import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useStores } from "@/store";
import "./index.less";
import { getQuery } from "@/service/http";
import Detail from "./components/detail";
import Vouchers from "./components/Vouchers";
import Foot from "./components/foot";
import { BookingAPI } from "./api";
import { useTranslation } from "react-i18next";
import BookRestaurant from "../success/components/book-restaurant";

// http://localhost:3000/#/booking-detail?reservation_code=ARC657F4VT&reservation_id=1780267&lang=en

const BookingDetail = () => {
  const [booking, setBooking] = useState(null); // 预订信息
  const { t } = useTranslation();
  const { restaurantStore } = useStores();

  // 获取预订详情
  function getDetail() {
    let { reservation_id, reservation_code } = getQuery();
    if (!reservation_id || !reservation_code) return;
    BookingAPI.getDetail(reservation_code, reservation_id).then((res) => {
      setBooking(res);
      restaurantStore.bookingInfo = res;
      console.log(res);
      document.body.classList.add("loaded");
    });
  }

  useEffect(() => {
    document.title = t("Booking details");
    getDetail();
  }, []);

  if (!booking) return null;

  return (
    <div className="booking-detail-page">
      <h2>{booking.status_humanize}</h2>
      <Detail booking={booking}></Detail>
      <Vouchers booking={booking}></Vouchers>
      <BookRestaurant></BookRestaurant>
      <Foot booking={booking} reload={getDetail}></Foot>
    </div>
  );
};

export default BookingDetail;
