import React, { useState, useEffect, useMemo, useCallback } from "react";
import ListItem from "./ListItem";
import PaymentInfo from "./PaymentInfo";
import Head from "./head";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { lang, getQuery } from "@/service/http";

const BookingDetail = ({ booking }) => {
  const { t } = useTranslation();
  const isPosPay = booking.orders?.length > 0;
  const vouchers = booking.vouchers || [];
  const history = useHistory();

  function checkBill() {
    const { reservation_code, id, orders } = booking;
    const { wx_code } = getQuery();
    const path = `/pos-pay?`;
    const query = qs.stringify({
      book_id: id,
      order_id: orders[0].id,
      reservation_code: reservation_code,
      lang: lang,
      wx_code: wx_code,
    });
    history.push(path + query);
  }

  return (
    <div className="booking-details">
      <Head
        title={t("Details")}
        check={t("Check Bill")}
        showCheck={isPosPay}
        onClick={checkBill}
      ></Head>
      {/* 预订信息 */}
      <BookInfo booking={booking}></BookInfo>
      {/* 支付信息 */}
      <ListItem
        show={isPosPay}
        icon="info"
        label={t("Total Paid")}
        value={booking.format_total_amount}
      ></ListItem>
      <PaymentInfo booking={booking} isPosPay={isPosPay} />
      {/* 优惠券 */}
      <ListItem
        icon="voucher"
        label={t("VOUCHERS.VOUCHERS")}
        show={vouchers.length > 0}
      >
        {vouchers.length > 0 && (
          <span>
            <span>{vouchers[0].detail.title}</span>
            <span>*{vouchers.length}</span>
          </span>
        )}
      </ListItem>
      {/* 点数 */}
      <ListItem
        icon="vip-grey"
        label={t("Points")}
        value={t("pointNumber", { points: booking.point?.debit })}
        show={!!booking.point?.debit}
      />
      <Event booking={booking}></Event>
      <ListItem
        icon="save"
        label={t("RESERVATION_INFO.BS")}
        value={booking.discount}
        show={!!booking.discount}
      />
      <Benefits booking={booking}></Benefits>
      <Remark booking={booking}></Remark>
      <Policy booking={booking}></Policy>
      <Notes booking={booking}></Notes>
    </div>
  );
};
/**预订信息 */
function BookInfo({ booking }) {
  const { t } = useTranslation();
  return (
    <>
      <ListItem
        icon="booking"
        label={t("RESERVATION_INFO.DATE")}
        value={booking.formated_date}
      />
      <ListItem
        icon="time"
        label={t("RESERVATION_INFO.TIME")}
        value={booking.formated_time}
      />
      <ListItem
        icon="seat"
        label={t("RESERVATION_INFO.PEOPLE")}
        value={booking.reservation_seats}
      />
      <ListItem
        icon="info"
        label={t("RESERVATION_INFO.CODE")}
        value={booking.reservation_code}
      />
    </>
  );
}
/**活动 */
function Event({ booking }) {
  const { t } = useTranslation();
  const { title, project_type } = booking.project_config || {};
  return (
    <>
      <ListItem
        icon="event"
        label={t("RESERVATION_INFO.EVENT")}
        value={title}
        show={project_type === "event"}
      />
      <ListItem
        icon="program-selected"
        label="Dining Program"
        value={title}
        show={project_type === "program"}
      />
    </>
  );
}
/**备注 */
function Remark({ booking }) {
  const { t } = useTranslation();
  const { reservation_comment, group_booking } = booking;
  if (!reservation_comment) return null;
  return (
    <div className="booking-detail-remark">
      <ListItem
        icon="info-selected"
        label={
          group_booking ? t("GROUP_REMARK") : t("RESERVATION_INFO.Requests")
        }
      />
      <article className="requests-text">{reservation_comment}</article>
    </div>
  );
}
/**取消条款 */
function Policy({ booking }) {
  const { t } = useTranslation();
  const policy = booking.restaurant?.book_config?.cancel?.policy;
  if (!policy) return null;
  return (
    <div className="cancellation-policy">
      <ListItem icon="info-selected" label={t("Cancellation policy")} />
      <article className="requests-text">{policy}</article>
    </div>
  );
}
/**预订提示 */
function Notes({ booking }) {
  const notes = booking.restaurant?.book_config?.booking_notes || [];
  if (!notes.length) return null;
  return notes.map((item, index) => {
    return (
      <div className="booking-notes" key={index}>
        <ListItem icon="info" label={item.title} />
        <article className="requests-text">{item.content}</article>
      </div>
    );
  });
}
/**VIP 会员礼遇 */
function Benefits({ booking }) {
  const { t } = useTranslation();
  const benefits = booking.loyalty_program_benefits || [];
  if (!benefits.length) return null;
  return (
    <ListItem icon="vip" label={t("RESERVATION_INFO.VIP")}>
      <article>
        {benefits.map((item) => {
          return <div key={item.id}>{item.title}</div>;
        })}
      </article>
    </ListItem>
  );
}

export default BookingDetail;
