import React, { useState, useEffect, useMemo, useCallback } from "react";
import Head from "./head";
import { useTranslation } from "react-i18next";
import { VoucherItem, Terms } from "../../../components/dc-voucher";

const Vouchers = ({ booking }) => {
  const { t } = useTranslation();
  const vouchers = booking.vouchers || [];
  const [terms, setTerms] = useState("");
  const [visible, setVisible] = useState(false);
  function showTerm(data) {
    setTerms(data.detail.desc);
    setVisible(true);
  }
  if (!vouchers.length) return null;

  return (
    <div className="booking-detail-vouchers">
      <Head title={t("VOUCHERS.VOUCHERS")} />
      <Terms text={terms} visible={visible} setVisible={setVisible} />
      {vouchers.map((item, index) => {
        return (
          <VoucherItem
            {...item}
            key={index}
            hidden
            showTerm={() => showTerm(item)}
          ></VoucherItem>
        );
      })}
    </div>
  );
};

export default Vouchers;
