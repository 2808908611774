import http, { getQuery } from "./http";

class Service {
  restaurant = null;
  dates = [];
  noteCache = {};
  /**
   * 获取餐厅信息
   *
   * @returns
   * @memberof Service
   */
  show(restaurantId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/restaurants/${restaurantId}`)
        .then(({ data }) => {
          this.restaurant = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取餐厅信息 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取餐厅预订规则
   * @param {string} restaurantId 
   * @returns 
   */
  getBookingConditions(restaurantId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/restaurants/${restaurantId}/booking_conditions`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * 获取服务条款
   *
   * @param { string } time YYYY-MM-DD_HH:mm
   * @param { string } [project='diningcity']
   * @returns
   * @memberof Service
   */
  getAlertTerms(time, project) {
    let url = `/restaurants/${this.restaurant.id}/projects/${project}/booking_terms_conditions`;
    let { access_code, handle } = getQuery();
    let data = {
      params: {
        time,
        handle,
        access_code,
      },
    };
    return new Promise((resolve, reject) => {
      http
        .get(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("获取服务条款 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取可用日期
   *
   * @returns
   * @memberof Service
   */
  getAvailability(options) {
    let url = `/restaurants/${this.restaurant.id}/available_2018`;
    let params = this.optionProxy(options);
    if (options.project) {
      delete params.channel_name;
    }
    if (options.deal_id && !options.channel) {
      params.channel_name = "DiningCity";
    }
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          params: params,
          headers: http.dropEmpty(http.getHeaders().headers),
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          console.error("获取可用日期 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取基础信息
   *
   * @param {*} options
   * @returns
   */
  optionProxy(options) {
    return {
      deal_id: options.deal_id || null,
      deal_enable: options.deal_enable,
      channel_name: options.channel_name,
      access_code: options.access_code,
      access_password: options.access_password,
      project: options.project || options.program || null,
      program_key: options.program_key || null,
      off_peak_only: options.off_peak_only || null,
      book_id: options.id || null,
      reservation_code: options.id ? options.reservation_code : null,
    };
  }

  /**
   * 获取当前最大折扣
   *
   * @param {*} data
   * @returns
   */
  getDiscount(data) {
    let discount = 0;
    try {
      discount = data.times
        .filter((i) => i.off_peak)
        .map((i) => i.off_peak.discount)
        .sort((a, b) => parseInt(b) - parseInt(a))[0];
    } catch (error) {
      return;
    }
    return discount || "";
  }
  /**
   * 获取笔记
   * @param {*} param0 
   */
  getNotes(query) {
    let cacheKey = JSON.stringify(query)
    let cache = this.noteCache[cacheKey]
    if (cache) return Promise.resolve(cache)
    return new Promise((resolve, reject) => {
      http
        .get(`/book_notes`, { params: query })
        .then(({ data }) => {
          this.noteCache[cacheKey] = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取笔记信息 失败, ", error);
          reject(error);
        });
    });
  }
}


export default new Service();
