import React, { useCallback, useMemo, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Calendar } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "../../store";
import "./index.less";
import { useEffect } from "react";
import { formatDate } from '../../utils'

let currentDate = moment(new Date()).format("YYYY-MM-DD");

/**
 * 选择预定日期
 *
 * @param {*} props
 */
const Dates = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  useEffect(() => {
    let { dates, form } = restaurantStore;
    let selectedDate = dates.find((i) => i.date === form.date);
    if (selectedDate) {
      // 匹配传入的日期
      setDate(selectedDate.date);
    } else if (dates[0]) {
      // 自动跳转到第一个可用日期的月份
      setDate(dates[0].date);
    }
  }, [restaurantStore.dates]);

  return useObserver(() => {
    const { dates, form } = restaurantStore;
    return (
      <div className="step-date" hidden={props.hidden}>
        <h2>{t("SELECT DATE")}</h2>
        <Calendar
          value={moment(date)}
          headerRender={DateHeader}
          onSelect={(date) => setDate(date.format("YYYY-MM-DD"))}
          dateFullCellRender={(item) =>
            DateCell(item, dates, form.date)
          }
        ></Calendar>
      </div>
    );
  });
};

/**
 * 自定义日历头部
 *
 * @param {*} { value, type, onChange, onTypeChange }
 * @returns
 */
const DateHeader = ({ value, onChange }) => {
  function onClick(step) {
    onChange(value.clone().add(step, "M"))
  }
  return (
    <div className="date-header">
      <div
        className="header-left"
        onClick={() => onClick(-1)}
      >
        <i className="icon icon-arrow-left"></i>
      </div>
      <div className="header-middle">
        <h3>{value.format("MMMM YYYY")}</h3>
      </div>
      <div
        className="header-right"
        onClick={() => onClick(1)}
      >
        <i className="icon icon-arrow-right"></i>
      </div>
    </div>
  );
};

/**
 * 单个日期
 *
 * @param {*} { date }
 * @returns
 */
const DateCell = (moment, enableList, current) => {
  const { restaurantStore } = useStores();
  let date = formatDate(moment.toString());
  let data = enableList.find((i) => i.date === date) || {};
  let today = currentDate === date ? "today" : "";
  let disabled = data.date ? "" : "disabled";
  let selected = current === date ? "selected" : "";
  let classList = ["date-cell", disabled, selected, today].join(" ");
  const onClick = (data) => {
    data.date && restaurantStore.selectDate(data.date);
  };
  return (
    <div className={classList} onClick={() => onClick(data)}>
      <div className="date-cell--date">{moment.format("D")}</div>
      <div className="date-cell--desc">{data.discount}</div>
    </div>
  );
};

export default Dates;
