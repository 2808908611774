import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from "../../store";
import { Confirm } from '../common'
import './index.less'

/**
 * 选择预定日期
 *
 * @param {*} props
 */
const Header = (props) => {
  const { t } = useTranslation()
  const { restaurantStore } = useStores();
  const { projectInfo, images } = restaurantStore
  const [visible, setVisible] = useState(false)
  function onConfirm() {
    // 如果在iframe中，需要用postmessage方式关闭
    if (window.parent === window) {
      window.history.back()
    } else {
      window.parent.postMessage({ type: 'close' }, '*')
    }
  }
  return (
    <header className="page-header entry">
      {/* <i className="icon icon-arrow-left" onClick={() => setVisible(true)}></i> */}
      <div className="left-wrap">
        <div className="logo"></div>
        <h1>{t('WIDGET_TITLE')}</h1>
      </div>
      
      {projectInfo && projectInfo.is_need_logo ? (
        <img
          className="right-log"
          src={images.logo}
          alt={projectInfo.logo.name}
        />
      ) : null}
      {/* <Confirm
        visible={visible}
        setVisible={setVisible}
        onConfirm={onConfirm}
      /> */}
    </header>
  )
}

export default Header
