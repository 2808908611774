import React, { useEffect, useCallback, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import "@fontsource/open-sans";
import "@fontsource/open-sans/600.css";
import { hot } from "react-hot-loader/root";
import { ConfigProvider } from "antd";
import { useObserver } from "mobx-react-lite";
import {
  HashRouter as Router,
  Route,
  useParams,
  Switch,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import zhTW from "antd/es/locale/zh_TW";
import hi_IN from "antd/es/locale/hi_IN";
import id_ID from "antd/es/locale/id_ID";
import ja_JP from "antd/es/locale/ja_JP";
import ko_KR from "antd/es/locale/ko_KR";
import ms_MY from "antd/es/locale/ms_MY";
import th_TH from "antd/es/locale/th_TH";
import vi_VN from "antd/es/locale/vi_VN";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/en-au";
import "moment/locale/zh-tw";
import "moment/locale/hi";
import "moment/locale/id";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/ms";
import "moment/locale/th";
import "moment/locale/vi";
import "moment/locale/tl-ph";
import Header from "./components/header";
import * as Common from "./components/common";
import TabHeader from "./components/tab-head";
import FullBook from "./components/dc-fullbook";
import Card from "./pages/card";
import Dates from "./pages/dates";
import Times from "./pages/times";
import Seats from "./pages/seats";
import Success from "./pages/success";
import Confirm from "./pages/confirm";
import Payment from "./pages/payment";
import Projects from "./pages/projects";
import PartialNoShow from "./pages/partial-no-show";
import ConfirmDetails from "./pages/confirm/member/confirm-details";
import PosPay from "./pages/pos-pay";
import BookingDetail from "./pages/booking-detail";
import { useStores } from "./store";
import { getQuery, lang } from "./service/http";
import "./index.less";
import "./locales";
import "@/utils/resize";

const languages = {
  en: {
    moment: "en-au",
    antd: enUS,
  },
  hi: {
    moment: "hi",
    antd: hi_IN,
  },
  id: {
    moment: "id",
    antd: id_ID,
  },
  ja: {
    moment: "ja",
    antd: ja_JP,
  },
  ko: {
    moment: "ko",
    antd: ko_KR,
  },
  ms: {
    moment: "ms",
    antd: ms_MY,
  },
  th: {
    moment: "th",
    antd: th_TH,
  },
  vi: {
    moment: "vi",
    antd: vi_VN,
  },
  zh: {
    moment: "zh-cn",
    antd: zhCN,
  },
  "zh-HK": {
    moment: "zh-tw",
    antd: zhTW,
  },
};
const language = languages[lang] || languages["en"];

moment.locale(language.moment, {
  week: { dow: 0 },
});

const Main = (props) => {
  const { restaurantStore, memberStore, paymentStore } = useStores();
  const { id, action } = useParams();
  const disabled = useMemo(() => {
    return action === "disabled";
  }, [action]);
  useEffect(() => {
    // 标记出席模式
    if (action === "partial-no-show") {
      console.warn("标记出席模式模式");
      restaurantStore.step = restaurantStore.STEPS.PARTIAL_NO_SHOW;
      document.body.classList.add("loaded");
      document.body.setAttribute("step", restaurantStore.step);
      return
    }
    // 不可用模式
    if (action === "disabled") {
      document.body.classList.add("loaded");
      return console.warn("disabled模式");
    }
    document.body.classList.remove("loaded");
    restaurantStore.bootstrap(id, action).then((data) => {
      // 初始化用户资料
      // 如果是修改预订，需要直接使用预订的用户资料
      const {
        avatar_url,
        first_name,
        last_name,
        email,
        mobile,
      } = restaurantStore.form;
      const fallback =
        action === "edit"
          ? { avatar_url, first_name, last_name, email, mobile }
          : null;
      memberStore.bootstrap(data, fallback);

      // 如果是需要取消预订
      if (action === "cancel") {
        console.warn("取消预订");
        restaurantStore.step = restaurantStore.STEPS.COMPLETE;
      }

      // 如果是需要修改预订
      if (action === "edit") {
        console.warn("修改预订");
        restaurantStore.step = restaurantStore.STEPS.START;
      }

      // 支付成功
      if (action === "complete") {
        console.warn("预订完成");
        restaurantStore.step = restaurantStore.STEPS.COMPLETE;
      }

      // 未完成支付
      if (action === "pay") {
        console.warn("未完成支付");
        restaurantStore.step = restaurantStore.STEPS.PAYMENT;
      }

      // admin模式
      if (action === "admin") {
        console.warn("admin模式");
        restaurantStore.step = restaurantStore.STEPS.ADMIN;
      }
      document.body.classList.add("loaded");
      document.body.setAttribute("step", restaurantStore.step);
    });
  }, [id, action]);

  return useObserver(() => {
    const { step, STEPS, pageHeight } = restaurantStore;
    const { PAYMENT, COMPLETE, ADMIN, PARTIAL_NO_SHOW } = STEPS;
    const showHeader =
      ![PAYMENT, COMPLETE, ADMIN, PARTIAL_NO_SHOW].includes(step) && !disabled;
    return (
      <ConfigProvider locale={language.antd}>
        <div
          className="page-main"
          style={{
            height: pageHeight,
          }}
        >
          <Common.Platform></Common.Platform>
          <Header
            projectInfo={restaurantStore.projectInfo}
            images={restaurantStore.images}
          />
          {showHeader && <TabHeader />}
          {disabled ? (
            <Disable />
          ) : (
            <main>
              {step == STEPS.CARD && <Card />}
              {step == STEPS.DATE && <Dates />}
              {step == STEPS.TIME && <Times />}
              {step == STEPS.SEAT && <Seats />}
              {step == STEPS.INFO && <ConfirmDetails />}
              {step == STEPS.CONFIRM && <Confirm />}
              {step == STEPS.COMPLETE && <Success />}
              {step == STEPS.PAYMENT && <Payment />}
              {step == STEPS.ADMIN && <Projects />}
              {step == STEPS.PARTIAL_NO_SHOW && <PartialNoShow />}
              <FullBook></FullBook>
            </main>
          )}
        </div>
      </ConfigProvider>
    );
  });
};

const Disable = () => {
  const { t } = useTranslation();
  return (
    <div className="step-disabled-wrap">
      <div className="step-disabled">
        {t("VOUCHERS.You have no voucher available to use in this venue")}
      </div>
    </div>
  );
};

function App() {
  const { paymentStore } = useStores();

  useEffect(() => {
    // stripe添加支付方式
    paymentStore.setupIntentUrlFlow();
  }, [])
  return (
    <Router>
      <Switch>
        <Route exact path="/booking-detail" component={BookingDetail} />
        <Route exact path="/pos-pay" component={PosPay} />
        <Route exact path="/redirect" component={Redirect} />
        <Route exact path="/restaurants/:id/reservation" component={Main} />
        <Route
          exact
          path="/restaurants/:id/reservation/:action"
          component={Main}
        />
        <Route exact path="/" component={MasterPayCallback} />
      </Switch>
    </Router>
  );
}

const WithHotReload = process.env.NODE_ENV === "production" ? App : hot(App);
const Redirect = () => {
  useEffect(() => {
    const { redirect } = getQuery();
    window.location.href = decodeURIComponent(redirect);
  }, []);
  return null;
};
const MasterPayCallback = () => {
  useEffect(() => {
    const queries = getQuery();
    localStorage.setItem("message", JSON.stringify(queries));
  }, []);
  return <span>MasterPayCallback</span>;
};

window.autoBackCheck = (msg) => {
  if(msg === '请返回银联权益入口，重新进入并预订。') {
    setTimeout(() => {
      debugger;
      window.top.location.href = 'https://benefits.95516.com/upbms-web/'
    }, 1000)
  }
}

ReactDOM.render(<WithHotReload />, document.getElementById("root"));


