import React from "react";
import { useTranslation } from "react-i18next";
import * as Common from "@/components/common";
import { WxOpenLaunch } from '@/components/wx-launch'

const PosPayFoot = (props) => {
  const { t } = useTranslation();
  const showPay = props.orderInfo.payment.status === "pending";
  // 展示支付动画
  return (
    <div className="pos-pay-foot">
      <div className="pos-pay-foot-left">
        <span className="pos-pay-foot-desc">{t("PAYMENT.Total")}</span>
        <span className="pos-pay-foot-price">{props.orderInfo.format_fee}</span>
      </div>
      {showPay ? (
        <Common.Button onClick={props.onClick} disabled={!props.payMethod}>
          {t("PAYMENT.Pay")}
          <WxOpenLaunch appid={props.appid} path={props.path}></WxOpenLaunch>
        </Common.Button>
      ) : null}
    </div>
  );
};

export default PosPayFoot;
