import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import Pick from "@/components/pick";
import "./index.less";
import { getQuery } from "@/service/http";
import * as Common from "@/components/common";

/**
 * 选择出席人数
 *
 * @param {*} props
 */
const PartialNoShow = (props) => {
  const { restaurantStore } = useStores();
  const { id } = useParams();
  const [current, setCurrent] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { t } = useTranslation();
  const seats = useMemo(() => {
    try {
      let reservation_seats = Number(getQuery()["reservation_seats"]);
      return new Array(reservation_seats).fill(1).map((id, index) => {
        return index + 1;
      });
    } catch (error) {
      return [];
    }
  }, [id]);
  // 选择了座位数
  const onSubmit = () => {
    setLoading(true);
    restaurantStore
      .setPartialNoShow({
        restaurant_id: id,
        book_id: getQuery()["reservation_id"],
        show_number: current,
      })
      .then((data) => {
        setLoading(false);
        setDone(true);
      })
      .catch((error) => {
        console.error(error.message);
        setTimeout(() => {
          setLoading(false);
          setDone(true);
        }, 1500);
      });
  };

  return (
    <div className="partial-no-show">
      <h2>{t("NO_SHOW.Mark partial no show")}</h2>
      {done ? (
        <section>
          <h3>{t("NO_SHOW.Confirmed!")}</h3>
          <p className="tip">
            {t("NO_SHOW.Confirmed that X guests showed", { seats: current })}
          </p>
        </section>
      ) : (
        <section>
          <h3>{t("NO_SHOW.Confirm seats")}</h3>
          <p className="tip">{t("NO_SHOW.How many guests came?")}</p>
        </section>
      )}
      <div className="seats-group" hidden={done}>
        <Row gutter={24}>
          {seats.map((item, index) => {
            return (
              <Col span={6} key={index}>
                <Pick
                  onClick={() => setCurrent(item)}
                  label={item}
                  selected={item == current}
                />
              </Col>
            );
          })}
        </Row>
        <footer className="page-footer" align="right">
          <Common.Button
            type="primary"
            loading={loading}
            disabled={!current}
            onClick={onSubmit}
          >
            {t("CONFIRM")}
          </Common.Button>
        </footer>
      </div>
    </div>
  );
};

export default PartialNoShow;
