import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { useStores } from "@/store";
import "./index.less";

/**
 * 满订
 *
 * @param {*} props
 */
export default function FullBook (props) {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  return useObserver(() => {
        return restaurantStore.fullBook ? <div className="full-book">
            <div className="full-book-tip">
            <div>{ t('FULL_BOOK.We apologize, but our reservations are currently fully booked') }</div>
            <div>{ t('FULL_BOOK.Kindly at a later time to check for availability') }</div>
            </div>
        </div> : null
    })

};