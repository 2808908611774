import React, { useState, useEffect, useRef, useMemo } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import qs from "qs";
import schema from "async-validator";
import { Row, Col, Form, Input, message, Select } from "antd";
import reservationService from "@/service/reservation";
import { getQuery, urlBuilder } from "@/service/http";
import { useStores } from "@/store";
import * as Common from "@/components/common";
import Voucher from "@/components/voucher";
import { VoucherPopover } from "@/components/dc-voucher";
import KidsPolicy from "@/components/dc-kids-policy";
import "./index.less";
import {
  PaymentSelector,
  PaymentPopup,
  PaymentAction,
  StripePayModal,
} from "@/components/dc-payment";
import { client } from "@/utils";

let timer = null;
let scrollTop = 0;

function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
}

// 进出场，回调
async function animate(dir, callback) {
  if (dir === "PUSH") {
    scrollTop = document.documentElement.scrollTop;
    callback();
    await sleep(30);
    document.documentElement.scrollTop = 0;
    document.body.setAttribute("animate", "push");
  } else {
    document.body.setAttribute("animate", "pop");
    await sleep();
    callback();
    document.documentElement.scrollTop = scrollTop;
    scrollTop = 0;
    document.body.setAttribute("animate", "");
  }
}

/**
 * 补充预订信息
 *
 * @param {*} props
 */
const Guest = (props) => {
  const { t } = useTranslation();
  const { paymentStore, restaurantStore } = useStores();
  const { restaurant } = restaurantStore;
  const selected_benefit_map = {};
  const loyalty_program_benefit_ids_cache = restaurantStore.form.loyalty_program_benefit_ids.split(
    ","
  );
  loyalty_program_benefit_ids_cache.forEach((i) => {
    selected_benefit_map[i] = true;
  });
  let [termsVisible, setTermsVisible] = useState(false);
  let [paymentVisible, setPaymentVisible] = useState(false);
  let [voucherVisible, setVoucherVisible] = useState(false);
  let [benefitsVisible, setBenefitsVisible] = useState(false);
  let [choosenBenefits, setBenefits] = useState(selected_benefit_map);
  let [payment, setPayment] = useState("");
  let [vouchers, setVouchers] = useState([]);
  let [selected_benefit_ids, setSelected_benefit_ids] = useState(
    loyalty_program_benefit_ids_cache
  );

  let benefitsSize = useMemo(() => {
    return selected_benefit_ids.filter(Boolean).length || "";
  }, [selected_benefit_ids]);

  function onPaymentPopupBack(data) {
    setPaymentVisible(false);
    setPayment(data);
  }
  function onBenefitsPopupBack(data) {
    let benefits = Object.keys(data).filter((i) => data[i]);
    setBenefitsVisible(false);
    setBenefits(data);
    setSelected_benefit_ids(benefits);
  }
  // 查看单个优惠券信息
  function onVoucherView(data) {
    data.hidden = true;
    setVouchers([data]);
    animate("PUSH", () => setVoucherVisible(true));
  }
  // 离开页面，自动关闭弹窗
  useEffect(() => {
    return () => {
      animate("POP", () => {
        setVoucherVisible(false);
        setBenefitsVisible(false);
        setPaymentVisible(false);
      });
    };
  }, []);

  useEffect(() => {
    paymentStore.getMethods().then((data) => {
      const methods = data.map((v) => v.provider);
      if (methods.includes("mpgs")) {
        return;
      } else if (methods.includes("stripe")) {
        // 当存在stripe支付时，也需要先选中卡片
        const item = data.find((v) => v.provider === "stripe");
        setPayment(item?.keyword);
        // 获取stripe 支付key, 取餐厅region_id
        paymentStore.getStripeKey(restaurant.region?.id);
      } else if (methods.includes("amex")) {
        const item = data.find((v) => v.provider === "amex");
        setPayment(item?.keyword);
      }
      // 在微信打开，自动选择支付方式
      if (client === "MP" || client === "WECHAT") {
        if (methods.includes("wx")) {
          setPayment("wx");
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      {termsVisible ? (
        <Common.TermsPopup
          visible={termsVisible}
          onBack={() => animate("POP", () => setTermsVisible(false))}
        ></Common.TermsPopup>
      ) : null}

      {/* 用户福利 */}
      {benefitsVisible ? (
        <Common.BenefitsPopup
          visible={benefitsVisible}
          data={choosenBenefits}
          onBack={(data) => animate("POP", () => onBenefitsPopupBack(data))}
        ></Common.BenefitsPopup>
      ) : null}

      {/* 支付方式 */}
      {paymentVisible ? (
        <PaymentPopup
          visible={paymentVisible}
          data={payment}
          onBack={(data) => animate("POP", () => onPaymentPopupBack(data))}
        ></PaymentPopup>
      ) : null}

      {/* 优惠券 */}
      {voucherVisible ? (
        <VoucherPopover
          visible={voucherVisible}
          vouchers={vouchers}
          rule={{}}
          showFooter={false}
          onBack={(data) => animate("POP", () => setVoucherVisible(false))}
        ></VoucherPopover>
      ) : null}
      <div className="page-user-guest page-animate-main">
        {/* <h2>{t("BOOK_UNDER_NAME")}</h2> */}
        <UserForm
          payment={payment}
          selected_benefit_ids={selected_benefit_ids}
          benefitsSize={benefitsSize}
          setVoucherVisible={onVoucherView}
          setBenefitsVisible={() =>
            animate("PUSH", () => setBenefitsVisible(true))
          }
          setPaymentVisible={() => {
            animate("PUSH", () => setPaymentVisible(true));
          }}
          onTermsVisible={() => animate("PUSH", () => setTermsVisible(true))}
        />
      </div>
    </React.Fragment>
  );
};

function getUnionpayMobile() {
  let val = getQuery().unionpay_mobile
  try {
    let [country_code, mobile] = window.atob(val).split("_")
    if(country_code && mobile) {
      return {
        country_code: Number(country_code),
        mobile: mobile
      }
    } else {
      return {}
    }
  } catch (error) {
    return {}
  }
}

/**
 * 用户资料填写表单
 *
 * @param {*} props
 * @returns
 */
const UserForm = (props) => {
  const [form] = Form.useForm();
  const { payment } = props;
  const { restaurantStore, memberStore } = useStores();
  const { t } = useTranslation();
  const { action } = useParams();
  const isEdit = action === "edit";
  const isAdmin = getQuery().from === "admin";
  const [hideMobileCode, setHideMobileCode] = useState(isAdmin || isEdit);
  let formdata = {
    country_code: 86,
    email: "",
    first_name: "",
    last_name: "",
    mobile: "",
    mobile_code: "",
    remark: "",
    country_name: "",
    comment: "",
    acceptTerms: true,
    voucher_codes: [],
    ...restaurantStore.form,
    ...getUnionpayMobile(),
  };
  const { paymentStore } = useStores();
  let [values, setValues] = useState(formdata);
  let [errors, setErrors] = useState({});
  let [loading, setLoading] = useState("");
  let [visible, setVisible] = useState(false);
  // Stripe 支付弹窗
  const [stripePayVisible, setStripePayVisible] = useState(false);
  // Stripe 支付 clientSecret
  const [clientSecret, setClientSecret] = useState("");
  let term = restaurantStore.alertTerms || [];
  let isSubmit = false;
  let [result, setResult] = useState(null);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [formatOriginAmount, setFormatOriginAmount] = useState("");
  const [formatAmount, setFormatAmount] = useState("");
  const [errtip, showErrorTip] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [termTip, showTermTip] = useState(false);
  const [voucherInfo, setVoucherInfo] = useState({});
  const [disabledMobile, setDisabledMobile] = useState(false);

  const voucherCodes = useMemo(() => {
    return values.voucher_codes.join();
  }, [values.voucher_codes]);

  /** 是否存在Stripe支付 */
  const hasStripe = useMemo(
    () =>
      paymentStore.paymentMethods.findIndex((v) => v.provider === "stripe") >
      -1,
    [paymentStore.paymentMethods]
  );
  useEffect(() => {
    // 获取优惠劵信息
    restaurantStore.getVouchers().then((data) => {
      setVoucherInfo(data);
    });
    // 从运通获取用户信息
    memberStore.getGuestInfo().then((data) => {
      if (!data.member) return;
      let { phone_code } = restaurantStore.restaurant.region;
      let country_code = phone_code ? Number(phone_code) : 86;
      // 有手机号码，不能修改，不能发送验证码
      setDisabledMobile(!!data.member?.mobile);
      setHideMobileCode(!!data.member?.mobile_verify);
      let values = Object.entries(data.member || {}).map(([key, value]) => {
        return { name: key, value };
      });
      values.country_code = country_code;
      form.setFields(values);
      setValues((pre) => {
        return Object.assign({}, pre, data.member || {}, {
          country_code: country_code,
        });
      });
    });
  }, []);
  useEffect(() => {
    // 获取支付信息
    restaurantStore
      .getPaymentInfo(Object.assign({}, values, { vouchers: voucherCodes }))
      .then((data) => {
        setAmount(data.amount);
        setFormatOriginAmount(data.format_origin_amount);
        setFormatAmount(data.format_amount);
        setCurrency(data.currency_code);
      })
      .catch((error) => {
        console.error(error);
        setAmount(0);
      });
  }, [voucherCodes]);
  const requireVoucher = useMemo(() => {
    let { required, points_range = [] } = voucherInfo;
    return (
      required &&
      points_range[0] > 0 &&
      restaurantStore.projectInfo &&
      restaurantStore.projectInfo.is_need_voucher_required
    );
  }, [voucherInfo, restaurantStore.projectInfo]);

  const onChange = async (data = {}) => {
    const formValues = Object.assign({}, values, form.getFieldsValue(), data);
    setValues(formValues);
    if (Object.keys(data)[0] === "email") {
      const validator = new schema({
        email: [{ required: true, type: "email" }],
      });
      validator
        .validate(data)
        .then(() => {
          setErrors({ email: "" });
        })
        .catch(({ fields }) => {
          setErrors(fields);
        });
    }
  };

  /**当成功动画播放结束 */
  async function onAnimateComplete(bookResult) {
    const bookingResult = bookResult || result;
    const reservation_code = bookingResult.book.reservation_code;
    const reservation_id = bookingResult.book.id;
    setVisible(false);
    isSubmit = false;
    restaurantStore.bookingResult = bookingResult;
    restaurantStore.bookingInfo =
      bookingResult.book || restaurantStore.bookResultAdapter(getUserInfo());
    if (bookingResult.book) {
      await restaurantStore.getBooking(reservation_code, reservation_id);
    }
    function getPayPage() {
      // 如果需要支付，重定向页面到支付页面
      const domain = document.location.href.split("#")[0];
      const base =
        domain + `#/restaurants/${restaurantStore.restaurant.id}/reservation`;
      let callback = urlBuilder(reservation_code, reservation_id);
      const query = qs.stringify({
        reservation_code: reservation_code,
        reservation_id: reservation_id,
        channel: props.payment,
        success_url: callback,
        project: getQuery().project,
        program: getQuery().program,
        deal_id: getQuery().deal_id,
        lang: getQuery().lang,
        access_code: getQuery().access_code,
      });
      return `${base}/pay?${query}`;
    }
    /** 支付失败 */
    const handlePayFail = ({ autoPay = true, isTop = true } = {}) => {
      const payPage = getPayPage();
      localStorage.setItem("AUTO_PAY", String(autoPay));
      if(isTop) {
        window.top.location.href = payPage;
      } else {
        window.location.href = payPage;
      }
    };
    function getSuccessPage({ channel }) {
      const domain = document.location.href.split("#")[0];
      const reservation_code =
        restaurantStore.bookingResult.book.reservation_code;
      const reservation_id = restaurantStore.bookingResult.book.id;
      const base =
        domain +
        `#/restaurants/${restaurantStore.restaurant.id}/reservation/complete?`;
      const query = qs.stringify({
        ...getQuery(),
        reservation_code: reservation_code,
        reservation_id: reservation_id,
        channel,
      });
      return base + query;
    }
    // 如果是预支付场景，还需要唤起支付，然后回调到预定完成页面
    if (bookingResult.book && amount) {
      // 运通支付
      const hasAmexCheckstand = !!paymentStore.paymentMethods.find(
        (v) => v.provider === "amex"
      );
      if (payment === "amexweb") {
        paymentStore
          .prePayment({
            ...restaurantStore.reservation,
            bank_card_uuid: null,
            payment_method: payment,
            success_url: getSuccessPage({ channel: payment }),
          })
          .then((data) => {
            window.location.replace(data.payUrl);
          })
          .catch((err) => {
            setSubmitLoading(false);
            message.error(err.message);
          });
      } else if (hasStripe) {
        paymentStore
          .prePayment({
            ...restaurantStore.reservation,
            bank_card_uuid: null,
            payment_method: payment,
          })
          .then((data) => {
            setStripePayVisible(true);
            setClientSecret(data.client_secret);
          });
      } else if (hasAmexCheckstand) {
        let { can_edit_member_info } = getQuery();
        paymentStore
          .prePayment({
            ...restaurantStore.reservation,
            bank_card_uuid: null,
            payment_method: payment,
            success_url: paymentStore.getAmexPayUrl(bookingResult.book),
          })
          .then((data) => {
            paymentStore.goAmexPay(bookingResult.book, () => {
              handlePayFail({ autoPay: false, isTop: false });
            });
          })
          .catch(() => {
            handlePayFail({ autoPay: false, isTop: false });
          });
      } else {
        // 如果需要支付，重定向页面到支付页面
        handlePayFail();
      }
    } else {
      setSubmitLoading(false);
      restaurantStore.step = restaurantStore.STEPS.COMPLETE;
    }
  }

  function getUserInfo(checked_terms_conditions) {
    let defaultBenefits = memberStore.benefits
      .filter((i) => !i.is_optional)
      .map((i) => i.id);
    let loyalty_program_benefit_ids = defaultBenefits
      .concat(props.selected_benefit_ids)
      .join(",");
    return Object.assign({}, values, {
      checked_terms_conditions,
      loyalty_program_benefit_ids,
      selected_benefit_ids: null,
    });
  }

  async function onConfirm(checked_terms_conditions = []) {
    try {
      if (isSubmit) return;
      isSubmit = true;
      setLoading("loading");
      let bookResult = await restaurantStore.create(
        getUserInfo(checked_terms_conditions)
      );
      if (bookResult) {
        setResult(bookResult);
        if (bookResult.book && amount) {
          onAnimateComplete(bookResult);
        } else {
          setLoading("success");
          setSubmitLoading(false);
          restaurantStore.navigateToMP(bookResult.book);
        }
      } else {
        setLoading("failed");
        setTimeout(() => {
          setLoading("");
          setVisible(false);
          isSubmit = false;
          setSubmitLoading(false);
        }, 3000);
      }
    } catch (error) {
      message.error(error.message, 5);
      setLoading("failed");
      setTimeout(() => {
        setLoading("");
        setVisible(false);
        isSubmit = false;
        setSubmitLoading(false);
      }, 3000);
    }
  }

  async function onSubmit() {
    setSubmitLoading(true);
    window.parent.postMessage({
      type: 'btn_click',
      data: {
        text: 'confirm',
      }
    }, '*')
    try {
      setErrors({});
      // 如果要求必须填写优惠券
      if (requireVoucher && !isEdit) {
        // 获取优惠券组件数据
        if (!values.voucher_codes.length) {
          message.error(t("VOUCHERS.ENTER"));
          setSubmitLoading(false);
          return console.error("必须填写优惠券");
        }
      }
      const validator = new schema({
        email: [{ required: true, type: "email" }],
        first_name: [{ required: true }],
        last_name: [{ required: true }],
        mobile: [{ required: true }],
        mobile_code: [{ required: !hideMobileCode }],
        seats: [
          values.group_booking
            ? { required: true, type: "number", max: 9999, min: 8 }
            : { required: true },
        ],
      });
      validator
        .validate({
          ...values,
          seats: +values.seats,
        })
        .then(() => {
          // 选择支付方式
          const showPayError = amount && !props.payment;
          const showTermsError = !values.acceptTerms;
          if (showPayError) {
            showErrorTip(true);
            setTimeout(() => {
              showErrorTip(false);
            }, 500);
          }

          // 勾选用户协议
          if (showTermsError) {
            showTermTip(true);
            setTimeout(() => {
              showTermTip(false);
            }, 500);
          }

          if (showPayError || showTermsError) {
            setSubmitLoading(false);
            return;
          }
          setVisible(true);
          // term 如果该餐厅有附加条款，需要展示给用户
          if (!term.length) {
            onConfirm();
          }
        })
        .catch(({ fields }) => {
          setSubmitLoading(false);
          setErrors(fields);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    try {
      let { phone_code } = restaurantStore.restaurant.region;
      form.setFieldsValue({
        country_code: phone_code ? Number(phone_code) : 86,
      });
    } catch (error) {
      console.error(error);
    }
  }, [form, restaurantStore.restaurant]);

  return useObserver(() => {
    const isGroupBooking = values.group_booking;
    const { deals, paymentInfo, restaurant, enablePrePay } = restaurantStore;
    const { benefits } = memberStore;
    const {
      first_name,
      mobile,
      mobile_code,
      acceptTerms,
      children_seats,
      seats,
    } = values;
    const showDeal = deals && enablePrePay;

    let projectInfo = restaurantStore.projectInfo;
    const showVoucher = projectInfo && projectInfo.is_need_voucher_required;
    let suffix = restaurantStore.currency;
    let shouldPay = !!(!isGroupBooking && amount);
    let adults_seats = seats - children_seats;
    console.log("guest shouldPay", shouldPay);

    const canSubmit = isEdit
      ? true
      : first_name &&
        mobile &&
        (hideMobileCode ? true : mobile_code) &&
        acceptTerms &&
        (shouldPay ? props.payment : true) &&
        !errors.email;
    // 团体预订和套餐预订不展示Benefits
    const showBenefits = !isGroupBooking && benefits.length;
    let isALaCarte = projectInfo
      ? projectInfo.project_type !== "program" &&
        projectInfo.project_type !== "event"
      : true;
    isALaCarte = deals ? false : isALaCarte;
    return (
      <Form
        size="large"
        className="book-page-form"
        form={form}
        scrollToFirstError
        layout="vertical"
        initialValues={formdata}
        onValuesChange={onChange}
      >
        <Common.LoadingModal
          visible={visible}
          loading={loading}
          setVisible={setVisible}
          setSubmitLoading={setSubmitLoading}
          onSubmit={onConfirm}
          onComplete={onAnimateComplete}
          terms={term}
        ></Common.LoadingModal>
        <section className="book-page-info">
          {action === "edit" ? (
            <Common.UserProfile></Common.UserProfile>
          ) : (
            <React.Fragment>
              <div className="user-profile__name">
                <i className="user-profile__name__icon"></i>
                <span className="user-profile__name__value">
                  {restaurant ? restaurant.name : ""}
                </span>
              </div>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    label={t("FIRST_NAME")}
                    name="first_name"
                    help={
                      errors.first_name ? (
                        <Common.Help>{t("ERROR.FIRST_NAME")}</Common.Help>
                      ) : null
                    }
                  >
                    <Input placeholder={t("FIRST_NAME")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("LAST_NAME")}
                    name="last_name"
                    help={
                      errors.last_name ? (
                        <Common.Help>{t("ERROR.LAST_NAME")}</Common.Help>
                      ) : null
                    }
                  >
                    <Input placeholder={t("LAST_NAME")} />
                  </Form.Item>
                </Col>
              </Row>
              {/* 邮箱 */}
              <Form.Item
                label={t("EMAIL")}
                name="email"
                help={
                  errors.email ? (
                    <Common.Help>{t("ERROR.EMAIL")}</Common.Help>
                  ) : null
                }
              >
                <Input placeholder={t("Enter email address")} />
              </Form.Item>
              {/* 手机 */}
              <Form.Item
                label={t("MOBILE")}
                help={
                  errors.mobile ? (
                    <Common.Help>{t("ERROR.MOBILE")}</Common.Help>
                  ) : null
                }
              >
                <Input.Group compact>
                  <Form.Item name="country_code" noStyle>
                    <Select
                      style={{ width: "80px" }}
                      optionLabelProp="name"
                      className="guest-country"
                      dropdownClassName="country-code-select"
                    >
                      {restaurantStore.countries.map((item) => {
                        return (
                          <Select.Option
                            value={item.country_code}
                            key={item.name}
                            name={item.country_code_format}
                          >
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="mobile" noStyle>
                    <Input
                      disabled={disabledMobile}
                      placeholder={t("MOBILE")}
                      style={{ width: "calc(100% - 80px)" }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              {/* 验证码 */}
              <Form.Item
                hidden={hideMobileCode}
                name="mobile_code"
                help={
                  errors.mobile_code ? (
                    <Common.Help>{t("ERROR.VERIFY_CODE")}</Common.Help>
                  ) : null
                }
              >
                <Input
                  placeholder={t("VERIFY_CODE")}
                  maxLength={4}
                  suffix={<CodeButton {...values} />}
                />
              </Form.Item>
            </React.Fragment>
          )}

          <Common.Bookinfo />
          {/* 有儿童 */}
          {children_seats > 0 && (
            <Common.ListItem
              title={
                <span style={{ marginLeft: 30, fontWeight: 400 }}>
                  {t("Children seats", {
                    adult: adults_seats,
                    children: children_seats,
                  })}
                </span>
              }
            ></Common.ListItem>
          )}
          {/* 点菜单 */}
          {isALaCarte && (
            <Common.ListItem
              title={
                <Common.Icon
                  text={
                    <span style={{ fontSize: 15, color: "#353535" }}>
                      {t("A la carte")}
                    </span>
                  }
                  icon="icon icon-carte"
                ></Common.Icon>
              }
            ></Common.ListItem>
          )}
          {/* 折扣 */}
          {values.discount ? (
            <Common.ListItem
              value={
                <span
                  style={{ fontSize: 16, color: "#dc2224", fontWeight: "bold" }}
                >
                  {values.discount}
                </span>
              }
              title={
                <Common.Icon
                  text={
                    <span style={{ fontSize: 15, color: "#353535" }}>
                      {t("RESERVATION_INFO.BS")}
                    </span>
                  }
                  icon="icon icon-save"
                ></Common.Icon>
              }
            ></Common.ListItem>
          ) : null}

          {/* 优惠 */}
          {showDeal ? (
            <Common.ListItem
              title={
                <Common.Icon
                  text={
                    <span
                      className="ellipsis"
                      style={{
                        fontSize: 15,
                        lineHeight: "24px",
                        width: "calc(100vw - 70px)",
                      }}
                    >
                      {deals.name}
                    </span>
                  }
                  icon="icon icon-deal"
                ></Common.Icon>
              }
            ></Common.ListItem>
          ) : null}

          {/* 活动 */}
          <Event disabled={props.disabled} />

          {/* 礼遇 */}
          {showBenefits ? (
            <Common.ListItem
              onClick={() => props.setBenefitsVisible(true)}
              title={
                <Common.Icon
                  icon="icon icon-vip"
                  text={
                    <strong style={{ fontSize: 15, lineHeight: "24px" }}>
                      {t("Benefits")}
                    </strong>
                  }
                />
              }
              desc={t("Your selected VIP benefits")}
              value={
                <React.Fragment>
                  <span
                    style={{
                      color: "#dc2224",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.benefitsSize}
                  </span>
                  <i className="icon icon-arrow-right"></i>
                </React.Fragment>
              }
            ></Common.ListItem>
          ) : null}
        </section>

        {/* 预支付金额 */}
        {shouldPay ? (
          <PrePay
            deals={deals}
            paymentInfo={paymentInfo}
            disabled={!canSubmit}
            loading={submitLoading}
            payment={props.payment}
            setPaymentVisible={props.setPaymentVisible}
            onSubmit={onSubmit}
            amount={amount}
            suffix={suffix}
            errtip={errtip}
          />
        ) : null}
        {/** stripe 支付弹窗 */}
        {hasStripe && paymentStore.stripePublicKey ? (
          <StripePayModal
            visible={stripePayVisible}
            setSubmitLoading={setSubmitLoading}
            clientSecret={clientSecret}
            onVisibleChange={setStripePayVisible}
            stripePublicKey={paymentStore.stripePublicKey}
            formatAmount={formatAmount}
            amount={amount}
            channel={props.payment}
            currency={currency}
          />
        ) : null}

        {/* 优惠券 */}
        {showVoucher ? (
          <Voucher
            mobile={mobile}
            required={requireVoucher}
            range={voucherInfo.points_range}
            onView={props.setVoucherVisible}
            onChange={(data) => {
              setValues(
                Object.assign({}, values, {
                  voucher_codes: data,
                })
              );
            }}
          />
        ) : null}
        <Common.NoteList values={values} />
        {isGroupBooking && <GroupBooking errors={errors}></GroupBooking>}
        <KidsPolicy hidden={values.children_seats < 1} />
        <Form.Item
          name="comment"
          label={t("Requests")}
          className="requests"
          style={{ marginBottom: "16px" }}
          hidden={isGroupBooking}
        >
          <Input.TextArea
            size="small"
            bordered={false}
            placeholder={t("SPECIAL_REQUESTS")}
            autoSize
          />
        </Form.Item>

        <Common.Terms
          checked={acceptTerms}
          errtip={termTip}
          btnText={
            !shouldPay
              ? values.id
                ? t("EDIT_RESERVATION")
                : isGroupBooking
                ? t("SEND_GROUP_BOOKING")
                : t("CONFIRM")
              : t("PAYMENT.Pay & book")
          }
          onChange={(val) => onChange({ acceptTerms: val })}
          onShow={props.onTermsVisible}
        ></Common.Terms>

        <div className="page-footer-place"></div>
        <div
          hidden={shouldPay}
          style={{
            paddingTop: 14,
            textAlign: "right",
            position: "fixed",
            left: "50%",
            bottom: "15px",
            transform: "translateX(-50%)",
          }}
        >
          <Common.Button
            type="primary"
            onClick={onSubmit}
            disabled={props.disabled}
            block={false}
            loading={submitLoading}
          >
            {values.id
              ? t("EDIT_RESERVATION")
              : isGroupBooking
              ? t("SEND_GROUP_BOOKING")
              : t("CONFIRM")}
          </Common.Button>
        </div>
      </Form>
    );
  });
};

/** Event信息 */
const Event = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  return useObserver(() => {
    const { projectInfo, enablePrePay } = restaurantStore;
    if (!projectInfo) return null;
    if (enablePrePay) return null;
    if (projectInfo.project_type !== "event") {
      return null;
    }
    return (
      <Common.ListItem
        title={
          <Common.Icon
            icon="icon icon-event"
            text={
              <strong
                className="ellipsis"
                style={{
                  fontSize: 15,
                  lineHeight: "24px",
                  width: "calc(100vw - 70px)",
                }}
              >
                {projectInfo.title}
              </strong>
            }
          />
        }
        // value={
        //   <span
        //     style={{
        //       display: 'inline-block',
        //       fontSize: 16,
        //       fontWeight: 500,
        //       overflow: 'hidden',
        //       textOverflow: 'ellipsis',
        //       width: '224px',
        //       whiteSpace: 'nowrap',
        //     }}
        //   >
        //     {projectInfo.title}
        //   </span>
        // }
      ></Common.ListItem>
    );
  });
};

/**
 * 团体预定需要增加这两个字段
 *
 * @param {*} props
 * @returns
 */
const GroupBooking = ({ errors, notes }) => {
  const { t } = useTranslation();
  return (
    <div className="user-form-group">
      <Form.Item
        label={t("GROUP_NUMBER")}
        name="seats"
        help={
          errors.seats ? (
            <Common.Help>{t("ERROR.GROUP_NUMBER")}</Common.Help>
          ) : null
        }
      >
        <Common.GroupBookSeat />
      </Form.Item>
      {notes}
      <Form.Item
        label={t("GROUP_REMARK")}
        name="remark"
        style={{ marginBottom: "16px" }}
      >
        <Input placeholder={t("GROUP_REMARK_PH")} />
      </Form.Item>
    </div>
  );
};

/**
 * 发送短信验证码
 *
 * @param {*} props
 * @returns
 */
const CodeButton = (props) => {
  let [sec, setSec] = useState(60);
  let [hasTimer, setTimer] = useState(false);
  const { t } = useTranslation();
  let timeless = async () => {
    if (!props.mobile) return;
    if (hasTimer) return;
    try {
      let {
        status,
        expired_seconds,
      } = await reservationService.sendMobileToken(
        props.country_code,
        props.mobile.replace(/\s/g, "")
      );
      let seconds = expired_seconds || sec;
      setSec(seconds);
      if (!status) return console.warn("验证码未能发送");
      timer = setInterval(() => {
        if (seconds <= 1) {
          clearInterval(timer);
          setTimer(false);
          return setSec(0);
        }
        setSec(seconds--);
      }, 1000);
      setTimer(true);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <span className="code-button" onClick={timeless} disabled={timer}>
      {!hasTimer ? t("SEND_CODE") : `${sec} ${t("SECONDS")}`}
    </span>
  );
};

let PrePay = ({
  deals,
  disabled,
  payment,
  setPaymentVisible,
  onSubmit,
  loading,
  amount,
  suffix,
  errtip,
  paymentInfo,
}) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const { currency, item_count } = paymentInfo;
  let enablePay = deals && deals.enable_pre_pay;
  if (!amount) return null;
  // 如果有押金，金额使用押金金额，否则使用套餐金额
  let price = currency + " " + Number(amount).toLocaleString();
  return (
    <React.Fragment>
      {enablePay && (
        <React.Fragment>
          <section className="pre-title">
            <p>{deals.category_name}</p>
            <div>
              <span>{currency + "" + deals.deal_price}</span>
              <span> * {item_count}</span>
            </div>
          </section>
          <Common.ListItem
            title={t("PAYMENT.Subtotal")}
            value={
              <span
                style={{ fontWeight: 500, fontSize: 16, lineHeight: "20px" }}
              >
                {price}
              </span>
            }
          ></Common.ListItem>
        </React.Fragment>
      )}
      {paymentInfo.amount_type === "deposit" && (
        <React.Fragment>
          <section className="pre-title">
            <p>{t("PAYMENT.Deposit required")}</p>
            <div>
              <span>{currency + "" + (amount / item_count).toFixed(2)}</span>
              <span> * {item_count}</span>
            </div>
          </section>
          <Common.ListItem
            title={t("PAYMENT.Subtotal")}
            value={
              <span
                style={{ fontWeight: 500, fontSize: 16, lineHeight: "20px" }}
              >
                {price}
              </span>
            }
          />
        </React.Fragment>
      )}
      <PaymentSelector
        payment={payment}
        projectInfo={restaurantStore.projectInfo}
        paymentInfo={paymentInfo}
        errtip={errtip}
        setPaymentVisible={setPaymentVisible}
      ></PaymentSelector>
      <PaymentAction
        left={
          paymentInfo.amount_type === "deposit"
            ? t("PAYMENT.Deposit") + ":"
            : ""
        }
        price={price}
        originPrice={price}
        disabled={disabled}
        loading={loading}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

export default Guest;
