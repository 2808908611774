import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { Row, Col } from "antd";
import Pick from "@/components/pick";
import "./index.less";

/**
 * 选择预定时间
 *
 * @param {*} props
 */
const Times = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const onClick = (data) => {
    restaurantStore.selectTime(data.time);
  };
  return useObserver(() => {
    const { times, form } = restaurantStore;
    return (
      <div className="step-times">
        <h2>{t("SELECT TIME")}</h2>
        {/* 时间分组 */}
        {Object.keys(times).map((group) => {
          return (
            <div className="time-group" key={group}>
              <h3>{group}</h3>
              <Row gutter={24}>
                {restaurantStore.times[group].map((item, index) => {
                  let data = dataProxy(item);
                  let desc =
                    data.discount ||
                    (data.seats
                      ? `${data.seats} ${t("SEATS")}`
                      : t("NO_SEATS"));
                  return (
                    <Col span={6} key={"" + index}>
                      <Pick
                        onClick={() => onClick(item)}
                        label={data.time}
                        discount={data.discount !== false}
                        desc={desc}
                        selected={form.time == item.time}
                        disabled={data.disabled}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </div>
    );
  });
};

// 构造渲染数据
function dataProxy(item) {
  let seats = [...item.seats.available].pop();
  let disabled = !seats;
  let discount = item.off_peak ? item.off_peak.discount : false;
  let { time } = item;
  return {
    time,
    seats,
    discount,
    disabled,
  };
}

export default Times;
