import React from "react";
import memberStore from "./member";
import restaurantStore from "./restaurant";
import paymentStore from "./payment";
import projectsStore from "./projects";
import voucherStore from "./voucher";
import masterCardStore from "./master-card";

export const StoresContext = React.createContext({
  memberStore,
  restaurantStore,
  paymentStore,
  projectsStore,
  voucherStore,
  masterCardStore,
});

export const useStores = () => React.useContext(StoresContext);
