import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useStores } from "../../../store";
import * as Common from "../../../components/common";
import { useEffect } from "react";
/**
 * 餐厅信息
 *
 * @returns
 */
const BookRestaurant = () => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  function onTagsClick() {
    document
      .querySelector(".restaurant-content--tags")
      .classList.toggle("show-all");
  }
  return useObserver(() => {
    if (!restaurantStore.bookingInfo.restaurant) return null;
    const {
      cover,
      book_config,
      tags,
      ratings_avg,
      phone,
      address,
      name,
      avg_price,
      format_avg_price,
      cuisines,
      region,
      lng,
      lat,
    } = restaurantStore.bookingInfo.restaurant;
    const price = `${format_avg_price}/p.p`;
    const rate = parseFloat(ratings_avg, 10).toFixed(1);
    const policy = function () {
      try {
        return book_config.cancel.policy;
      } catch (error) {
        return null;
      }
    };
    return (
      <section className="book-result--restaurant">
        <Policy policy={policy()} />
        {/* <Notes notes={book_config.booking_notes} /> */}
        <header className="list-head">{t("RESTAURANT")}</header>
        <article className="restaurant-profile">
          <div className="restaurant-image">
            <img src={cover} width="82" height="60" />
          </div>
          <div className="restaurant-content">
            <div className="restaurant-content--head">
              <div className="restaurant-cuisines">
                <Cuisines cuisines={cuisines} />
              </div>
              <div className="restaurant-name">{name}</div>
              <div className="restaurant-price">{price}</div>
              <div className="restaurant-rate">
                <strong>{rate}</strong>
                <span>{t("RATING")}</span>
              </div>
            </div>
            <div className="restaurant-content--address">
              <span>
                <i className="icon icon-location"></i>
                {region.name}
              </span>
            </div>
            <div className="restaurant-content--tags">
              <Tags tags={tags} onClick={onTagsClick} />
            </div>
          </div>
        </article>
        <div className="restaurant-map">
          <MapView region={region} lng={lng} lat={lat} />
        </div>
        <footer className="restaurant-address">
          <Common.ListItem
            title={<Common.Icon text={address} icon="icon icon-map" />}
            value={
              <CopyToClipboard text={address} onCopy={() => alert("Copied!")}>
                <span className="icon icon-copy"></span>
              </CopyToClipboard>
            }
          ></Common.ListItem>
          <Common.ListItem
            title={<Common.Icon text={phone} icon="icon icon-info" />}
            value={
              <CopyToClipboard text={phone} onCopy={() => alert("Copied!")}>
                <span className="icon icon-copy"></span>
              </CopyToClipboard>
            }
          ></Common.ListItem>
        </footer>
      </section>
    );
  });
};

const Cuisines = ({ cuisines }) => {
  let max = cuisines.length - 1;
  return (
    <React.Fragment>
      {cuisines.map((item, index) => {
        return (
          <React.Fragment key={item.name}>
            <span>{item.name}</span>
            {index < max && <i></i>}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const Tags = ({ tags, onClick }) => {
  let size = tags.length;
  return (
    <React.Fragment>
      {tags.map((item, index) => {
        return (
          <div className="restaurant-tag" key={index}>
            <div className="tag-icon">
              <img src={item.icon_url} width="12" height="12" />
              <span>{item.name}</span>
            </div>
            {size > 1 && index === 0 && <DownOutlined onClick={onClick} />}
          </div>
        );
      })}
    </React.Fragment>
  );
};

const Policy = ({ policy }) => {
  if (!policy) return null;
  return (
    <div className="cancellation-policy">
      <Common.ListItem
        title={
          <Common.Icon
            text={"Cancellation policy"}
            icon="icon icon-info selected"
          />
        }
      ></Common.ListItem>
      <article>{policy}</article>
    </div>
  );
};

const Notes = ({ notes }) => {
  if (!notes) return null;
  return (
    <div className="booking-notes">
      <Common.ListItem
        title={<Common.Icon text={"Booking note 1"} icon="icon icon-info" />}
      ></Common.ListItem>
      <article>{notes.lng1}</article>
    </div>
  );
};

/**
 * 地图。需要自动判定选择谷歌还是高德地图
 *
 * @param {*} { lat, lng }
 * @returns
 */
const MapView = ({ region, lat, lng }) => {
  useEffect(() => {
    var inChina = region.country_name === "china";

    // 高德地图
    var normal = L.layerGroup([
      L.tileLayer(
        "http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_en&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
        {
          maxZoom: 18,
          minZoom: 5,
          subdomains: "1234",
        }
      ),
    ]);
    // 谷歌地图
    var googleMap = L.tileLayer.chinaProvider("Google.Normal.Map", {
      maxZoom: 18,
      minZoom: 5,
    });

    var google = L.layerGroup([googleMap]);

    const map = L.map("leaflet-map", {
      center: [lat, lng],
      zoom: 15,
      layers: [inChina ? normal : google],
      zoomControl: false,
      attributionControl: false,
    });
    var position = new L.LatLng(lat, lng);
    var marker = L.marker(position, {
      icon: L.icon({
        iconSize: [26, 33],
        iconUrl: "//assets.diningcity.cn/pin-blue.png",
      }),
    });
    map.addLayer(marker);

    return () => {
      map.remove();
    };
  }, []);

  return <div className="map-view" id="leaflet-map"></div>;
};

export default BookRestaurant;
