import http, { getQuery } from "./http";

const BASE = process.env.REACT_APP_API;

class Service {
  member = null;
  benefits = null;
  /**
   * 默认从 URL 中获取 private_token
   * 如果URL没有 从当前的域名的主域名获取 private_token
   *
   * 如果当前域名是 website.diningcity.cn/xxx
   * www.diningcity.cn/api/v2/members/profile?callback=JSONP_CALLBACK
   *
   * website.diningcity.hk/xxx
   * www.diningcity.hk/api/v2/members/profile?callback=JSONP_CALLBACK
   *
   * website.diningcity.sg/xxx
   * www.diningcity.sg/api/v2/members/profile?callback=JSONP_CALLBACK
   *
   * @memberof Service
   */
  get jsonCallback() {
    let host = "";
    const url = location.href;
    if (url.includes("website.diningcity.cn")) {
      host = "https://dcwebsiteapi.diningcity.cn";
    } else if (url.includes("website.diningcity.hk")) {
      host = "https://dcwebsiteapi.diningcity.hk";
    } else if (url.includes("website.diningcity.sg")) {
      host = "https://dcwebsiteapi.diningcity.sg";
    } else if (url.includes("staging-book")) {
      host = 'https://www.diningcitydeals.com'
    } else {
      host = "https://dcwebsiteapi.diningcity.cn";
    }
    return host + "/api/v2/members/profile?callback=JSONP_CALLBACK";
  }

  /**
   * 通过token获取用户信息
   *
   * fe9a6e21a1f34f279b5a:1307862
   * @param {string} private_token
   * @returns
   * @memberof Service
   */
  getUserByToken(private_token) {
    return new Promise((resolve, reject) => {
      http
        .get(`${BASE}/member`, {
          headers: http.dropEmpty({
            "access-token-member": private_token,
            ...http.getHeaders().headers,
          }),
        })
        .then(({ data }) => {
          this.member = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取用户信息失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取用户信息
   *
   * Safari浏览器在跨域情况下，无法获得cookie，此时通过url上的private_token参数获取用户信息
   *
   * @returns
   * @memberof Service
   */
  show(isAdminBooking) {
    if (this.member) return Promise.resolve(this.member);
    let { private_token } = getQuery();
    if (private_token) return this.getUserByToken(private_token);
    if (isAdminBooking) return Promise.reject("not login");
    return new Promise((resolve, reject) => {
      http
        .jsonp(this.jsonCallback)
        .then(({ member }) => {
          this.member = member;
          resolve(member);
        })
        .catch((error) => {
          console.error("获取用户信息失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 修改用户信息
   *
   * @returns
   * @memberof Service
   */
  update(member) {
    let url = `${BASE}/member`;
    let headers = {
      headers: http.dropEmpty({
        "access-token-member": this.member.private_token,
      }),
    };
    return new Promise((resolve, reject) => {
      http
        .put(url, member, headers)
        .then(() => {
          this.member = { ...this.member, ...member };
          resolve(this.member);
        })
        .catch((error) => {
          console.error("更新用户信息 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 补充用户姓名
   *
   * @returns
   * @memberof Service
   */
  updateProfile(member) {
    let url = `${BASE}/member`;
    let headers = {
      headers: http.dropEmpty({
        "access-token-member": this.member.private_token,
      }),
    };
    let data = {
      first_name: member.first_name,
      last_name: member.last_name,
    };
    return new Promise((resolve, reject) => {
      http
        .put(url, data, headers)
        .then((data) => {
          this.member = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("补充用户姓名 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取用户福利
   *
   * @returns
   * @memberof Service
   */
  getLoyaltyBenefits(restaurantId) {
    if (this.benefits) return this.benefits;
    let url = `/restaurants/${restaurantId}/default`;
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then(({ data }) => {
          this.benefits = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取用户福利 失败, ", error);
          resolve([]);
        });
    });
  }

  /**
   * 获取微信openId
   * @param {*} code 
   * @returns 
   */
  getOpenId(code) {
    return http.get('/members/wechat_auth', {
      params: { code }
    }).then(res => {
      let openid = res.data.openid || sessionStorage.getItem(code)
      if (openid) {
        sessionStorage.setItem(code, openid)
      }
      return openid || null
    })
  }
}

export default new Service();
