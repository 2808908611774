import http, { getQuery } from "@/service/http";
import { action, computed, observable } from "mobx";

const fallback = [];

const BASE = process.env.REACT_APP_API;

class ProjectsStore {
  @observable prokects = []; // 项目列表

  /**
   * 获取预订项目
   *
   * @memberof ProjectsStore
   */
  @action
  async list(restaurant_id) {
    try {
      const { client, uid } = getQuery();
      let { data } = await http.get(
        `${BASE}/public/restaurants/${restaurant_id}/projects/program_and_event`,
        {
          headers: http.dropEmpty({
            "access-token": getQuery()["access-token"],
            client,
            uid,
          }),
        }
      );
      return data || fallback;
    } catch (error) {
      console.error(error);
      return fallback;
    }
  }
  @action
  async show(project) {
    try {
      let { data } = await http.get(`/project_configs/${project}`);
      return data || {};
    } catch (error) {
      return {};
    }
  }
  @action
  async deals(restaurant_id, project) {
    try {
      const { client, uid } = getQuery();
      let { data } = await http.get(
        `${BASE}/public/restaurants/${restaurant_id}/deals`,
        {
          params: {
            project,
            per_page: 999,
          },
          headers: http.dropEmpty({
            "access-token": getQuery()["access-token"],
            client,
            uid,
          }),
        }
      );
      return data || fallback;
    } catch (error) {
      return fallback;
    }
  }
  @action
  async validCard(project, code_or_password) {
    try {
      let { data } = await http.get("/access_codes/wide_valid", {
        params: {
          project,
          code_or_password,
        },
      });
      return data || [];
    } catch (error) {
      return [];
    }
  }
}

export default new ProjectsStore();
