import React, { useState, useEffect, useMemo } from "react";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import "./index.less";
import * as Common from "@/components/common";
import http, { getQuery } from "@/service/http";
import { useStores } from "@/store";
import IconDiningcity from "@/images/admin_diningcity.svg";
import IconEvent from "@/images/admin_event.svg";
import IconProgram from "@/images/admin_program.svg";
import IconNormal from "@/images/admin_normal.svg";
import IconDeal from "@/images/deals_red.svg";

const diningcity = {
  title: "DiningCity",
  project: "diningcity",
  is_need_normal_booking: true,
  is_need_deal_booking: true,
};

/**
 * 选择不同的预订项目
 *
 * 如果是来自ops，只允许选择DiningCity和Events
 *
 * 如果是来自DC Partners Console, 只允许选择Programs
 *
 * 是否允许选择 a la tea, 取决于program设置
 *
 * @param {*} props
 */
const Projects = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState([]);
  const [deals, setDeals] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [loading, setLoading] = useState(false);
  const { projectsStore, restaurantStore } = useStores();
  const { id } = useParams();
  const history = useHistory();

  // 进入预订, 携带选择的项目和套餐
  function next(project, offer) {
    let query = getQuery();
    let path = `/restaurants/${id}/reservation?`;
    query = qs.stringify({
      ...query,
      project,
      program: project,
      deal_id: offer,
      from: "admin",
    });
    restaurantStore.step = restaurantStore.STEPS.START;
    history.replace(path + query);
  }

  // 返回选择项目
  function onBack() {
    setFilters(projects);
    setCurrentProject({});
    setDeals([]);
    setLoading(false);
  }

  // 判断是否可选择
  async function setEnable(list) {
    return setProjects(list.map(item => {
      item.enable = true
      return item
    }))
      // 判断每个项目是否可以预订
      let url = `/restaurants/${id}/reservable`;
      let map = {}
      let reservables = await Promise.all(list.map(({ project }) => {
        return http.get(url, {
          params: {project: project}
        }).then(res => {
          return {project, enable: !!res.data.data.length}
        })
      }))
      reservables.forEach(({ project, enable }) => {
        map[project] = enable
      })
      setProjects(list.map(item => {
        item.enable = map[item.project]
        return item
      }))
  }

  // 选中项目
  function onSelect(item) {
    if (currentProject.title) return;
    setCurrentProject(item);
    setFilters(projects.filter((i) => i.title === item.title));
    // 允许套餐预订
    if (item.is_need_deal_booking) {
      setLoading(true);
      projectsStore.deals(id, item.project).then((data) => {
        setDeals(data);
        setLoading(false);
      });
    }
  }
  useEffect(() => {
    let { from, project } = getQuery();
    if (project) {
      projectsStore.show(project).then((data) => {
        setEnable([data]);
      });
    } else {
      projectsStore.list(id).then((data) => {
        let projects = [];
        if (from === "ops") {
          projects = data.filter((item) => item.project_type === "event");
          projects.unshift(diningcity);
        }
        if (from === "console") {
          projects = data.filter((item) => item.project_type === "program");
        }
        setEnable(projects);
      });
    }
  }, []);

  useEffect(() => {
    // 如果url参数携带有project信息，自动选中其中一个
    let { project } = getQuery();
    let current = projects.find((item) => project === item.project);
    if (current) {
      onSelect(current);
    } else {
      setFilters(projects);
    }
  }, [projects]);

  const hideBack = useMemo(() => {
    let project = getQuery().project;
    return project || !currentProject.title;
  }, [currentProject]);

  const showNormal = useMemo(() => {
    const { is_need_normal_booking, project_type } = currentProject;
    if (project_type === "event") return true;
    return !!is_need_normal_booking;
  }, [currentProject]);

  return (
    <div className="step-projects">
      <h2>{t("YOU ARE BOOKING")}:</h2>

      <div className="step-projects--list">
        {filters.map((item, index) => {
          return (
            <ProjectItem
              key={index}
              select={currentProject.title}
              {...item}
              onClick={() => onSelect(item)}
            />
          );
        })}
      </div>

      <div className="step-projects--loading" hidden={!loading}>
        <Common.Loading red loop></Common.Loading>
      </div>

      <div className="step-projects--current" hidden={!currentProject.title}>
        <h2>{t("SELECT YOUR OFFER")}:</h2>
        <div className="step-projects--deals">
          {showNormal ? (
            <ProjectItem
              title={t("A la carte")}
              icon={IconNormal}
              onClick={() => next(currentProject.project, "")}
            />
          ) : null}

          {deals.map((item, index) => {
            return (
              <ProjectItem
                key={index}
                {...item}
                title={item.name}
                icon={IconDeal}
                onClick={() => next(currentProject.project, item.id)}
              />
            );
          })}
        </div>
      </div>
      <div className="page-footer-place" hidden={hideBack}></div>
      <footer className="page-footer" align="left" hidden={hideBack}>
        <Common.Button onClick={onBack}>
          {t("BACK")}
          {hideBack}
        </Common.Button>
      </footer>
    </div>
  );
};

const ProjectItem = (props) => {
  const logo = useMemo(() => {
    let { project_type } = props;
    if (props.icon) {
      return props.icon;
    }
    if (project_type === "program") {
      return IconProgram;
    }
    if (project_type === "event") {
      return IconEvent;
    }
    return IconDiningcity;
  }, [props]);
  return (
    <div
      className="step-projects--item"
      data-select={props.title === props.select}
      data-enable={props.enable}
      onClick={props.onClick}
    >
      <div className="item-icon">
        <img src={logo} alt="" />
      </div>
      <div className="item-text">{props.title}</div>
    </div>
  );
};

export default Projects;
