import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import * as Common from "@/components/common";
import { CancelModal } from "@/pages/success";
import { useStores } from "@/store";
import { lang } from "@/service/http";
import qs from "qs";

const Foot = ({ booking, reload }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { restaurantStore } = useStores();
  const history = useHistory();
  const can_cancel = !!booking.can_cancel;
  const can_update = !!booking.can_update;
  /**编辑预订 */
  async function editBooking() {
    const { reservation_code, id, project } = booking;
    const path = `/restaurants/${booking.restaurant.id}/reservation/edit?`;
    const query = qs.stringify({
      reservation_id: id,
      reservation_code: reservation_code,
      project: project,
      lang: lang,
      from: restaurantStore.STEPS.COMPLETE,
    });
    await restaurantStore.assignEdit(reservation_code, id);
    history.replace(path + query);
  }
  /**取消预订 */
  async function cancelBooking() {
    setVisible(false);
    try {
      await restaurantStore.cancel(booking.reservation_code, booking.id);
      reload();
      message.success(t("RESERVATION_CANCELLED"));
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  }
  // 展示支付动画
  return (
    <div className="booking-detail-foot">
      <div className="page-footer-place"></div>
      <footer className="page-footer" align="right">
        {can_cancel && (
          <Common.Button onClick={() => setVisible(true)}>
            {t("CANCEL")}
          </Common.Button>
        )}
        {can_update && (
          <Common.Button type="primary" onClick={editBooking}>
            {t("MODIFY")}
          </Common.Button>
        )}
      </footer>
      <CancelModal
        visible={visible}
        onSubmit={cancelBooking}
        setVisible={setVisible}
      />
    </div>
  );
};

export default Foot;
