import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useStores } from "@/store";
import "./index.less";
import * as Common from "@/components/common";
import { getQuery, lang } from "@/service/http";
import member from "@/service/member";
import Head from "./components/head";
import Detail from "./components/detail";
import Foot from "./components/foot";
import { PosPayAPI } from "./api";
import { client } from "@/utils";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { useHistory } from "react-router-dom";

window.member = member;

function getWxCode() {
  let ua = navigator.userAgent.toLowerCase();
  let iswx =
    ua.indexOf("micromessenger") > -1 &&
    window.__wxjs_environment !== "miniprogram";
  return new Promise((resolve) => {
    // 不是微信浏览器打开
    if (!iswx) return resolve();
    let query = getQuery();
    // 已经获取到code
    if (query.wx_code) return resolve();
    // 开始微信静默授权
    let authorize = "https://open.weixin.qq.com/connect/oauth2/authorize?";
    // 需要去掉code参数
    let redirect_uri =
      (process.env.NODE_ENV === "development"
        ? "https://staging-book.diningcity.asia"
        : location.origin) + "/#/pos-pay?";
    delete query.wx_code;
    delete query.state;
    redirect_uri += qs.stringify(query);
    authorize += [
      `appid=wx9dc05b9fd541658c`,
      `redirect_uri=${encodeURIComponent(redirect_uri)}`,
      "response_type=code",
      "scope=snsapi_base",
      `state=123`,
    ].join("&");
    authorize += "#wechat_redirect";
    location.href = authorize;
  });
}

const PosPay = () => {
  const [orderInfo, setOrderInfo] = useState(null); // 订单信息
  const [payMethod, setPayMethod] = useState(""); // 默认支付方式
  const [loading, setLoading] = useState(false); // 支付过程动画
  const [booking, setBooking] = useState(null); // 预订信息
  const [openId, setOpenId] = useState(null); // 预订信息
  const [payMethodList, setPayMethodList] = useState([]); // 支付方式列表
  const [weappConfig, setWeappConfig] = useState({});
  const { restaurantStore, paymentStore, memberStore } = useStores();
  const timer = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const payProvider = useMemo(() => {
    let provider = payMethodList.find((item) => item.keyword === payMethod)
      ?.provider;
    return provider;
  }, [payMethod, payMethodList]);
  const showWxLunch = useMemo(() => {
    return payMethod === 'uac_upacp' && paymentStore.deviceDetect() === 'weixin'
  }, [payMethod])



  // 如果选择了银联支付，需要获取小程序信息
  useEffect(() => {
    clearInterval(timer.current);
    if(!showWxLunch) return setWeappConfig({});
    let [host] = location.href.split("#");
    paymentStore
    .prePayment({
      payment_method: payMethod,
      success_url: `${host}#/redirect?redirect=${encodeURIComponent(location.href)}`,
      payment: orderInfo.payment,
      open_id: openId,
    }).then(data => {
      clearInterval(timer.current);
      checkPayStatus();
      timer.current = setInterval(checkPayStatus, 3500);
      setWeappConfig({
        appid: data.miniPayRequest.cqpMpAppId,
        path: data.miniPayRequest.cqpMpPath,
      })
    })
  }, [showWxLunch])

  // 唤起支付
  const callPayment = useCallback(() => {
    let client = paymentStore.deviceDetect()
    let { reservation_code, book_id } = getQuery();
    if (loading) return;
    setLoading(true);
    // 跳转到DC微信小程序
    if(payMethod === 'uac_wx' && client === 'mobile') {
      return paymentStore.getScheme(book_id, reservation_code, payMethod, payProvider, orderInfo.payment).then(outlink => {
        debugger;
        location.href = outlink
      })
    }
    let [host] = location.href.split("#");
    paymentStore
      .prePayment({
        payment_method: payMethod,
        success_url:
          payProvider === "amexweb"
            ? location.href
            : `${host}#/redirect?redirect=${encodeURIComponent(location.href)}`,
        payment: orderInfo.payment,
        open_id: openId,
      })
      .then((data) => {
        if (payProvider === "ping++") {
          // ping++支付
          paymentStore.callPayment().then((status) => {
            // 支付不成功，取消支付等待窗口
            if (status !== "success") {
              setLoading(false);
            }
          });
        } else if (payProvider === "amexweb") {
          // 运通支付
          if (data.payUrl) {
            window.location.replace(data.payUrl);
          } else {
            setLoading(false);
            message.error("支付失败: 未返回payUrl");
          }
        } else if(payProvider === 'unionpay') { // 银联支付
          if(data.url) {
            window.location.replace(data.url)
          } else {
            setLoading(false);
            message.error("支付失败: 未返回url");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        message.error(error.message);
      });
  }, [orderInfo, payMethod, openId, payProvider]);
  // 支付完成
  const onComplete = useCallback(() => {
    setLoading(false);

    // 支付完成，重新获取订单状态
    let { order_id } = getQuery();
    PosPayAPI.getOrder(order_id).then((orderInfo) => {
      setOrderInfo(orderInfo);
    });
  }, []);
  // 查看预订详情
  const openBookingPage = useCallback(() => {
    let { reservation_code, book_id, wx_code } = getQuery();
    let query = qs.stringify({
      reservation_code,
      reservation_id: book_id,
      wx_code,
      lang,
    });
    history.push("/booking-detail?" + query);
  }, []);
  // 获取支付状态
  function checkPayStatus() {
    paymentStore.getPayment().then(({ data }) => {
      if (data.status !== "pending") {
        clearInterval(timer.current);
        onComplete();
      }
    }).catch(() => {
      clearInterval(timer.current);
      onComplete();
    })
  }

  // 获取订单信息
  useEffect(() => {
    getWxCode().then(() => {
      document.title = t("PAYMENT.Payment Details");
      let { order_id, book_id, reservation_code, wx_code } = getQuery();
      if (!order_id) return;
      // 获取微信openId
      if (wx_code) {
        member.getOpenId(wx_code).then((openId) => {
          setOpenId(openId);
        });
      }
      PosPayAPI.getBooking(reservation_code, book_id).then((res) => {
        console.log("booking", res);
        setBooking(res);
        // 用户信息
        memberStore.member = {
          first_name: res.first_name,
          last_name: res.last_name,
          email: res.email,
          mobile: res.mobile,
          country_code: res.country_code,
          avatar_url: res.member?.avatar_url,
        };
        // 餐厅信息
        restaurantStore.restaurant = res.restaurant;
      });
      // 请求订购信息
      PosPayAPI.getOrder(order_id).then(async (orderInfo) => {
        console.log("orderInfo", orderInfo);
        setOrderInfo(orderInfo);
        // 获取支持的支付方式
        paymentStore
          .getMethods({
            deal_id: orderInfo.booking.deal_id,
            restaurant_id: orderInfo.restaurant.id,
            payment_id: orderInfo.payment.id,
          })
          .then(() => {
            paymentStore.paymentMethods = paymentStore.paymentMethods.filter(
              (item) => {
                return orderInfo.payment.channels.includes(item.keyword);
              }
            );
            setPayMethodList(
              JSON.parse(JSON.stringify(paymentStore.paymentMethods))
            );
          });
      });

      paymentStore.getWxConfig();
    });
  }, []);

  // 解析订单信息
  useEffect(() => {
    if (!orderInfo) return;
    const { channel, channels } = orderInfo.payment;
    const channelList = channels.split(',')
    if (channel) {
      setPayMethod(channel);
    } else if (!channels.includes(",")) {
      // 选中唯一支付方式
      setPayMethod(channels);
    } else if (channels === "amexweb") {
      // 设置默认支付方式: 运通
      setPayMethod("amexweb");
    } else if (
      channelList.includes("wx") &&
      (client === "WECHAT" || client === "MP")
    ) {
      // 设置默认支付方式: 微信
      setPayMethod("wx");
    } else if (channelList.includes("alipay") && client === "DINGTALK") {
      // 设置默认支付方式: 支付宝
      setPayMethod("alipay");
    }

    // 解除loading动画
    document.body.classList.add("loaded");
    // 设置页面主题色
    let isAmex = channels === "amexweb";
    if (isAmex) {
      document.body.classList.add("isAmex");
    }
    document.body.style.setProperty("--theme", isAmex ? "#006FCF" : "#ed2224");
    document.body.style.setProperty(
      "--theme-pay",
      isAmex ? "#006FCF" : "#A0D468"
    );
  }, [orderInfo]);

  return orderInfo && booking ? (
    <div className="pos-pay-page">
      {/* 头部 */}
      <Head
        title={orderInfo.payment.status_humanize}
        payMethod={payMethod}
      ></Head>
      {/* 餐厅和用户信息 */}
      <Common.UserProfile orderInfo={orderInfo} onClick={openBookingPage}>
        <Common.Bookinfo
          booking={{
            date: booking.reservation_date,
            time: booking.formated_time,
            seats: booking.reservation_seats,
          }}
        ></Common.Bookinfo>
        {booking.discount ? (
          <Common.ListItem
            value={
              <span
                style={{
                  fontSize: 13,
                  color: "var(--theme)",
                  fontWeight: "bold",
                }}
              >
                {booking.discount}
              </span>
            }
            title={
              <Common.Icon
                text={
                  <span style={{ fontSize: 15, color: "#353535" }}>
                    {t("RESERVATION_INFO.BS")}
                  </span>
                }
                icon={
                  payMethod === "amexweb"
                    ? "icon icon-save-blue"
                    : "icon icon-save"
                }
              ></Common.Icon>
            }
          ></Common.ListItem>
        ) : null}
        {(booking.tags || []).map((item) => {
          return (
            <Common.ListItem
              key={item.title}
              title={
                <Common.Icon
                  text={
                    <span style={{ fontSize: 13, color: "#353535" }}>
                      {item.title}
                    </span>
                  }
                  img={<img src={item.icon_url} width="24" height="24" />}
                ></Common.Icon>
              }
            ></Common.ListItem>
          );
        })}
      </Common.UserProfile>

      {/* 支付信息 */}
      <Detail
        orderInfo={orderInfo}
        payMethod={payMethod}
        payMethodList={payMethodList}
        setPayMethod={setPayMethod}
      ></Detail>
      <div style={{ height: 90 }}></div>
      {/* 支付按钮 */}
      <div className="page-footer" style={{ maxWidth: 1400 }}>
        <Foot
          {...weappConfig}
          orderInfo={orderInfo}
          onClick={showWxLunch ? null : callPayment}
          payMethod={payMethod}
        ></Foot>
      </div>
      {/* 支付动画 */}
      <Common.PaymentModal
        visible={loading}
        onComplete={onComplete}
      ></Common.PaymentModal>
    </div>
  ) : null;
};

export default PosPay;
