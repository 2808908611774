import React from "react";
import "./index.less";

/**
 * 选择器标签
 *
 * @param {*} props
 */
const Pick = (props) => {
  return (
    <div
      className="pick-item"
      data-discount={props.discount}
      data-selected={props.selected}
      data-disabled={props.disabled}
      onClick={props.onClick}
    >
      <div className="pick-item--label">{props.label}</div>
      {props.desc ? <div className="pick-item--desc">{props.desc}</div> : null}
    </div>
  );
};

export default Pick;
