import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { PreAuthorization } from "@/pages/confirm/components/master-card";
import ListItem from "./ListItem";

/**
 * 支付信息
 * @param {*} param0
 * @returns
 */
const PaymentInfo = ({ booking, isPosPay }) => {
  const { t } = useTranslation();
  const payment = booking.payment;

  // 预授权
  const isPrePay =
    booking.genre === "pre_authorized" &&
    booking.payment &&
    booking.payment.channel === "mpgs";
  if (!payment) return null;
  return (
    <div className="booking-detail-payment">
      <ListItem
        show={!isPosPay && booking.genre !== "no_pay"}
        icon="info"
        label={t("Amount paid")}
        value={payment.format_price}
      ></ListItem>
      <ListItem
        icon="info"
        label={t("PAYMENT.Payment method")}
        value={payment.channel_humanize}
      ></ListItem>
      {/* 预授权 */}
      {isPrePay && <PreAuthorization type="normal"></PreAuthorization>}
      {/* 退款 */}
      <Refund booking={booking} payment={payment} />
      {/* 预授权退款 */}
      {isPrePay && !!booking.show_refund && (
        <PreAuthorization type="refund"></PreAuthorization>
      )}
    </div>
  );
};

function Refund({ booking, payment }) {
  const { t } = useTranslation();
  if (!booking.show_refund) return null;
  const refundText = useMemo(() => {
    switch (booking.genre) {
      case "deposit":
        return t("Refund amount");
        break;
      case "pre_authorized":
        return t("Amount released");
        break;
      default:
        return t("Amount reimbursed");
        break;
    }
  }, [booking]);
  return (
    <ListItem
      icon="info"
      label={refundText}
      value={payment.format_refund_price}
    ></ListItem>
  );
}

export default PaymentInfo;
