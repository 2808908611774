import React from "react";

const PageHead = (props) => {
  return (
    <div className="booking-detail-head">
      <span>{props.title}</span>
      {props.showCheck && (
        <span className="check-bill" onClick={props.onClick}>
          {props.check}
        </span>
      )}
    </div>
  );
};

export default PageHead;
