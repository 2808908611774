import React from "react";
import { useObserver } from "mobx-react-lite";
import { useStores } from "../../store";
import "./index.less";
import Member from "./member";
import Guest from "./guest";
import ConfirmDetails from "./member/confirm-details";

/**
 * 用户确认
 *
 * @param {*} props
 */
export default (props) => {
  const { memberStore } = useStores();

  return useObserver(() => {
    // 会员账户
    if (memberStore.isMember) {
      // 需要补充资料的会员账户
      if (memberStore.noMemebrInfo) {
        return <ConfirmDetails></ConfirmDetails>;
      }
      return <Member />;
    }
    // 游客账户
    return <Guest />;
  });
};
