import React, { useEffect, useState, useRef, useMemo } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../store";
import reservationService from "../../service/reservation";
import { Input, Form } from "antd";
import {
  MinusCircleFilled,
  PlusCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import * as Common from "../common";
import "./index.less";

/**
 * 券码
 *
 * @param {*} props
 */
let Voucher = (props) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const [points, setPoints] = useState([]);
  const { project, restaurant_id, deal_id, voucher_codes,date, time, seats, discount, country_code } =
    restaurantStore.form;
  const { range = [], disabled } = props;
  const [min = 0] = range;
  const defaultCode = voucher_codes[0] || "";

  const max = useMemo(() => {
    return range[1] || range[0] || 1;
  }, [range]);

  useEffect(() => {
    setPoints(
      new Array(min || 1).fill("").map((item, index) => {
        return {
          enable: false,
          code: index ? "" : defaultCode,
        };
      })
    );
  }, [voucher_codes]);

  // 同步校验过的券码
  useEffect(() => {
    props.onChange &&
      props.onChange(
        points.filter((item) => item.enable).map((item) => item.code)
      );
  }, [points]);

  // 校验券码是否可用
  function verifyCode(value) {
    return reservationService.verifyVoucher({
      project,
      restaurant_id,
      deal_id,
      code: value,
      mobile: props.mobile,
      date, time, seats, discount, country_code 
    });
  }

  function onChange(index, value) {
    let newvalues = [...points];
    newvalues.splice(index, 1, value);
    setPoints(newvalues);
  }

  function onMinus(index) {
    if (points.length < 2) return;
    setPoints(points.filter((i, idx) => idx != index));
  }

  function onPlus() {
    if (points.length >= max) return;
    setPoints(points.concat([{ code: "", enable: false }]));
  }

  return useObserver(() => {
    if (!project) return null;
    if (!max) return null;
    return (
      <div className="dc-vouchers">
        <div className="dc-vouchers__top">
          <div className="dc-vouchers__top__left">
            <i className="dc-vouchers__top__left__icon"></i>
            <h3>{t("VOUCHERS.TITLE")}</h3>
          </div>

          {
            disabled ? null : (
            <div className="sub-title">
              {/* <p>{t("VOUCHERS.TIPS", { number: max })}</p> */}
              <PlusCircleFilled
                hidden={points.length >= max}
                style={{
                  fontSize: 20,
                  color: "#dc2224",
                }}
                onClick={onPlus}
              ></PlusCircleFilled>
            </div>
            )
          }
        </div>
        <p className="dc-vouchers__desc">
          {t("VOUCHERS.TIPS", { number: max })}
        </p>
        {points.map((item, index) => {
          return (
            <VoucherInput
              key={index}
              item={item}
              hidden={index < (min || 1)}
              onMinus={() => onMinus(index)}
              onView={props.onView}
              verifyCode={verifyCode}
              disabled={disabled}
              onChange={(val) => onChange(index, val)}
            />
          );
        })}
      </div>
    );
  });
};

const VoucherInput = (props) => {
  const { t } = useTranslation();
  const { disabled } = props;
  const [value, setValue] = useState(props.item.code);
  const code = useThrottle(value);
  const [error, setError] = useState("");
  const [info, setInfo] = useState(null);

  // 校验券码
  useEffect(async () => {
    props.onChange({
      code,
      enable: false,
    });
    if (!code) {
      return;
    }
    let [err, data] = await props.verifyCode(code);
    setInfo(null);
    if (err) {
      setError(err);
      return;
    }
    if (data.used) {
      setError(t("RESERVATION_INFO.USED_VOUCHERS"));
      return;
    }
    setError("");
    setInfo(data);
    props.onChange({
      code,
      enable: true,
    });
  }, [code]);

  return (
    <Input.Group>
      <Form.Item
        style={{ width: "calc(100% - 60px)" }}
        help={error ? <Common.Help>{error}</Common.Help> : null}
      >
        <Input
          size="large"
          placeholder={t("VOUCHERS.ENTER")}
          value={value}
          disabled={disabled}
          onChange={(ev) => {
            setValue(ev.target.value);
          }}
          suffix={
            info ? (
              <EyeOutlined
                onClick={() => {
                  props.onView(info);
                }}
              />
            ) : null
          }
        ></Input>
      </Form.Item>
      {
        disabled ? null : (
          <MinusCircleFilled
          style={{ position: "relative", top: 10 }}
          hidden={props.hidden}
          onClick={props.onMinus}
        ></MinusCircleFilled>
        )
      }
    </Input.Group>
  );
};

export default Voucher;

function useThrottle(value, interval = 300) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastExecuted = useRef(Date.now());

  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
  }, [value, interval]);

  return throttledValue;
}
