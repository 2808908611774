import React  from "react";

/**
 * 唤起微信小程序
 * @param {*} props 
 * @returns 
 */
export const WxOpenLaunch = (props) => {
  return <wx-open-launch-weapp
      id="launch-btn"
      appid={props.appid}
      path={props.path}
      style={{
        position: 'absolute',
        width: props.appid ? '100%' : '1px',
        height: '38px',
        opacity: 0,
        top: 0,
        left: 0,
        zIndex: 10
      }}
    >
      <script type="text/wxtag-template">
        <div style={{
          position: 'absolute',
          width: props.appid ? '100%' : '1px',
          height: '38px',
          opacity: 0,
          top: 0,
          left: 0,
          zIndex: 10,
        }}></div>
      </script>
    </wx-open-launch-weapp>
}
