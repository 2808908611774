import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from '../common'
import "./index.less";

/**
 * 自动返回指定页面
 *
 * @param {*} props
 */
export default function AutoBack ({ back_url }) {
    const { t } = useTranslation();
    const timer = useRef();
    const [time, setTime] = useState(5)
    const goBack = () => {
        window.top.location.href = back_url
    }
    const stop = () => {
        window.top.postMessage({ type: 'unlock' }, '*');
        window.parent.postMessage({ type: 'unlock' }, '*');
        clearInterval(timer.current)
        timer.current = null
    }

    useEffect(() => {
        if(!back_url) return;
        document.body.classList.add('auto-back-mode');
        window.top.postMessage({ type: 'lock' }, '*');
        window.parent.postMessage({ type: 'lock' }, '*');
        timer.current = setInterval(() => {
            setTime((val) => val - 1)
        }, 1000)
        return () => {
            stop()
        }
    }, [])

    useEffect(() => {
        if(time === 0) {
            stop();
            goBack();
        }
    }, [time])

    if(!back_url) return null
    return <div className="dc-auto-back">
        <div className="dc-auto-back--inner">
            <div className="dc-auto-back--text" dangerouslySetInnerHTML={{
                __html: t('Automatically return', {x: time})
            }}></div> 
            <Button onClick={goBack} type='primary'>{ t('Return now') }</Button>
        </div>
    </div>

};