import React from "react";
/**
 * 预定头部信息
 *
 * @param {*} { title, message, status }
 * @returns
 */
const BookHeader = ({ title, message, status, isNeedPay }) => {
  return (
    <section className="book-result__header">
      <header>
        <div className="header-left" onClick={() => history.go(-1)}>
          <i className="icon icon-arrow-left"></i>
        </div>
        <div className="book-result--title" hidden={!title}>
          {title}
        </div>
        <div className="header-right"></div>
      </header>
      {/* 预定回执 */}
      <div className="book-result--slogan" hidden={!message || isNeedPay}>
        {message}
      </div>
      {/* 订单状态 */}
      <div className="book-result--status" hidden={!status}>
        {status}
      </div>
    </section>
  );
};

export default BookHeader;
