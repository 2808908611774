import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import lottie from "lottie-web";
import moment from "moment";
import { useStores } from "../../store";
import { Modal, Row, Col, Carousel } from "antd";
import "./index.less";
import loadingData from "./loading_animation_white.json";
import loadingDataRed from "./loading_animation.json";
import processingData from "./processing.json";
import successData from "./success.json";
import failedData from "./failed.json";
import restaurantService from "../../service/restaurant";
import { throttle } from "../../utils";

import http, { lang, getQuery } from "../../service/http";

const mockText = `
Yes, I have rYes, I have read and agreed to the Privacy Policy
aPrivacy Policy and the restaurant Terms aPrivacy Policy and the
restaurant Terms and the restaurant Terms and Conditions.`;

/**
 * 提交表单之前的弹窗等待动画
 *
 * @param {*} props
 * @returns
 */
export const LoadingModal = (props) => {
  let {
    terms,
    visible,
    loading = "",
    setVisible,
    setSubmitLoading,
    onSubmit,
    onComplete,
  } = props;
  const [checklist, setList] = useState([]);
  const [currentTerm, setCurrentTerm] = useState(0);
  const { t } = useTranslation();
  let slider = null;
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const disabled = useMemo(() => {
    if (!terms.length) return false;
    let termTarget = terms[currentTerm];
    // 必选，但是没有可选内容，识别为非必选
    if (termTarget.required && termTarget.checkbox) {
      return !checklist.includes(termTarget.key);
    } else {
      return false;
    }
  }, [terms, currentTerm, checklist]);

  function onChange(key, val) {
    let arr = [...checklist];
    if (val) {
      arr.push(key);
    } else {
      let index = arr.indexOf(key);
      arr.splice(index, 1);
    }
    setList(arr);
  }
  function onNext() {
    // 当存在必须选择的条款，需要勾选才能够点击确定
    if (slider && currentTerm < terms.length - 1) {
      slider.next();
      setCurrentTerm(currentTerm + 1);
    } else {
      onSubmit([...checklist]);
      reset();
    }
  }
  function reset() {
    setCurrentTerm(0);
    setList([]);
  }
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <div className="show-loading" hidden={!loading.length}>
        {loading === "loading" ? (
          <React.Fragment>
            <Loading data={processingData}></Loading>
            <p>{t("Processing_booking")}</p>
          </React.Fragment>
        ) : null}
        {loading === "success" ? (
          <React.Fragment>
            <Loading data={successData} onComplete={onComplete}></Loading>
            <p>{t("Booking successful!")}</p>
          </React.Fragment>
        ) : null}
        {loading === "failed" ? (
          <React.Fragment>
            <Loading data={failedData}></Loading>
            <p></p>
          </React.Fragment>
        ) : null}
      </div>
      {/* 展示预定条款, 多个，可选，步进 */}
      <div className="confirm-content model-terms" hidden={!!loading.length}>
        {terms.length ? (
          <Carousel dots={false} ref={(el) => (slider = el)}>
            {terms.map((item, index) => {
              return (
                <article key={index}>
                  <header
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "pre-line",
                    }}
                  ></header>
                  <div className="modal-content">
                    <p
                      hidden={!item.tips}
                      dangerouslySetInnerHTML={{ __html: item.tips }}
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                      }}
                    ></p>
                    <Checkbox
                      hidden={!item.checkbox}
                      onChange={(val) => onChange(item.key, val)}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: item.checkbox }}
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "pre-line",
                        }}
                      ></div>
                    </Checkbox>
                  </div>
                </article>
              );
            })}
          </Carousel>
        ) : null}

        <footer>
          <Button
            onClick={() => {
              setVisible(false);
              setSubmitLoading && setSubmitLoading(false);
              reset();
            }}
          >
            {t("CANCEL")}
          </Button>
          <Button onClick={onNext} type="primary" disabled={disabled}>
            {t("CONFIRM")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

/**
 * 支付等待动画
 *
 * @param {*} props
 * @returns
 */
export const PaymentModal = (props) => {
  let { visible, onComplete } = props;
  const { paymentStore } = useStores();
  const { t } = useTranslation();
  useEffect(() => {
    let timer = setInterval(() => {
      paymentStore
        .getPayment()
        .then(({ data }) => {
          if (data.status !== "pending") {
            clearInterval(timer);
            onComplete();
          }
        })
        .catch(() => {
          clearInterval(timer);
          onComplete();
        });
    }, 2000);
    () => {
      clearInterval(timer);
    };
    if (!visible) {
      clearInterval(timer);
    }
  }, [onComplete, paymentStore, visible]);
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <div className="show-loading">
        <Loading red loop></Loading>
        <p>{t("PAYMENT.Processing payment")}</p>
      </div>
    </Modal>
  );
};

/**确认弹窗 */
export const Confirm = ({ visible, setVisible, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      {/* 展示预定条款 */}
      <div className="confirm-content">
        <header>{t("PAYMENT.Are you sure")}</header>
        <div className="modal-content">
          <p>{t("PAYMENT.will be lost")}</p>
        </div>
        <footer>
          <Button
            onClick={() => {
              setVisible(false);
            }}
          >
            {t("CANCEL")}
          </Button>
          <Button onClick={onConfirm} type="primary">
            {t("PAYMENT.Leave")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

/**
 * 用户资料
 *
 * @param {*} props
 * @returns
 */
export const UserProfile = ({
  forOther,
  setForOther,
  canBookForOthers,
  children,
  onClick,
}) => {
  const { memberStore, restaurantStore } = useStores();
  const { restaurant } = restaurantStore;
  const { t } = useTranslation();
  // 在修改预订的时候，这里应该展示顾客的信息，而不是当前用户的信息
  // todo: 修改预订，url上传递的token是无效的
  return useObserver(() => {
    const {
      email,
      avatar_url = "https://static-assets.diningcity.asia/avatars/member.png",
      mobile = "",
      country_code,
      first_name,
      last_name,
    } = memberStore.member;
    const phone = mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1 $2 $3");
    return (
      <div className="user-profile" onClick={onClick}>
        <div className="user-profile__name">
          <i className="user-profile__name__icon"></i>
          <span className="user-profile__name__value">
            {restaurant ? restaurant.name || restaurant.dirname : ""}
          </span>
        </div>
        <aside
          className="user-profile__content"
          data-others={canBookForOthers}
          hidden={forOther}
          onClick={() => (canBookForOthers ? setForOther(true) : null)}
        >
          <div className="user-profile--avatar">
            <img src={avatar_url} alt="user-avatar" />
          </div>
          <div>
            <div className="user-profile--fullname">
              {first_name}&nbsp;{last_name}
            </div>
            <div className="user-profile--email">{email}</div>
            <div className="user-profile--email">
              +{country_code} {phone}
            </div>
            {/* 允许为他人预定 */}
            {canBookForOthers && (
              <div className="book-other-edit">
                <i className="icon icon-edit"></i>
                <div>{t("EDIT_BOOKING_USER")}</div>
              </div>
            )}
          </div>
        </aside>
        {children}
      </div>
    );
  });
};

/**
 * 图标文字
 *
 * @param {*} {icon, text} 图标类名 附带文字
 * @returns
 */
export const Icon = ({ icon, text, img, children }) => {
  return (
    <span className="icon-tip">
      {img ? img : <i className={icon}></i>}
      <span>{text}</span>
    </span>
  );
};

/**
 * 文字列表
 *
 * @param {*} {title, value, desc} 左侧，右侧的组件
 * @returns
 */
export const ListItem = ({
  title,
  value,
  desc,
  hidden,
  onClick,
  className,
}) => {
  const classNames = `list-item ${className}`;
  return (
    <div className={classNames} onClick={onClick} hidden={hidden}>
      <div className="list-item--title">
        <strong>{title}</strong>
        <div className="list-item--desc">{desc}</div>
      </div>
      <div className="list-item--value">{value}</div>
    </div>
  );
};

/**
 * 圆形复选框
 *
 * @param {*} props
 */
export const Checkbox = (props) => {
  let [checked, setChecked] = useState(props.checked || false);
  let { card, hidden, noIcon } = props;
  const onChange = () => {
    let newVal = !checked;
    setChecked(newVal);
    props.onChange && props.onChange(newVal);
  };
  return (
    <div
      className="checkbox"
      data-card={card}
      data-error={props.errtip}
      onClick={onChange}
      hidden={hidden}
    >
      <div
        className="checkbox-icon"
        data-checked={checked}
        hidden={noIcon}
      ></div>
      <div className="checkbox-text">{props.children}</div>
    </div>
  );
};

export const DcCheckbox = (props) => {
  let { card, hidden, noIcon, checked } = props;
  const onChange = () => {
    props.onChange && props.onChange(!checked);
  };
  return (
    <div
      className="checkbox"
      data-card={card}
      data-error={props.errtip}
      onClick={onChange}
      hidden={hidden}
    >
      <div
        className="checkbox-icon"
        data-checked={checked}
        hidden={noIcon}
      ></div>
      <div className="checkbox-text">{props.children}</div>
    </div>
  );
};

/**
 * 表单错误提示
 *
 * @param {*} props
 * @returns
 */
export const Help = (props) => {
  return (
    <div className="form-helper" data-tip={props.tip} hidden={props.hidden}>
      <i className="icon icon-info selected"></i>
      <span>{props.children}</span>
    </div>
  );
};
/**
 * 自定义按钮
 *
 * @param {*} props
 * @returns
 */
export const Button = (props) => {
  let {
    size,
    type,
    disabled,
    block = false,
    loading,
    hidden = false,
    style = {},
  } = props;
  return (
    <button
      hidden={hidden}
      className="dc-button"
      style={style}
      data-size={size}
      data-block={block}
      data-loading={loading}
      type={type}
      disabled={disabled}
      onClick={props.onClick}
    >
      {loading ? <Loading></Loading> : <span>{props.children}</span>}
    </button>
  );
};
/**
 * 代码提示
 *
 * @param {*} props
 * @returns
 */
export const Code = ({ data }) => {
  let [hide, setHide] = useState(false);
  return (
    <div className="code-mask">
      <Button size="mini" onClick={() => setHide(!hide)}>
        展开/收起
      </Button>
      <pre hidden={hide}>
        <code className="language-bash">{JSON.stringify(data, null, 2)}</code>
      </pre>
    </div>
  );
};

/**
 * 用户预订的日期-时间-座位数信息
 *
 * @param {*} props
 * @returns
 */
export const Bookinfo = (props) => {
  const { restaurantStore } = useStores();
  return useObserver(() => {
    let { form } = restaurantStore;
    form = props.booking || form;
    if (!form.date) return null;
    let date = moment(form.date).format("MMM");
    return (
      <div className="user-bookinfo">
        <div className="bookinfo-left">
          <span className="icon-tip">
            <i className="icon icon-date"></i>
            <span>
              {String(lang).startsWith("zh")
                ? `${date}${moment(form.date).format("DD")}日`
                : `${date}.${moment(form.date).format("D")}`}
            </span>
          </span>
          <span className="icon-tip">
            <i className="icon icon-time"></i>
            <span>{form.time}</span>
          </span>
        </div>
        <div className="bookinfo-right" hidden={!form.seats}>
          <span className="icon-tip">
            <i className="icon icon-seat"></i>
            <span>{form.seats}</span>
          </span>
        </div>
      </div>
    );
  });
};

/**
 * 用户折扣信息, 可以在折扣和套餐之间切换
 *
 * @param {*} props
 * @returns
 */
export const Discount = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  return useObserver(() => {
    const { form, deals } = restaurantStore;
    if (!form.discount && !deals) return null;

    // 如果url带有套餐id，直接展示套餐
    if (form.deal_id) {
      return (
        <ListItem
          title={
            <Icon
              icon="icon icon-deal"
              text={
                <span
                  style={{
                    fontSize: 18,
                    lineHeight: "24px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "224px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {deals.name}
                </span>
              }
            ></Icon>
          }
          // value={
          //   <span>
          //     <div>{deals.name}</div>
          //     <div>{deals.description}</div>
          //   </span>
          // }
        ></ListItem>
      );
    }

    return (
      <div className="user-discount">
        <div className="discount-left">
          <i className="icon icon-save"></i>
          <strong>{t("RESERVATION_INFO.BS")}</strong>
        </div>
        <div className="discount-right">
          <strong>{form.discount}</strong>
          {/* {form.discount && deals ? (
            <i className="icon icon-arrow-right"></i>
          ) : null} */}
        </div>
      </div>
    );
  });
};

export const Loading = (props) => {
  let animation = null;
  let dom = useRef();
  useEffect(() => {
    animation = lottie.loadAnimation({
      container: dom.current,
      renderer: "svg",
      loop: props.loop ? props.loop : false,
      autoplay: true,
      animationData: props.data || (props.red ? loadingDataRed : loadingData),
    });
    animation.onComplete = () => {
      props.onComplete && props.onComplete();
    };
    () => {
      animation.destroy();
    };
  }, []);
  return (
    <span className="dc-loading">
      <i ref={dom}></i>
    </span>
  );
};

/**
 * 用户条款
 *
 * 普通预订，获取餐厅的 tos 和 tos_url
 *
 * @param {*} props
 * @returns
 */
export const Terms = (props) => {
  const { btnText } = props;
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  return useObserver(() => {
    return (
      <div className="terms-wrap">
        <span>{t("Click_button")}</span>
        {/* <Checkbox
        onChange={props.onChange}
        checked={props.checked}
        errtip={props.errtip}
      >
        <div>
          
          
          <a onClick={onShow}> {t('TERMS')}</a>
        </div>
      </Checkbox> */}
        <strong>{btnText}</strong>
        <span
          dangerouslySetInnerHTML={{
            __html: t("AGREE_PRIVACY_POLICY_AND_TERMS", {
              privacyUrl: restaurantStore.privacyUrl,
              termsAndConditions:
                restaurantStore.restaurant.terms_and_conditions_url,
            }),
          }}
        ></span>
      </div>
    );
  });
};

/**
 * 内部弹窗页头
 *
 * @param {*} props
 * @returns
 */
export const PageHeader = (props) => {
  return (
    <header>
      <div className="header-left" onClick={props.onBack}>
        <i className="icon icon-arrow-left"></i>
      </div>
      <h2>{props.children}</h2>
      <div className="header-right">{props.right}</div>
    </header>
  );
};

/**
 * 选择用户福利
 *
 * @param {*} props
 * @returns
 */
export const BenefitsPopup = (props) => {
  const { memberStore } = useStores();
  const { t } = useTranslation();
  const [values, setValues] = useState(props.data || {});
  function onChange(checked, { id, is_optional }) {
    if (!is_optional) return;
    let newVal = Object.assign({}, values, {
      [id]: checked,
    });
    setValues(newVal);
  }
  return useObserver(() => {
    const { benefits } = memberStore;
    return (
      <div className="page-popup page-animate-popup">
        <PageHeader onBack={() => props.onBack(props.data)}>
          {t("YOUR VIP BENEFITS")}
        </PageHeader>
        <div className="page-popup--body">
          {benefits.map((item) => {
            return (
              <Checkbox
                card
                noIcon={!item.is_optional}
                key={item.title}
                checked={values[item.id]}
                onChange={(checked) => onChange(checked, item)}
              >
                <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
              </Checkbox>
            );
          })}
          <footer
            style={{
              marginTop: 30,
            }}
          >
            <Button
              block={true}
              type="primary"
              onClick={() => props.onBack(values)}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                {t("CONFIRM")}
              </span>
            </Button>
          </footer>
        </div>
      </div>
    );
  });
};

/**
 * 选择套餐或折扣
 *
 * 需要同时展示折扣和套餐信息
 *
 * @param {*} props
 * @returns
 */
export const DealPopup = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    id: "", // deal_id 或 off_peak_id
    type: "", // 套餐 deal 或 折扣 discount
  });
  function onChange(checked, item) {
    if (checked) {
      setValues(item);
    }
  }
  return useObserver(() => {
    const { form, deals } = restaurantStore;
    const list = [
      {
        id: form.off_peak_id,
        type: "discount",
        desc: form.discount,
        checked: false,
      },
      {
        id: deals.id,
        type: "deal",
        desc: deals.description,
        checked: false,
      },
    ];

    return (
      <div className="page-popup">
        <PageHeader onBack={() => props.onBack(values)}>
          {t("CHOOSE_DEAL")}
        </PageHeader>
        <div className="page-popup--body">
          {list.map((item) => {
            <Checkbox card onChange={(checked) => onChange(checked, item)}>
              <span>{item.desc}</span>
            </Checkbox>;
          })}
        </div>
      </div>
    );
  });
};

/**
 * 用户条款
 *
 * 普通预订，获取餐厅的 tos 和 tos_url
 *
 * 套餐预定，展示 deals 的 term_condition
 *
 * @param {*} props
 * @returns
 */
export const TermsPopup = (props) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const query = getQuery();
  return useObserver(() => {
    const { restaurant, deals } = restaurantStore;
    return (
      <div className="page-popup page-animate-popup">
        <header>
          <div className="header-left" onClick={props.onBack}>
            <i className="icon icon-arrow-left"></i>
          </div>
          <h2>{t("TERMS")}</h2>
          <div className="header-right"></div>
        </header>
        <div className="page-popup--body">
          {query.deal_id ? (deals || {}).term_condition : restaurant.tos}
        </div>
      </div>
    );
  });
};

/**
 * 选择支付方式
 *
 * @param {*} props
 * @returns
 */
export const PaymentPopup = (props) => {
  const { t } = useTranslation();
  const { paymentStore } = useStores();
  return useObserver(() => {
    let { paymentMethods } = paymentStore;
    return (
      <div className="page-popup page-animate-popup">
        <header>
          <div className="header-left" onClick={() => props.onBack(props.data)}>
            <i className="icon icon-arrow-left"></i>
          </div>
          <h2>{t("PAYMENT.Payment method")}</h2>
          <div className="header-right"></div>
        </header>
        <div className="page-popup--body">
          <div className="payments">
            <Row gutter={10} justify={"start"}>
              {paymentMethods.map((item) => {
                return (
                  <Col span={12} key={item.keyword}>
                    <div
                      onClick={() => props.onBack(item.keyword)}
                      className="payment"
                      data-method={item.keyword}
                      style={
                        item.icon
                          ? { backgroundImage: `url(${item.icon})` }
                          : {}
                      }
                    ></div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    );
  });
};

/**
 * 检测浏览器运行环境
 *
 * @returns
 */
export const Platform = () => {
  var userAgent = navigator.userAgent;
  var platform = {
    trident: userAgent.indexOf("Trident") > -1,
    presto: userAgent.indexOf("Presto") > -1,
    webKit: userAgent.indexOf("AppleWebKit") > -1,
    gecko: userAgent.indexOf("Gecko") > -1 && userAgent.indexOf("KHTML") == -1,
    mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/),
    android:
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1, //android终端或者uc浏览器
    iPhone: userAgent.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: userAgent.indexOf("iPad") > -1, //是否iPad
    webApp: userAgent.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
  };
  var isMobile =
    platform.mobile || platform.android || platform.iPhone || platform.iPad;

  document.body.classList.add(isMobile ? "is-mobile" : "is-pc");
  var inIframe = window.top !== window.self;
  document.body.classList.add(inIframe ? "in-iframe" : "in-main");
  return null;
};

/**
 * 团体预定选择座位数
 *
 * @returns
 */
export const GroupBookSeat = ({ onChange, value }) => {
  const { t } = useTranslation();
  function onValueChange(val) {
    let parseVal = parseInt(val, 10);
    if (Number.isNaN(parseVal)) {
      parseVal = 1;
    }
    onChange(Math.max(parseVal, 1));
  }
  return (
    <ListItem
      className="group-book-seat"
      title={<Icon text={t("GROUP_NUMBER")} icon="icon icon-seat"></Icon>}
      value={
        <div className="number-input">
          <MinusOutlined onClick={() => onValueChange(value - 1)} />
          <input
            value={value || "0"}
            onChange={(ev) => onChange(parseInt(ev.target.value, 10))}
          />
          <PlusOutlined onClick={() => onValueChange(value + 1)} />
        </div>
      }
    ></ListItem>
  );
};

/**
 * 笔记
 * @param {*} props
 * @returns
 */
export const NoteList = (props) => {
  const [notes, setNotes] = useState([]);
  const [params, setParams] = useState({});
  const throttled = useRef(throttle((newValue) => getNotes(newValue), 600));
  function getNotes(params) {
    if (Object.keys(params).length < 1) return;
    restaurantService.getNotes(params).then((data) => {
      setNotes(data || []);
    });
  }
  useEffect(() => {
    let {
      date,
      time,
      restaurant_id,
      discount,
      deal_id,
      group_booking,
      off_peak_id,
      project,
      seats,
    } = props.values;
    setParams({
      date,
      time,
      restaurant_id,
      discount,
      deal_id,
      group_booking,
      off_peak_id,
      project: project || "diningcity",
      seats,
    });
  }, [props.values]);
  useEffect(() => {
    throttled.current(params);
  }, [params]);
  return (
    <React.Fragment>
      {notes.map((v, index) => {
        return (
          <div className="note-item" key={index}>
            <h4 className="note-item__title">{v.title}</h4>
            <div className="note-item__desc">{v.content}</div>
          </div>
        );
      })}
    </React.Fragment>
  );
};
