import React, { useState, useEffect, useRef, useMemo } from "react";
import { useObserver } from "mobx-react-lite";
import schema from "async-validator";
import { Form, Input, Row, Col, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import * as Common from "@/components/common";
import DcVoucher, { VoucherPopover } from "@/components/dc-voucher";
import Voucher from "@/components/voucher";
import { getQuery, urlBuilder } from "@/service/http";
import reservationService from "@/service/reservation";
import "./index.less";
import qs from "qs";
import { SyncOutlined } from "@ant-design/icons";
import {
  PaymentSelector,
  PaymentPopup,
  PaymentAction,
} from "@/components/dc-payment";
import KidsPolicy from "@/components/dc-kids-policy";
import { client, animate } from "@/utils";
import { CardsPopup } from "../components/master-card";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js/pure";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

/**
 * 已经登录的用户预订(会员)
 *
 * @param {*} props
 */
export default (props) => {
  const {
    memberStore,
    restaurantStore,
    paymentStore,
    voucherStore,
  } = useStores();
  const { action } = useParams();
  const { benefits } = memberStore;
  const { restaurant } = restaurantStore;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const voucherRef = useRef();
  const { can_edit_member_info } = getQuery();
  const selected_benefit_ids = restaurantStore.form.loyalty_program_benefit_ids.split(
    ","
  );
  const selected_benefit_map = {};

  selected_benefit_ids.forEach((i) => {
    selected_benefit_map[i] = true;
  });
  benefits.forEach((v) => {
    if (!v.is_optional) {
      selected_benefit_map[v.id] = true;
    }
  });
  let formdata = Object.assign(
    {},
    {
      ...restaurantStore.form,
      acceptTerms: true,
      selected_benefit_ids: selected_benefit_ids,
    }
  );
  let [values, setValues] = useState(formdata);
  let [isVaild, setVaild] = useState(true);
  let [termsVisible, setTermsVisible] = useState(false);
  let [benefitsVisible, setBenefitsVisible] = useState(false);
  let [paymentVisible, setPaymentVisible] = useState(false);
  let [cardVisible, setCardVisible] = useState(false);
  let [voucherVisible, setVoucherVisible] = useState(false);
  // Stripe 支付弹窗
  let [choosenBenefits, setBenefits] = useState(selected_benefit_map);
  let [errors, setErrors] = useState({});
  let [loading, setLoading] = useState("");
  let [submitLoading, setSubmitLoading] = useState(false);
  // 支付方式
  let [payment, setPayment] = useState("");
  // bank card uu id
  const [bankCardId, setBankCardId] = useState("");
  let [mastercardNumber, setMastercardNumber] = useState("");
  let [result, setResult] = useState(null);
  let [visible, setVisible] = useState(false);
  let [forOther, setForOther] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  let [otherFormValues, setOtherFormValues] = useState({});
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [amountType, setAmountType] = useState("");
  const [formatAmount, setFormatAmount] = useState("");
  const [formatOriginAmount, setFormatOriginAmount] = useState("");
  let [voucherRule, setVoucherRule] = useState({});
  let [choosenVoucherAble, setChoosenVoucherAble] = useState(true);
  const [errtip, showErrorTip] = useState(false);
  const [termTip, showTermTip] = useState(false);
  const [needUuid, setNeedUuid] = useState(false);
  let [paymentLoadingVisible, setPaymentLoadingVisible] = useState(false);
  let [inviteCode, setInviteCode] = useState({});
  const stripeRef = useRef(null);
  let term = restaurantStore.alertTerms || [];
  let isSubmit = false;

  const uuid = useMemo(() => {
    return mastercardNumber ? bankCardId : "";
  }, [payment, mastercardNumber]);

  const voucherComponent = useMemo(() => {
    if (!restaurantStore.projectInfo) return "";
    if (!restaurantStore.projectInfo.is_need_voucher_required) return "";
    // 已登录的，选择
    if (memberStore.member.id) return "SELECT";
    // 未登录的，录入
    return "INPUT";
  }, [memberStore.member, restaurantStore.projectInfo]);

  /** 是否存在Stripe支付 */
  const hasStripe = useMemo(() => {
    return (
      paymentStore.paymentMethods.findIndex((v) => v.provider === "stripe") > -1
    );
  }, [paymentStore.paymentMethods]);
  /** 是否需要选择卡片, stripe 支付，登录必须选择卡片 */
  const requiredCard = useMemo(() => {
    return hasStripe && (!values.group_booking && amount ? true : false);
  }, [hasStripe, values, amount]);
  const requireVoucher = useMemo(() => {
    let { required, points_range = [] } = voucherRule;
    return required && points_range[0] > 0 && voucherComponent;
  }, [voucherRule, voucherComponent]);

  useEffect(() => {
    paymentStore.getMethods().then((data) => {
      const methods = data.map((v) => v.provider);
      if (methods.includes("mpgs")) {
        // 当存在mpgs支付方式时，需要先选择卡片，再选择券码
        setNeedUuid(true);
        return;
      } else if (methods.includes("stripe")) {
        // 当存在stripe支付时，也需要先选中卡片
        const item = data.find((v) => v.provider === "stripe");
        setPayment(item?.keyword);
        setNeedUuid(true);
        // 获取stripe 支付key, 取餐厅region_id
        paymentStore.getStripeKey(restaurant.region?.id).then((data) => {
          loadStripe(data.public_key, {
            betas: [
              "elements_enable_deferred_intent_beta_1",
              "blocked_card_brands_beta_1",
            ],
          }).then((stripe) => {
            stripeRef.current = stripe;
          });
        });

        // 获取银行卡信息
        paymentStore.getBankCardInfos();
        return;
      } else if (methods.includes("amex")) {
        // 当存在stripe支付时，也需要先选中卡片
        const item = data.find((v) => v.provider === "amex");
        setPayment(item?.keyword);
      }
      // 在微信打开，自动选择支付方式
      if (client === "MP" || client === "WECHAT") {
        if (methods.includes("wx")) {
          setPayment("wx");
        }
      }
    });
    // 编辑模式下不获取优惠券信息
    if (action !== "edit") {
      // 获取优惠劵信息
      restaurantStore.getVouchers().then((data) => {
        setVoucherRule(data);
        getVocherList();
      });
    }
  }, []);

  // 自动添加url上的优惠券
  useEffect(async () => {
    let entryCode = values.voucher_codes[0];
    if (!entryCode || !voucherComponent || window.entriedVoucher) return;
    if (voucherComponent === "SELECT") {
      try {
        let vouchers = await getVocherList();
        if (vouchers.find((item) => item.code === entryCode)) {
          return console.warn("已经添加过的券码", entryCode);
        }
        const { data } = await reservationService.entryVoucher(entryCode);
        if (data === "ok") {
          getVocherList();
        } else {
          onChange({ voucher_codes: [] });
        }
      } catch (error) {
        message.error(error.message);
        onChange({ voucher_codes: [] });
      }
      window.entriedVoucher = true;
    }
  }, [voucherComponent]);

  /**
   * 获取优惠劵
   * @param {*} query
   */
  function getVocherList(query = {}) {
    /** 获取优惠劵 */
    return voucherStore.getList(query).then((data) => {
      setVouchers(data);
      return data;
    });
  }

  // 离开页面，自动关闭弹窗
  useEffect(() => {
    return () => {
      showErrorTip(false);
      animate("POP", () => {
        setVoucherVisible(false);
        setBenefitsVisible(false);
        setPaymentVisible(false);
      });
    };
  }, []);

  const voucherCodes = useMemo(() => {
    return values.voucher_codes.join();
  }, [values.voucher_codes]);

  // 获取支付信息
  useEffect(() => {
    restaurantStore
      .getPaymentInfo({
        ...restaurantStore.form,
        vouchers: voucherCodes,
        uuid,
      })
      .then((data) => {
        setFormatAmount(data.format_amount || "");
        setFormatOriginAmount(data.format_origin_amount || "");
        setAmount(data.amount || 0);
        setCurrency(data.currency_code);
        setAmountType(data.amount_type);
      });
  }, [voucherCodes, payment]);

  // 为他人预定
  function onOthersChange(data, isVaild) {
    setVaild(isVaild);
    setOtherFormValues(data);
  }

  async function onChange(data) {
    const formValues = Object.assign({}, values, form.getFieldsValue(), data);
    setValues(formValues);
  }

  function userInfo(checked_terms_conditions) {
    // 获取优惠券组件数据
    let voucher_codes = values.voucher_codes || [];

    // 获取邀请码数据
    let { invite_code } = inviteCode;

    let {
      first_name,
      last_name,
      email,
      mobile,
      country_code,
      region_id,
    } = memberStore.member;

    let user = {
      first_name,
      last_name,
      email,
      mobile,
      country_code,
    };

    let other = forOther ? { ...otherFormValues } : {};
    let defaultBenefits = memberStore.benefits
      .filter((i) => !i.is_optional)
      .map((i) => i.id);
    let loyalty_program_benefit_ids = defaultBenefits
      .concat(values.selected_benefit_ids)
      .join(",");

    return Object.assign({}, values, user, other, {
      voucher_codes,
      invite_code,
      checked_terms_conditions,
      loyalty_program_benefit_ids,
      selected_benefit_ids: null,
      uuid,
    });
  }
  function getSuccessPage({ channel }) {
    const domain = document.location.href.split("#")[0];
    const reservation_code =
      restaurantStore.bookingResult.book.reservation_code;
    const reservation_id = restaurantStore.bookingResult.book.id;
    const base =
      domain +
      `#/restaurants/${restaurantStore.restaurant.id}/reservation/complete?`;
    const query = qs.stringify({
      ...getQuery(),
      reservation_code: reservation_code,
      reservation_id: reservation_id,
      channel,
    });
    return base + query;
  }
  /**当万事达卡支付完毕 */
  function onMasterCardPayComplete({ channel = "mpgs" }) {
    setPaymentLoadingVisible(false);
    setSubmitLoading(false);
    // open message component when hasStripe is true
    if (hasStripe) {
      message.success(t("paymentSuccess"));
    }
    const domain = document.location.href.split("#")[0];
    const reservation_code =
      restaurantStore.bookingResult.book.reservation_code;
    const reservation_id = restaurantStore.bookingResult.book.id;
    const base =
      domain +
      `#/restaurants/${restaurantStore.restaurant.id}/reservation/complete?`;
    const query = qs.stringify({
      ...getQuery(),
      reservation_code: reservation_code,
      reservation_id: reservation_id,
      channel,
    });
    window.location.href = base + query;
  }

  /**当成功动画播放结束 */
  async function onAnimateComplete(bookResult) {
    const bookingResult = bookResult || result;
    const reservation_code = bookingResult.book.reservation_code;
    const reservation_id = bookingResult.book.id;
    setVisible(false);
    isSubmit = false;
    restaurantStore.bookingResult = bookingResult;
    restaurantStore.bookingInfo =
      bookingResult.book || restaurantStore.bookResultAdapter(userInfo());
    if (bookingResult.book) {
      await restaurantStore.getBooking(reservation_code, reservation_id);
    }
    function getPayPage() {
      const domain = document.location.href.split("#")[0];
      const base =
        domain + `#/restaurants/${restaurantStore.restaurant.id}/reservation`;
      let callback = urlBuilder(reservation_code, reservation_id);
      console.warn("success_url", callback);
      const query = qs.stringify({
        reservation_code: reservation_code,
        reservation_id: reservation_id,
        channel: payment,
        bankCardId,
        cardNumber: mastercardNumber,
        project: getQuery().project,
        program: getQuery().program,
        deal_id: getQuery().deal_id,
        private_token: getQuery().private_token,
        success_url: callback,
        lang: getQuery().lang,
        access_code: getQuery().access_code,
      });
      return `${base}/pay?${query}`;
    }
    /** 支付失败 */
    const handlePayFail = ({ autoPay = true, isTop = true } = {}) => {
      const payPage = getPayPage();
      localStorage.setItem("AUTO_PAY", String(autoPay));
      if(isTop) {
        window.top.location.href = payPage;
      } else {
        window.location.href = payPage;
      }
    };
    // 如果是预支付场景，还需要唤起支付，然后回调到预定完成页面
    // 支付的回调页面，是iframe-top的页面
    if (bookingResult.book && amount) {
      // 如果需要支付，重定向页面到支付页面
      const hasAmexCheckstand = !!paymentStore.paymentMethods.find(
        (v) => v.provider === "amex"
      );
      const hasMpgs =
        paymentStore.paymentMethods.findIndex((v) => v.keyword === "mpgs") > -1;
      if (payment === "amexweb") {
        paymentStore
          .prePayment({
            ...restaurantStore.reservation,
            bank_card_uuid: null,
            payment_method: payment,
            success_url: getSuccessPage({ channel: payment }),
          })
          .then((data) => {
            window.location.replace(data.payUrl);
          })
          .catch((err) => {
            setSubmitLoading(false);
            message.error(err.message);
          });
      } else if (hasMpgs) {
        paymentStore
          .prePayment({
            ...restaurantStore.reservation,
            bank_card_uuid: bankCardId,
            payment_method: "mpgs",
          })
          .then(() => {
            setPaymentLoadingVisible(true);
          })
          .catch((error) => {
            setPaymentLoadingVisible(true);
            console.error("支付失败", error);
          });
      } else if (hasStripe) {
        // 如果已经选择了卡片
        if (bankCardId) {
          // Stripe 支付
          paymentStore
            .prePayment({
              ...restaurantStore.reservation,
              bank_card_uuid: bankCardId,
              payment_method: payment,
            })
            .then(async (res) => {
              // 如果存在card 直接调用支付
              // 前端验证是否支付成功
              const { client_secret, payment_method } = res;
              const {
                paymentIntent,
                error,
              } = await stripeRef.current.confirmCardPayment(client_secret, {
                payment_method,
              });
              if (error) {
                throw error;
              }
              if (
                paymentIntent.status === "succeeded" ||
                paymentIntent.status === "requires_capture"
              ) {
                console.info("支付成功");
                let count = 0;
                let timer = null;
                // 查询订单状态，等待支付成功
                const helper = async () => {
                  // 最大重试十次
                  if (count > 10) {
                    clearTimeout(timer);
                    return onMasterCardPayComplete({ channel: payment });
                  }
                  const res = await restaurantStore.getBooking(
                    reservation_code,
                    reservation_id
                  );
                  if (res.status === "need_pay") {
                    count++;
                    timer = setTimeout(helper, 400);
                  } else {
                    return onMasterCardPayComplete({ channel: payment });
                  }
                };
                helper();
              } else {
                throw new Error(paymentIntent);
              }
            })
            .catch((err) => {
              setSubmitLoading(false);
              setPaymentLoadingVisible(false);
              message.error(err.message);
              handlePayFail({ autoPay: false, isTop: false });
              console.error("支付失败", err);
            });
        } else {
          // setStripePayVisible(true)
          console.warn("stripe 支付, 但是没有选择卡片");
        }
        return;
      } else if (hasAmexCheckstand) {
        paymentStore
          .prePayment({
            ...restaurantStore.reservation,
            bank_card_uuid: null,
            payment_method: payment,
            success_url: paymentStore.getAmexPayUrl(bookingResult.book),
          })
          .then((data) => {
            // 代订模式直接去待支付页面
            if (can_edit_member_info === "true") {
              handlePayFail({ autoPay: false, isTop: false });
            } else {
              paymentStore.goAmexPay(bookingResult.book, () => {
                handlePayFail({ autoPay: false, isTop: false });
              });
            }
          });
      } else {
        handlePayFail();
      }
    } else {
      // 不需要支付，重定向到支付完成页面
      setSubmitLoading(false);
      restaurantStore.step = restaurantStore.STEPS.COMPLETE;
    }
  }

  async function onConfirm(checked_terms_conditions = []) {
    if (isSubmit) return;
    isSubmit = true;
    try {
      setLoading("loading");
      if (forOther) {
        console.log("为他人预定，传入对方的姓名和电话号码。");
      }
      console.log("开始创建预定");
      let bookResult = await restaurantStore.create(
        userInfo(checked_terms_conditions)
      );
      // 如果需要需要支付，不出现动画
      if (bookResult) {
        setResult(bookResult);
        if (bookResult.book && amount) {
          onAnimateComplete(bookResult);
        } else {
          setLoading("success");
          setSubmitLoading(false);
          restaurantStore.navigateToMP(bookResult.book);
        }
      } else {
        setLoading("failed");
        setTimeout(() => {
          setLoading("");
          setVisible(false);
          isSubmit = false;
          setSubmitLoading(false);
        }, 3300);
      }
    } catch (error) {
      message.error(error.message, 5);
      setLoading("failed");
      setTimeout(() => {
        setLoading("");
        setVisible(false);
        isSubmit = false;
        setSubmitLoading(false);
      }, 3000);
    }
  }

  const onSubmit = async function () {
    let isGroupBooking = values.group_booking;
    setSubmitLoading(true);
    window.parent.postMessage({
      type: 'btn_click',
      data: {
        text: 'confirm',
      }
    }, '*')
    try {
      setErrors({});
      // 获取邀请码数据
      // if (inviteCode.required && !inviteCode.enable) {
      //   message.error(t('INCORRECT_INVITECODE'))
      //   setSubmitLoading(false)
      //   return console.error('必须填写邀请码')
      // }

      // // 如果要求必须选择优惠券
      // if (voucherComponent && requireVoucher) {
      //   if (!values.voucher_codes.length) {
      //     message.error(t("VOUCHERS.ENTER"));
      //     setSubmitLoading(false);
      //     return console.error("必须填写优惠券");
      //   }
      // }
      const validator = new schema({
        mobile: [{ required: true }],
        email: [{ required: forOther ? false : true, type: "email" }],
        first_name: [{ required: true }],
        last_name: [{ required: true }],
        seats: [
          isGroupBooking
            ? { required: true, type: "number", max: 9999, min: 8 }
            : { required: true },
        ],
      });
      validator
        .validate({
          ...values,
          ...userInfo(),
          seats: +values.seats,
        })
        .then(() => {
          // 选择支付方式
          const showPayError = amount && !payment && !isGroupBooking;
          const showTermsError = !values.acceptTerms;
          if (showPayError) {
            showErrorTip(true);
            setTimeout(() => {
              showErrorTip(false);
            }, 500);
          }

          // 勾选用户协议
          if (showTermsError) {
            showTermTip(true);
            setTimeout(() => {
              showTermTip(false);
            }, 500);
          }

          if (showPayError || showTermsError) {
            setSubmitLoading(false);
            return;
          }
          setVisible(true);
          // term 如果该餐厅有附加条款，需要展示给用户
          if (!term.length) {
            onConfirm();
          }
        })
        .catch(({ fields }) => {
          setSubmitLoading(false);
          setErrors(fields);
        });
    } catch (error) {
      console.error(error);
      setSubmitLoading(false);
    }
  };

  function onBenefitsPopupBack(data) {
    let benefits = Object.keys(data).filter((i) => data[i]);
    setBenefitsVisible(false);
    setBenefits(data);
    onChange({ selected_benefit_ids: benefits });
  }

  function onPaymentPopupBack(data) {
    setPaymentVisible(false);
    setPayment(data);
  }

  function onCardsPopupBack(data) {
    // 选择的卡片
    setCardVisible(false);
    // 清空选择的优惠券
    onVoucherPopupBack([]);
    if (data) {
      setMastercardNumber(data.short_number);
      // setPayment(data.uuid);
      setBankCardId(data.uuid);
      restaurantStore.bankNumber = data.short_number;
    }
    if (voucherComponent === "SELECT") {
      // 重新获取获取优惠劵
      getVocherList({ cardNumber: data?.uuid });
    }
  }
  /** remove card */
  function onRemoveCard(uuid) {
    /** 删除已选择卡片 */
    if (uuid === bankCardId) {
      setMastercardNumber("");
      setBankCardId("");
      restaurantStore.bankNumber = "";
      // 清空选择的优惠券
      onVoucherPopupBack([]);
    }
  }

  function onVoucherPopupBack(data) {
    setValues(
      Object.assign({}, values, {
        voucher_codes: data,
      })
    );
    setVoucherVisible(false);
  }

  // 查看单个优惠券信息
  function onVoucherView(data) {
    data.hidden = true;
    setVouchers([data]);
    setChoosenVoucherAble(false);
    animate("PUSH", () => setVoucherVisible(true));
  }

  return useObserver(() => {
    // 团队预订状态
    let isGroupBooking = values.group_booking;
    let { currency: suffix, paymentInfo } = restaurantStore;
    const shouldPay = !isGroupBooking && amount;
    let disabled =
      !values.acceptTerms ||
      !isVaild ||
      (shouldPay ? !payment : false) ||
      (inviteCode.required && !inviteCode.enable) ||
      (requireVoucher && !values.voucher_codes.length) ||
      (requiredCard && !bankCardId);
    const disableSelectVoucher = needUuid && amount ? !mastercardNumber : false;
    // 默认礼遇
    const { benefits = [] } = memberStore;
    // 去重
    const ids = [
      ...new Set([
        ...values.selected_benefit_ids.map(String),
        ...benefits.filter((v) => !v.is_optional).map((v) => String(v.id)),
      ]),
    ];
    let benefitsSize = ids.filter(Boolean).length || "";
    let canBookForOthers = can_edit_member_info === "true" && !isGroupBooking;

    return (
      <React.Fragment>
        {/* 等待万事达卡支付 */}
        {paymentLoadingVisible ? (
          <Common.PaymentModal
            visible={paymentLoadingVisible}
            onComplete={onMasterCardPayComplete}
          ></Common.PaymentModal>
        ) : null}

        {/* 用户协议 */}
        {termsVisible ? (
          <Common.TermsPopup
            visible={termsVisible}
            onBack={() => animate("POP", () => setTermsVisible(false))}
          ></Common.TermsPopup>
        ) : null}

        {/* 用户福利 */}
        {benefitsVisible ? (
          <Common.BenefitsPopup
            visible={benefitsVisible}
            data={choosenBenefits}
            onBack={(data) => animate("POP", () => onBenefitsPopupBack(data))}
          ></Common.BenefitsPopup>
        ) : null}

        {/* 支付方式 */}
        {paymentVisible ? (
          <PaymentPopup
            visible={paymentVisible}
            data={payment}
            onBack={(data) => animate("POP", () => onPaymentPopupBack(data))}
          ></PaymentPopup>
        ) : null}

        {/* 万事达卡 & Stripe卡支付 */}
        {cardVisible ? (
          <CardsPopup
            visible={cardVisible}
            paymentMethods={paymentStore.paymentMethods}
            channel={payment}
            onBack={(data) => animate("POP", () => onCardsPopupBack(data))}
            onRemove={onRemoveCard}
          ></CardsPopup>
        ) : null}
        {/* 优惠券 */}
        {voucherVisible ? (
          <VoucherPopover
            visible={voucherVisible}
            rule={voucherRule}
            vouchers={vouchers}
            uuid={uuid}
            codes={values.voucher_codes}
            showCardVoucher={voucherComponent === "SELECT"}
            showFooter={choosenVoucherAble}
            getVocherList={() =>
              getVocherList({
                cardNumber: mastercardNumber ? bankCardId : undefined,
              })
            }
            onBack={(data) => animate("POP", () => onVoucherPopupBack(data))}
            isMember={true}
          ></VoucherPopover>
        ) : null}

        {/* 用户信息, 有支付*/}
        <div className="step-user-form page-animate-main">
          <section className="book-page-info">
            {/* 用户及商店信息 */}
            <Common.UserProfile
              forOther={forOther}
              setForOther={setForOther}
              canBookForOthers={canBookForOthers}
            ></Common.UserProfile>

            {/* 为他人预定 */}
            {forOther ? (
              <ForOthers
                onChange={onOthersChange}
                setForOther={setForOther}
              ></ForOthers>
            ) : null}

            {/* 预定信息，优惠信息 */}
            <UserBookingInfo
              disabled={disabled}
              payment={payment}
              mastercardNumber={mastercardNumber}
              amount={amount}
              suffix={suffix}
              isGroupBooking={isGroupBooking}
              childrenSeats={values.children_seats}
              adultSeats={values.seats - values.children_seats}
              action={action}
              setBenefitsVisible={() =>
                animate("PUSH", () => setBenefitsVisible(true))
              }
              setPaymentVisible={() => {
                animate("PUSH", () => setPaymentVisible(true));
              }}
              setCardVisible={() => {
                animate("PUSH", () => setCardVisible(true));
              }}
              onSubmit={onSubmit}
              errtip={errtip}
              benefitsSize={benefitsSize}
            />
            {/* event */}
            {/* <Event disabled={disabled}></Event> */}
          </section>
          <PrePayItem
            disabled={disabled}
            amount={amount}
            VoucherComponent={
              /** 优惠劵 */
              voucherComponent === "SELECT" && action !== "edit" ? (
                <DcVoucher
                  disabled={disableSelectVoucher}
                  big={true}
                  showCardVoucher={voucherComponent === "SELECT"}
                  vouchers={vouchers}
                  codes={values.voucher_codes}
                  onClick={() => {
                    animate("PUSH", () => setVoucherVisible(true));
                  }}
                />
              ) : null
            }
            suffix={suffix}
            payment={payment}
            mastercardNumber={mastercardNumber}
            setPaymentVisible={() => {
              animate("PUSH", () => setPaymentVisible(true));
            }}
            setCardVisible={() => {
              animate("PUSH", () => setCardVisible(true));
            }}
            onSubmit={onSubmit}
            isGroupBooking={isGroupBooking}
          />

          {/* 邀请码 */}
          <InviteCode
            onChange={setInviteCode}
            seats={values.seats}
          ></InviteCode>

          {/* 需要补充的资料 */}
          <Form
            size="large"
            form={form}
            className="book-page-form"
            hideRequiredMark
            scrollToFirstError
            layout="vertical"
            initialValues={formdata}
            onValuesChange={onChange}
          >
            {isGroupBooking ? (
              <Form.Item
                label={""}
                name="seats"
                help={
                  errors.seats ? (
                    <Common.Help>{t("ERROR.GROUP_NUMBER")}</Common.Help>
                  ) : null
                }
              >
                <Common.GroupBookSeat />
              </Form.Item>
            ) : null}
            <Common.NoteList values={values} />
            <KidsPolicy hidden={values.children_seats < 1} />
            {isGroupBooking ? (
              <div className="user-form-group">
                <Form.Item
                  label={t("GROUP_REMARK")}
                  name="remark"
                  style={{ marginBottom: "16px" }}
                >
                  <Input placeholder={t("GROUP_REMARK_PH")} />
                </Form.Item>
              </div>
            ) : (
              <React.Fragment>
                {/* <div className="book-page-form__label">{t("Requests")}</div> */}
                <Form.Item
                  name="comment"
                  className="requests"
                  style={{ marginBottom: "16px" }}
                  label={t("Requests")}
                >
                  <Input.TextArea
                    size="small"
                    bordered={false}
                    placeholder={t("SPECIAL_REQUESTS")}
                    autoSize
                  />
                </Form.Item>
              </React.Fragment>
            )}
            {/* 优惠券 */}
            {voucherComponent === "INPUT" ? (
              <Voucher
                disabled={action === "edit"}
                mobile={memberStore.member.mobile}
                required={requireVoucher}
                range={voucherRule.points_range}
                onView={onVoucherView}
                onChange={onVoucherPopupBack}
              />
            ) : null}

            <Common.Terms
              errtip={termTip}
              values={values}
              btnText={
                !shouldPay
                  ? values.id
                    ? t("EDIT_RESERVATION")
                    : isGroupBooking
                    ? t("SEND_GROUP_BOOKING")
                    : t("CONFIRM")
                  : t("PAYMENT.Pay & book")
              }
              isGroupBooking={isGroupBooking}
              checked={values.acceptTerms}
              onChange={(val) => onChange({ acceptTerms: true })}
              onShow={() => animate("PUSH", () => setTermsVisible(true))}
            ></Common.Terms>

            <div className="footer-place" hidden={shouldPay}></div>

            <div
              hidden={shouldPay}
              style={{
                paddingTop: 14,
                textAlign: "right",
                position: "fixed",
                left: "50%",
                bottom: "15px",
                transform: "translateX(-50%)",
              }}
            >
              <Common.Button
                type="primary"
                onClick={onSubmit}
                disabled={disabled}
                block={false}
                loading={submitLoading}
              >
                {values.id
                  ? t("EDIT_RESERVATION")
                  : isGroupBooking
                  ? t("SEND_GROUP_BOOKING")
                  : t("CONFIRM")}
              </Common.Button>
            </div>

            <Common.LoadingModal
              visible={visible}
              loading={loading}
              setVisible={setVisible}
              setSubmitLoading={setSubmitLoading}
              onSubmit={onConfirm}
              onComplete={onAnimateComplete}
              terms={term}
            ></Common.LoadingModal>
          </Form>
        </div>
        {shouldPay ? (
          <React.Fragment>
            <div style={{ height: 69 }}></div>
            <PaymentAction
              hidden={voucherVisible}
              left={
                paymentInfo.amount_type === "deposit"
                  ? t("PAYMENT.Deposit") + ":"
                  : ""
              }
              formatAmount={formatAmount}
              formatOriginAmount={formatOriginAmount}
              disabled={disabled}
              onSubmit={onSubmit}
              loading={submitLoading}
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  });
};

// 为他人预定的表单
const ForOthers = (props) => {
  const { t } = useTranslation();
  const { restaurantStore, memberStore } = useStores();
  const [form] = Form.useForm();
  const { phone_code } = restaurantStore.restaurant.region;
  let formdata = {
    first_name: "",
    last_name: "",
    mobile: "",
    country_code: phone_code ? +phone_code : 86,
  };
  let [values, setValues] = useState(formdata);
  let [errors, setErrors] = useState({});

  const validator = new schema({
    first_name: [{ required: true }],
    last_name: [{ required: true }],
    mobile: [{ required: true }],
  });

  useEffect(() => {
    // 从运通获取用户信息
    memberStore.getGuestInfo().then((data) => {
      if (!data.member) return;
      form.setFields(
        Object.entries(data.member || {}).map(([key, value]) => {
          return { name: key, value };
        })
      );
      if (Object.values(data.member || {}).length > 0) {
        onChange({}, data.member || {});
      }
    });
  }, []);

  useEffect(() => {
    props.onChange(values, false);
    return () => {
      props.onChange(values, true);
    };
  }, []);

  async function onChange(data, fields) {
    const formValues = Object.assign({}, values, fields);
    let isVaild = false;
    setValues(formValues);
    setErrors({});
    try {
      await validator.validate(formValues);
      isVaild = true;
    } catch (error) {
      setErrors(error.fields);
    }
    props.onChange(formValues, isVaild);
  }

  return useObserver(() => {
    return (
      <Form
        size="large"
        form={form}
        hideRequiredMark
        scrollToFirstError
        layout="vertical"
        initialValues={formdata}
        onValuesChange={onChange}
      >
        <header>
          <i
            className="icon icon-arrow-left"
            onClick={() => props.setForOther(false)}
          ></i>
        </header>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label={t("FIRST_NAME")}
              name="first_name"
              help={
                errors.first_name ? (
                  <Common.Help>{t("ERROR.FIRST_NAME")}</Common.Help>
                ) : null
              }
            >
              <Input placeholder={t("FIRST_NAME")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("LAST_NAME")}
              name="last_name"
              help={
                errors.last_name ? (
                  <Common.Help>{t("ERROR.LAST_NAME")}</Common.Help>
                ) : null
              }
            >
              <Input placeholder={t("LAST_NAME")} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("MOBILE")}
          help={
            errors.mobile ? (
              <Common.Help>{t("ERROR.MOBILE")}</Common.Help>
            ) : null
          }
        >
          <Input.Group compact>
            <Form.Item name="country_code" noStyle>
              <Select
                style={{ width: "80px" }}
                optionLabelProp="name"
                dropdownClassName="country-code-select"
              >
                {restaurantStore.countries.map((item) => {
                  return (
                    <Select.Option
                      value={item.country_code}
                      key={item.name}
                      name={item.country_code_format}
                    >
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="mobile" noStyle>
              <Input
                autoComplete="new-password"
                placeholder={t("MOBILE")}
                style={{ width: "calc(100% - 80px)" }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  });
};

/** Event信息 */
const Event = (props) => {
  const { restaurantStore } = useStores();
  return useObserver(() => {
    const { projectInfo, enablePrePay } = restaurantStore;
    if (!projectInfo) return null;
    if (enablePrePay) return null;
    if (projectInfo.project_type !== "event") {
      return null;
    }
    return (
      <Common.ListItem
        title={
          <Common.Icon
            icon="icon icon-event"
            text={
              <span
                className="ellipsis"
                style={{
                  fontSize: 15,
                  lineHeight: "24px",
                  width: "calc(100vw - 70px)",
                }}
              >
                {projectInfo.title}
              </span>
            }
          />
        }
      ></Common.ListItem>
    );
  });
};

/** 用户预支付 */
const PrePayItem = (props) => {
  const { restaurantStore } = useStores();
  const { deals, enablePrePay, paymentInfo } = restaurantStore;
  return (
    <PrePay
      isGroupBooking={props.isGroupBooking}
      VoucherComponent={props.VoucherComponent}
      enablePrePay={enablePrePay}
      deals={deals}
      paymentInfo={paymentInfo}
      disabled={props.disabled}
      payment={props.payment}
      mastercardNumber={props.mastercardNumber}
      setPaymentVisible={props.setPaymentVisible}
      setCardVisible={props.setCardVisible}
      onSubmit={props.onSubmit}
      amount={props.amount}
      suffix={props.suffix}
      errtip={props.errtip}
    />
  );
};

/** 用户预订信息*/
const UserBookingInfo = (props) => {
  const { memberStore, restaurantStore } = useStores();
  const { t } = useTranslation();
  const setBenefitsVisible = () => {
    props.setBenefitsVisible(true);
  };
  return useObserver(() => {
    const { benefits } = memberStore;
    const { form, deals, enablePrePay, projectInfo } = restaurantStore;
    // 团体预订和套餐预订不展示Benefits
    const showBenefits = !props.isGroupBooking && benefits.length;
    let isALaCarte = projectInfo
      ? projectInfo.project_type !== "program" &&
        projectInfo.project_type !== "event"
      : true;
    isALaCarte = deals ? false : isALaCarte;
    return (
      <React.Fragment>
        <Common.Bookinfo></Common.Bookinfo>
        {/* 有儿童 */}
        {props.childrenSeats > 0 && (
          <Common.ListItem
            title={
              <span style={{ marginLeft: 30, fontWeight: 400 }}>
                {t("Children seats", {
                  adult: props.adultSeats,
                  children: props.childrenSeats,
                })}
              </span>
            }
          ></Common.ListItem>
        )}
        {/* 点菜单 */}
        {isALaCarte && (
          <Common.ListItem
            title={
              <Common.Icon
                text={
                  <span style={{ fontSize: 15, color: "#353535" }}>
                    {t("A la carte")}
                  </span>
                }
                icon="icon icon-carte"
              ></Common.Icon>
            }
          ></Common.ListItem>
        )}
        {/* 折扣 */}
        {form.discount ? (
          <Common.ListItem
            value={
              <span
                style={{ fontSize: 16, color: "#dc2224", fontWeight: "bold" }}
              >
                {form.discount}
              </span>
            }
            title={
              <Common.Icon
                text={
                  <span style={{ fontSize: 15, color: "#353535" }}>
                    {t("RESERVATION_INFO.BS")}
                  </span>
                }
                icon="icon icon-save"
              ></Common.Icon>
            }
          ></Common.ListItem>
        ) : null}

        {/* 优惠 */}
        {deals && !enablePrePay ? (
          <Common.ListItem
            title={
              <Common.Icon
                text={
                  <span
                    className="ellipsis"
                    style={{
                      fontSize: 15,
                      lineHeight: "24px",
                      width: "calc(100vw - 70px)",
                    }}
                  >
                    {deals.name}
                  </span>
                }
                icon="icon icon-deal"
              ></Common.Icon>
            }
          ></Common.ListItem>
        ) : null}

        {enablePrePay && (
          <Common.ListItem
            title={
              <Common.Icon
                text={
                  <React.Fragment>
                    <span
                      style={{
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        marginRight: 3,
                      }}
                    >
                      {deals.name}
                    </span>
                  </React.Fragment>
                }
                icon="icon icon-deal"
              ></Common.Icon>
            }
          ></Common.ListItem>
        )}
        {/* 活动 */}
        <Event disabled={props.disabled} />
        {/* 礼遇 */}
        {showBenefits ? (
          <Common.ListItem
            className="benefits"
            onClick={setBenefitsVisible}
            title={
              <Common.Icon
                icon="icon icon-vip"
                text={
                  <strong style={{ fontSize: 15, lineHeight: "24px" }}>
                    {t("Benefits")}
                  </strong>
                }
              />
            }
            desc={t("BOOK_WINDOW_DESC")}
            value={
              <React.Fragment>
                <span
                  style={{
                    color: "#dc2224",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {props.benefitsSize}
                </span>
                <i className="icon icon-arrow-right"></i>
              </React.Fragment>
            }
          ></Common.ListItem>
        ) : null}
      </React.Fragment>
    );
  });
};

/**
 * 邀请码
 *
 * 邀请码为空：使用个人码
 * 邀请码错误：不通过
 * 邀请码正确：使用邀请码
 *
 * @param {*} param0
 * @param {*} ref
 * @returns
 */
let InviteCode = ({ onChange, seats }, ref) => {
  const { memberStore, restaurantStore } = useStores();
  const { inviteInfo } = restaurantStore;
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [notice, setNotice] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const throttledValue = useThrottle(value);

  const lastInviteCode = useMemo(() => {
    return memberStore.member.invite_code || "";
  }, [memberStore.member]);

  useEffect(() => {
    const { access_code, access_password } = restaurantStore.form;
    setValue(access_code === "invitecode" ? access_password : "");
  }, []);

  useEffect(() => {
    onChange({
      invite_code: inviteCode,
      required: inviteInfo.isOpen,
      enable: inviteInfo.isOpen ? !error : true,
    });
  }, [inviteCode, error]);

  useEffect(() => {
    onBlur(throttledValue || lastInviteCode);
  }, [throttledValue]);

  // 会多次触发
  async function onBlur(value) {
    try {
      setNotice("");
      // 如果输入为空，或输入值等于用户默认值
      if (!value) {
        return setInviteCode("");
      }
      // 检查输入值, 过短的值没有意义，不检测
      if (value.length < 4 && value.length > 0) {
        setError(true);
        return setInviteCode("");
      }
      let { nickname, notice } = await reservationService.verifyInvite(
        value,
        seats
      );
      setNotice(notice);
      if (nickname === "your friends") {
        setError(true);
        setInviteCode("");
      } else {
        setInviteCode(value);
        setError(false);
      }
    } catch (error) {
      setNotice("");
      setError(true);
      setInviteCode("");
    }
  }

  return useObserver(() => {
    const { inviteInfo } = restaurantStore;
    if (!inviteInfo.isOpen) return null;
    let help = null;
    if (error) {
      help = <Common.Help>{t("INCORRECT_INVITECODE")}</Common.Help>;
    } else {
      help = (
        <div style={{ margin: "5px 0", fontSize: "13px", color: "#333333" }}>
          {notice}
        </div>
      );
    }
    return (
      <div className="invite-code">
        <Form
          size="large"
          hideRequiredMark
          scrollToFirstError
          layout="vertical"
        >
          <Form.Item label={t("INVITE_CODE")} help={help}>
            <Input
              placeholder={lastInviteCode}
              value={value}
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
              onBlur={() => onBlur(value || lastInviteCode)}
              onChange={(ev) => setValue(ev.target.value)}
            ></Input>
            <SyncOutlined
              onClick={() => {
                setValue("");
                onBlur(lastInviteCode);
              }}
              style={{ fontSize: "24px", color: "#DC2224", marginLeft: 10 }}
            />
          </Form.Item>
        </Form>
      </div>
    );
  });
};

let PrePay = ({
  deals,
  enablePrePay,
  payment,
  setPaymentVisible,
  setCardVisible,
  paymentInfo,
  errtip,
  amount,
  mastercardNumber,
  VoucherComponent,
  isGroupBooking,
}) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const { projectInfo } = restaurantStore;
  const { currency, item_count, amount_type, origin_amount } = paymentInfo;
  // 如果有押金，金额使用押金金额，否则使用套餐金额
  let price = currency + " " + Number(amount).toLocaleString();
  let originPrice = currency + " " + Number(origin_amount).toLocaleString();
  let showPay = !isGroupBooking && amount;
  const showTwo = origin_amount !== amount;
  const isEvent = projectInfo ? projectInfo.project_type === "event" : false;
  return (
    <React.Fragment>
      {showPay ? (
        <>
          <PaymentSelector
            projectInfo={restaurantStore.projectInfo}
            payment={payment}
            paymentInfo={paymentInfo}
            amountType={amount_type}
            enablePrePay={enablePrePay}
            mastercardNumber={mastercardNumber}
            errtip={errtip}
            setPaymentVisible={setPaymentVisible}
            setCardVisible={setCardVisible}
          ></PaymentSelector>
        </>
      ) : null}

      <React.Fragment>
        {showPay ? (
          <section className="pre-title">
            {deals ? (
              <div className="pre-title__content">
                <p>{deals.category_name}</p>
                <div>
                  <span>{deals.price_desc[0] || ""}</span>
                  <span> * {item_count}</span>
                </div>
              </div>
            ) : amount_type === "deposit" ? (
              <div className="pre-title__content">
                <p>{t("PAYMENT.Deposit required")}</p>
                <div>
                  <span>
                    {currency + "" + (amount / item_count).toFixed(2)}
                  </span>
                  <span> * {item_count}</span>
                </div>
              </div>
            ) : isEvent ? (
              <div className="pre-title__content">
                <p>{projectInfo.title}</p>
                <div>
                  <span>
                    {currency + "" + (amount / item_count).toFixed(2)}
                  </span>
                  <span> * {item_count}</span>
                </div>
              </div>
            ) : (
              <div className="pre-title__content">
                <p>{t("A la carte")}</p>
                <div>
                  <span>
                    {currency + "" + (amount / item_count).toFixed(2)}
                  </span>
                  <span> * {item_count}</span>
                </div>
              </div>
            )}
          </section>
        ) : null}
        {/* 优惠券选择需要放在这个位置 */}
        {VoucherComponent}
        {/* 分割线 */}
        <div className="split-line"></div>
        {showPay ? (
          <Common.ListItem
            title={t("PAYMENT.Subtotal")}
            value={
              showTwo ? (
                <React.Fragment>
                  <span
                    className="text-gray line-through"
                    style={{
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {originPrice}
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    {price}
                  </span>
                </React.Fragment>
              ) : (
                <span
                  style={{ fontWeight: 500, fontSize: 16, lineHeight: "20px" }}
                >
                  {price}
                </span>
              )
            }
          ></Common.ListItem>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

function useThrottle(value, interval = 300) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastExecuted = useRef(Date.now());

  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
  }, [value, interval]);

  return throttledValue;
}
