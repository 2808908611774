import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../store";
import { Form, Input, message } from "antd";
import * as Common from "../../../components/common";
import schema from "async-validator";

/**
 * 补充用户信息
 *
 * @param {*} props
 */
export const UserForm = (props) => {
  const { memberStore, restaurantStore } = useStores();
  const { t } = useTranslation();
  let formdata = {
    email: "",
    first_name: "",
    last_name: "",
    promotion: true,
  };
  let fields = [
    {
      name: "first_name",
      label: t("FIRST_NAME"),
      help: <Common.Help>{t("ERROR.FIRST_NAME")}</Common.Help>,
      placeholder: t("Please provide a first name"),
    },
    {
      name: "last_name",
      label: t("LAST_NAME"),
      help: <Common.Help>{t("ERROR.LAST_NAME")}</Common.Help>,
      placeholder: t("Please provide a last name"),
    },
    {
      name: "email",
      label: t("EMAIL"),
      help: <Common.Help>{t("ERROR.EMAIL")}</Common.Help>,
      placeholder: t("Enter email address"),
    },
  ];
  let rules = {
    first_name: [{ required: true }],
    last_name: [{ required: true }],
    email: [{ required: true, type: "email" }],
  };
  let [values, setValues] = useState(formdata);
  let [errors, setErrors] = useState({});
  const [form] = Form.useForm();
  const validator = new schema(rules);
  const onChange = async (data) => {
    setErrors({});
    setValues(Object.assign({}, formdata, form.getFieldsValue(), data));
  };
  /**
   * 保存用户信息, 然后进入真实预订步骤
   *
   */
  const onSave = async () => {
    try {
      try {
        await validator.validate(values);
      } catch ({ errors, fields }) {
        return setErrors(fields);
      }
      await memberStore.update(values);
      restaurantStore.step = restaurantStore.STEPS.CONFIRM;
    } catch (error) {
      message.error(error.message);
    }
  };

  return useObserver(() => {
    let disabled = !(values.email && values.first_name);
    return (
      <div className="page-confirm-details">
        <h2>{t("CONFIRM DETAILS")}</h2>
        <article>
          {t("Almost done!")}
          <p>{t("provide_your_full_name")}</p>
          <p>{t("information_used")}</p>
        </article>
        <Form
          form={form}
          hideRequiredMark
          scrollToFirstError
          layout="vertical"
          size="large"
          initialValues={formdata}
          onValuesChange={onChange}
        >
          {fields.map((item) => {
            return (
              <Form.Item
                key={item.name}
                {...item}
                help={errors[item.name] ? item.help : null}
              >
                <Input placeholder={item.placeholder} />
              </Form.Item>
            );
          })}

          <Common.Checkbox
            checked={values.promotion}
            onChange={(val) => onChange({ promotion: val })}
          >
            {t("related_emails")}
          </Common.Checkbox>
        </Form>
        <div style={{height: 69}}></div>
        <footer className="page-footer" align="right">
          <Common.Button type="primary" disabled={disabled} onClick={onSave}>
            {t("SAVE")}
          </Common.Button>
        </footer>
      </div>
    );
  });
};

export default UserForm;
