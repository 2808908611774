import React, { useState, useEffect, useMemo, useRef } from "react";
import qs from "qs";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Modal, message } from "antd";
import { useStores } from "../../store";
import * as Common from "../../components/common";
import http, { lang, getQuery, urlBuilder } from "../../service/http";
import reservationService from "../../service/reservation";
import "./index.less";
import { useHistory, useParams } from "react-router-dom";
import BookHeader from "./components/book-header";
import BookInfo from "./components/book-info";
import BookRestaurant from "./components/book-restaurant";
import AutoBack from "../../components/dc-auto-back";

function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

// 进出场，回调
async function animate(dir, callback) {
  if (dir === "PUSH") {
    callback();
    await sleep(30);
    document.body.setAttribute("animate", "push");
  } else {
    document.body.setAttribute("animate", "pop");
    await sleep();
    callback();
    document.body.setAttribute("animate", "");
  }
}

/**
 * 预订成功展示
 *
 * 团队预订和普通预订，需要两种不同的展示数据, 如果是团队预定，直接返回TBD，这里直接展示BOOK内容，不再提取
 *
 * @param {*} props
 */
const Success = (props) => {
  const { restaurantStore, memberStore } = useStores();
  const [visible, setVisible] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { id, action } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const timer = useRef();
  const needPay = useMemo(() => {
    const { reservation } = restaurantStore;
    if (reservation.payment?.channel === "amexweb") return false;
    return reservation.status === "need_pay";
  }, [restaurantStore.reservation]);
  const enableBookAgain =
    action !== "edit" && action !== "cancel" && getQuery().from !== "app";
  // 如果是从iframe中预订的，需要跳转回原始页面
  function bookAgain() {
    if (needPay) {
      return goPaymentUrl(id);
    }
    const queryObj = {
      ...getQuery(),
      reservation_id: undefined,
      reservation_code: undefined,
    };
    history.push(`/restaurants/${id}/reservation?` + qs.stringify(queryObj));
    location.reload();
  }

  useEffect(() => {
    const {
      reservation_seats: seats,
      reservation_date,
      reservation_time: time,
    } = restaurantStore.reservation;
    restaurantStore.getPaymentInfo({
      ...restaurantStore.reservation,
      seats,
      time,
      reservation_date,
      restaurant_id: restaurantStore.restaurant.id,
    });
    if (action === "cancel") {
      animate("PUSH", () => setVisible(true));
    }
  }, []);

  // 如果是amexweb方式支付，需要获取支付状态
  useEffect(() => {
    const { reservation } = restaurantStore;
    function getBooking() {
      restaurantStore.assignEdit(
        reservation.reservation_code,
        reservation.id,
        reservation.restaurant.id
      );
    }
    if (
      reservation.payment?.channel === "amexweb" &&
      reservation.payment?.status === "pending"
    ) {
      setShowLoading(true);
      getBooking();
      timer.current = setInterval(() => {
        getBooking();
      }, 2000);
    } else {
      setShowLoading(false);
      clearInterval(timer.current);
    }
    return () => {
      setShowLoading(false);
      clearInterval(timer.current);
    };
  }, []);

  // 构造支付页面地址
  function goPaymentUrl(id) {
    // 如果需要支付，重定向页面到支付页面
    const domain = document.location.href.split("#")[0];
    const { reservation_code, reservation_id, private_token } = getQuery();
    const base = domain + `#/restaurants/${id}/reservation`;
    let callback = urlBuilder(reservation_code, reservation_id);
    const query = qs.stringify({
      reservation_code: reservation_code,
      reservation_id: reservation_id,
      channel: "",
      private_token: private_token,
      success_url: callback,
      project: getQuery().project,
      program: getQuery().program,
      deal_id: getQuery().deal_id,
      lang: getQuery().lang,
    });
    window.top.location.href = `${base}/pay?${query}`;
  }

  return useObserver(() => {
    const { bookingInfo, bookingResult, reservation } = restaurantStore;
    let isGroupBooking = bookingInfo.group_booking;
    // if (reservation.payment && reservation.payment.status === "pending") {
    //   return <Fail />;
    // }
    let message = bookingResult.message
      ? bookingResult.message.replace(/↵/gi, "\r\n")
      : "";
    return (
      <React.Fragment>
        {visible ? (
          <DetailsPopup
            onBack={() => animate("POP", () => setVisible(false))}
          ></DetailsPopup>
        ) : null}

        <Modal
          visible={showLoading}
          wrapClassName="dc-modal"
          width={300}
          footer={null}
          closable={false}
          keyboard={false}
          maskClosable={false}
          centered
          destroyOnClose
        >
          <div className="show-loading">
            <Common.Loading red loop></Common.Loading>
            <p>{t("PAYMENT.Processing payment")}</p>
          </div>
        </Modal>

        <div className="step-success page-animate-main">
          <BookHeader
            message={
              isGroupBooking || needPay ? (
                ""
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: message }}
                  style={{ whiteSpace: "pre-wrap" }}
                ></div>
              )
            }
            title={
              isGroupBooking
                ? t("Request successful!")
                : reservation.status_humanize
            }
          />

          <BookInfo needPay={needPay} />
          <div className="page-footer-place"></div>
          {bookingInfo.back_url ? <AutoBack back_url={bookingInfo.back_url}></AutoBack> : (
            <footer className="page-footer" align="center">
              {needPay ? (
                <Common.Button type="primary" onClick={bookAgain}>
                  {t("PAYMENT.Pay now")}
                </Common.Button>
              ) : enableBookAgain ? (
                <Common.Button type="primary" onClick={bookAgain}>
                  {t("Book again")}
                </Common.Button>
              ) : null}
            </footer>
          )}
          
        </div>
        
      </React.Fragment>
    );
  });
};

/** 预定详情：创建接口返回，取book字段，查询接口返回，取全部 */
export const DetailsPopup = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { restaurantStore } = useStores();
  const history = useHistory();
  const { action } = useParams();
  /**编辑预订 */
  async function editBooking() {
    animate("POP", () => {});
    const { bookingInfo } = restaurantStore;
    const { reservation_code, id, project } = bookingInfo;
    const path = `/restaurants/${bookingInfo.restaurant.id}/reservation/edit?`;
    const query = qs.stringify({
      reservation_id: id,
      reservation_code: reservation_code,
      project: project,
      lang: lang,
      from: restaurantStore.STEPS.COMPLETE,
    });
    await restaurantStore.assignEdit(reservation_code, id);
    if (action === "edit") {
      restaurantStore.step = restaurantStore.STEPS.START;
    } else {
      history.replace(path + query);
    }
  }
  /**取消预订 */
  async function cancelBooking() {
    const { bookingInfo } = restaurantStore;
    setVisible(false);
    try {
      await restaurantStore.cancel(
        bookingInfo.reservation_code,
        bookingInfo.id
      );
      restaurantStore.bookingInfo = await reservationService.show(
        bookingInfo.reservation_code,
        bookingInfo.id
      );
      message.success(t("RESERVATION_CANCELLED"));
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  }

  return useObserver(() => {
    const { bookingInfo } = restaurantStore;
    const noBack = action === "edit" || action === "cancel;";
    return (
      <div className="page-popup page-animate-popup over-header page-details-Popup">
        <CancelModal
          visible={visible}
          onSubmit={cancelBooking}
          setVisible={setVisible}
        />
        <header>
          <div className="header-left" onClick={props.onBack} hidden={noBack}>
            <i className="icon icon-arrow-left"></i>
          </div>
          <h2>{t("Booking details")}</h2>
          <div className="header-right"></div>
        </header>
        <div className="page-popup--body">
          <h2
            style={{
              fontSize: 30,
              lineHeight: 1.4,
            }}
          >
            {t(bookingInfo.status_lng)}
          </h2>
          <BookInfo />
          <BookRestaurant />
          <div className="page-footer-place"></div>
          <footer className="page-footer" align="right">
            {bookingInfo.can_cancel && (
              <Common.Button onClick={() => setVisible(true)}>
                {t("CANCEL")}
              </Common.Button>
            )}
            {bookingInfo.can_update && (
              <Common.Button type="primary" onClick={editBooking}>
                {t("MODIFY")}
              </Common.Button>
            )}
          </footer>
        </div>
      </div>
    );
  });
};

/**
 * 取消预订
 *
 * @param {*} props
 * @returns
 */
export const CancelModal = (props) => {
  let { visible, setVisible, onSubmit } = props;
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <p style={{ padding: "40px 40px 0 40px" }}>{t("SURE_TO_CANCEL")}</p>
      <footer>
        <Common.Button
          onClick={() => {
            setVisible(false);
          }}
        >
          {t("CANCEL")}
        </Common.Button>
        <Common.Button onClick={onSubmit} type="primary">
          {t("CONFIRM")}
        </Common.Button>
      </footer>
    </Modal>
  );
};

export default Success;
