import React from "react";
import { Divider, Modal } from "antd";
import { useObserver } from "mobx-react-lite";
import { useStores } from "../../store";
import "./index.less";
import { useState } from "react";
import { Confirm } from "../common";

// 每个图标有三个状态：待选(灰色)，选中(红底白色)，已选(红色)

const moveTo = {
  0: 0,
  1: 57,
  2: 114,
  3: 171,
  4: 228,
  5: 285,
};

/**
 * tabs切换头部
 *
 * 第四个步骤，是针对未登录用户补充用户信息用的
 *
 * @param {*} props
 */
const TabHeader = (props) => {
  const { memberStore, restaurantStore } = useStores();
  const [visible, setVisible] = useState(false);
  const [waitStep, setWaitStep] = useState("");
  const size = restaurantStore.steps.length;
  const isEdit = !!restaurantStore.form.id;
  // 当用户不需要修改资料时，第四步禁止点击
  const isNeedUpdateUser = memberStore.noMemebrInfo;
  function onStepChange(step, currentStep, field) {
    if (step >= currentStep && !isEdit) {
      return console.warn("还不能操作此步骤");
    }
    if (!isNeedUpdateUser && step === restaurantStore.STEPS.INFO) {
      return console.warn("不需要修改资料");
    }
    // 当有支付逻辑的时候，增加弹窗提示
    if (restaurantStore.enablePrePay) {
      setVisible(true);
      setWaitStep(step);
    } else {
      restaurantStore.reset(step, field);
    }
  }

  function onConfirm() {
    restaurantStore.reset(waitStep);
    setVisible(false);
  }

  return useObserver(() => {
    let { step } = restaurantStore;
    return (
      <div className="tab-header">
        <Confirm
          visible={visible}
          setVisible={setVisible}
          onConfirm={onConfirm}
        />
        <div className="tab-inner">
          <div className="tab-bar" style={{ left: moveTo[step] }}></div>
          {restaurantStore.steps.map((item, index) => {
            let isSelectd = step > index ? "selected" : "";
            let isCurrent = step == index ? "current" : "";
            let className = ["icon", item.icon, isSelectd, isCurrent].join(" ");
            return (
              <React.Fragment key={index}>
                <div className="tab-item">
                  <span
                    onClick={() => onStepChange(item.value, step, item.name)}
                    className="tab-item-inner"
                    data-selected={step === index}
                  >
                    <span className={className}></span>
                  </span>
                </div>
                {index < size - 1 ? <Divider type="vertical" /> : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  });
};

export default TabHeader;
