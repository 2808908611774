import React, { useState, useEffect, useRef, useMemo } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import QRCode from "qrcode.react";
import "./index.less";
import { useHistory } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import * as Common from "@/components/common";
import http, { lang, getQuery } from "@/service/http";
import BookHeader from "../success/components/book-header";
import {
  PaymentSelector,
  PaymentPopup,
  StripePayModal,
} from "@/components/dc-payment";
import { CardsPopup } from "@/pages/confirm/components/master-card";
import { message } from "antd";
import { loadStripe } from "@stripe/stripe-js/pure";
import { usePayResult } from '@/utils'

loadStripe.setLoadParameters({ advancedFraudSignals: false });

/**二维码支付 */
const QrcodePage = (props) => {
  const { paymentStore, restaurantStore } = useStores();
  const timer = useRef();
  const { t } = useTranslation();
  const [qrcode, setQrcode] = useState("");
  const onCancel = () => {
    clearInterval(timer.current);
    props.onCancel()
  }

  useEffect(() => {
    const { channel, credential, billQRCode } = paymentStore.payment;
    switch (true) {
      case channel === "alipay_qr":
        setQrcode(credential.alipay_qr);
        break;
      case channel === "wx_pub_qr":
        setQrcode(credential.wx_pub_qr);
        break;
      case !!billQRCode:
        setQrcode(billQRCode);
        break;
    }
    timer.current = setInterval(() => {
      paymentStore.getPayment().then(({ data }) => {
        if (data.status !== "pending") {
          clearInterval(timer.current);
          const path = `/#/restaurants/${restaurantStore.restaurant.id}/reservation`;
          const query = qs.stringify({
            reservation_code: restaurantStore.reservation.reservation_code,
            reservation_id: restaurantStore.reservation.id,
          });
          const url =
            decodeURIComponent(getQuery().success_url) ||
            `${path}/complete?${query}`;
          window.location.href = url;
        }
      });
    }, 3500);
    () => {
      clearInterval(timer.current);
    };
  }, [paymentStore, restaurantStore.reservation.id, restaurantStore.reservation.reservation_code, restaurantStore.restaurant.id]);
  return useObserver(() => {
    return (
      <div className="step-payment">
        <h2>{t("PAYMENT.PAY WITH WECHAT")}</h2>
        <div className="tip">{t("PAYMENT.qrcode tip")}</div>

        <div className="qrcode">
          {qrcode ? <QRCode size={145} value={qrcode} /> : null}
        </div>

        <footer className="page-footer" align="right">
          <Common.Button type="primary" onClick={onCancel}>
            {t("CANCEL")}
          </Common.Button>
        </footer>
      </div>
    );
  });
};

function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

// 进出场，回调
async function animate(dir, callback) {
  if (dir === "PUSH") {
    callback();
    await sleep(30);
    document.body.setAttribute("animate", "push");
  } else {
    document.body.setAttribute("animate", "pop");
    await sleep();
    callback();
    document.body.setAttribute("animate", "");
  }
}

/**
 * 支付页面
 *
 * 进入支付页面，首先获取订单信息
 *
 * @param {*} props
 */
const Payment = () => {
  const { restaurantStore, paymentStore } = useStores();
  const { restaurant } = restaurantStore;
  let [paymentVisible, setPaymentVisible] = useState(false);
  let [payment, setPayment] = useState("");
  let [showQrocde, setShowQrocde] = useState(false);
  let [cardVisible, setCardVisible] = useState(false);
  let [mastercardNumber, setMastercardNumber] = useState("");
  let [profile, setProfile] = useState(null);
  let [paymentLoadingVisible, setPaymentLoadingVisible] = useState(false);
  const { t } = useTranslation();
  const [bankCardId, setBankCardId] = useState("");
  const stripeRef = useRef(null);
  const [stripePayVisible, setStripePayVisible] = useState(false);
  // Stripe 支付 clientSecret
  const [clientSecret, setClientSecret] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formatOriginAmount, setFormatOriginAmount] = useState("");
  const [formatAmount, setFormatAmount] = useState("");
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [weappConfig, setWeappConfig] = useState({});
  const timer = useRef();

  function checkPayStatus() {
    paymentStore.getPayment().then(({ data }) => {
      if (data.status !== "pending") {
        clearInterval(timer.current);
        onPaymentComplete();
      }
    }).catch(() => {
      clearInterval(timer.current);
      onPaymentComplete();
    })
  }

  /**
   * 移动端支付方式
   * 
   * 微信支付：from:weixin + (uac_wx)
   * 跳转链接 from:mobile + (uac_alipay | uac_upacp)
   * 云闪付小程序 from:weixin + (uac_upacp)
   * dc小程序 from:mobile + (uac_wx)
   * 
   */

  const showWxLunch = useMemo(() => {
    return payment === 'uac_upacp' && paymentStore.deviceDetect() === 'weixin'
  }, [payment])

  /** 是否存在Stripe支付 */
  const hasStripe = useMemo(
    () =>
      paymentStore.paymentMethods.findIndex((v) => v.provider === "stripe") >
      -1,
    [paymentStore.paymentMethods]
  );

  useEffect(() => {
    let {
      reservation_id,
      reservation_code,
      channel,
      bankCardId: bankCardIdVal,
      cardNumber,
    } = getQuery();
    setBankCardId(bankCardIdVal);
    paymentStore.getMethods().then((data) => {
      const methods = data.map((v) => v.provider);
      setPayment(channel);
      if (methods.includes("stripe")) {
        const item = data.find((v) => v.provider === "stripe");
        setPayment(item?.keyword);
        // 获取stripe 支付key, 取餐厅region_id
        paymentStore.getStripeKey(restaurant.region?.id).then((data) => {
          loadStripe(data.public_key, {
            betas: [
              "elements_enable_deferred_intent_beta_1",
              "blocked_card_brands_beta_1",
            ],
          }).then((stripe) => {
            stripeRef.current = stripe;
          });
        });
      } else if (methods.includes("amex")) {
        const item = data.find((v) => v.provider === "amex");
        setPayment(item?.keyword);
      }
    });
    const {
      reservation_seats: seats,
      reservation_date,
      reservation_time: time,
    } = restaurantStore.reservation;
    restaurantStore
      .getPaymentInfo({
        ...restaurantStore.reservation,
        seats,
        time,
        reservation_date,
        restaurant_id: restaurantStore.restaurant.id,
      })
      .then((data) => {
        setFormatOriginAmount(data.format_origin_amount || "");
        setFormatAmount(data.format_amount || "");
        setAmount(data.amount || 0);
        setCurrency(data.currency_code);
      });
    restaurantStore
      .getBooking(reservation_code, reservation_id)
      .then((data) => {
        setProfile(data);
        if (channel === "mpgs") {
          setMastercardNumber(data.payment.pay_method || "");
        } else {
          setMastercardNumber(cardNumber);
        }
        // 第一次进入，自动唤起支付，不需要点击按钮
        if (localStorage.getItem("AUTO_PAY") === "true") {
          localStorage.removeItem("AUTO_PAY");
          if(['uac_upacp', 'uac_alipay', 'uac_wx'].includes(channel)) {
            return;
          }
          onPay();
        }
      });
      paymentStore.getWxConfig();
  }, []);

  useEffect(() => {
    clearInterval(timer.current);
    if(!showWxLunch) {
       setWeappConfig({});
    } else {
      // 切换到云闪付小程序支付，需要获取授权码信息
      paymentStore
      .prePayment({
        ...restaurantStore.reservation,
        payment_method: payment,
        bank_card_uuid: null,
      }).then(data => {
        clearInterval(timer.current);
        checkPayStatus();
        timer.current = setInterval(checkPayStatus, 3500);
        setWeappConfig({
          appid: data.miniPayRequest.cqpMpAppId,
          path: data.miniPayRequest.cqpMpPath,
        })
      })
    }
  }, [showWxLunch])

  function onPaymentPopupBack(data) {
    setPaymentVisible(false);
    setPayment(data);
  }
  function onCardsPopupBack(data) {
    setCardVisible(false);
    if (data) {
      setMastercardNumber(data.short_number);
      setBankCardId(data.uuid);
    }
  }
  /**唤起支付 */
  function onPay() {
    let client = paymentStore.deviceDetect();
    if (submitLoading) return;
    setSubmitLoading(true);
    let { channel, private_token } = getQuery();
    const provider = paymentStore.paymentMethods.find((v) => v.keyword === payment).provider;
    const isGuest =
      restaurantStore.reservation?.project_config?.authentication_method ===
        "no_membership" && !private_token;
    const hasMpgs =
      paymentStore.paymentMethods.findIndex((v) => v.provider === "mpgs") > -1;

    // 跳转到DC微信小程序
    if(payment === 'uac_wx' && client === 'mobile') {
      let {
        reservation_id,
        reservation_code,
      } = getQuery();
      return paymentStore.getScheme(reservation_id, reservation_code, payment, provider, restaurantStore.reservation.payment).then(outlink => {
        location.href = outlink
      })
    }

    if (payment === "amexweb") {
      // 支付完成后，需要跳转到某个中间页，去获取支付结果
      paymentStore
        .prePayment({
          ...restaurantStore.reservation,
          bank_card_uuid: null,
          payment_method: payment,
          success_url: getSuccessPage(),
        })
        .then((data) => {
          window.location.replace(data.payUrl);
        })
        .catch((err) => {
          setSubmitLoading(false);
          message.error(err.message);
        });
    } else if (hasStripe && isGuest) {
      paymentStore
        .prePayment({
          ...restaurantStore.reservation,
          bank_card_uuid: null,
          payment_method: payment || channel || "visa",
        })
        .then((data) => {
          setStripePayVisible(true);
          setClientSecret(data.client_secret);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    } else if (hasStripe && !isGuest) {
      // Stripe 支付
      paymentStore
        .prePayment({
          ...restaurantStore.reservation,
          bank_card_uuid: bankCardId,
          payment_method: payment || channel || "visa",
        })
        .then(async (res) => {
          // 如果存在card 直接调用支付
          // 前端验证是否支付成功
          const { client_secret, payment_method } = res;
          const {
            paymentIntent,
            error,
          } = await stripeRef.current.confirmCardPayment(client_secret, {
            payment_method,
          });
          if (error) {
            throw error;
          }
          if (
            paymentIntent.status === "succeeded" ||
            paymentIntent.status === "requires_capture"
          ) {
            console.info("支付成功");
            let count = 0;
            let timer = null;
            // 查询订单状态，等待支付成功
            const helper = async () => {
              // 最大重试十次
              if (count > 10) {
                clearTimeout(timer);
                return onPaymentComplete();
              }
              const res = await restaurantStore.getBooking(
                restaurantStore.reservation.reservation_code,
                restaurantStore.reservation.id
              );
              if (res.status === "need_pay") {
                count++;
                timer = setTimeout(helper, 400);
              } else {
                return onPaymentComplete();
              }
            };
            helper();
          } else {
            throw new Error(paymentIntent);
          }
        })
        .catch((err) => {
          message.error(err.message);
          console.error("支付失败", err);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    } else {
      paymentStore
        .prePayment({
          ...restaurantStore.reservation,
          payment_method: hasMpgs ? "mpgs" : payment || channel,
          bank_card_uuid: hasMpgs ? bankCardId : null,
          success_url: getSuccessPage()
        })
        .then((data) => {
          console.log("pay", JSON.stringify(data, '', 2))
          debugger;
          // 银联支付
          if(provider === 'unionpay') {
            if(data.url && ['uac_upacp', 'uac_alipay', 'uac_wx'].includes(payment)) {
              window.location.href = data.url
            } else if(data.billQRCode) {
              setShowQrocde(true);
            }
            return;
          }
          if (["wx_pub_qr", "alipay_qr"].includes(data.channel)) {
            setShowQrocde(true);
          } else {
            setPaymentLoadingVisible(true);
            // ping++支付
            if (channel != "mpgs") {
              paymentStore.callPayment().then((status) => {
                setPaymentLoadingVisible(false);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          message.error(error.message);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  }
  function getSuccessPage() {
    if(getQuery().success_url) {
      return decodeURIComponent(getQuery().success_url)
    }
    const domain = document.location.href.split("#")[0];
    const base =
      domain +
      `#/restaurants/${restaurantStore.restaurant.id}/reservation/complete?`;
    const query = qs.stringify({
      ...getQuery(),
      channel: payment,
      reservation_code: restaurantStore.reservation.reservation_code,
      reservation_id: restaurantStore.reservation.id,
    });
    return base + query;
  }
  /**获取到支付结果 */
  function onPaymentComplete() {
    setPaymentLoadingVisible(false);
    // 支付完成，去完成页面
    window.location.href = getSuccessPage();
  }
  return useObserver(() => {
    const { reservation, bankNumber, paymentInfo } = restaurantStore;
    const hideFoot = reservation.status !== "need_pay";

    if (!profile) return <div></div>;

    if (showQrocde) return <QrcodePage onCancel={() => setShowQrocde(false)} />;

    return (
      <React.Fragment>
        {/* 支付方式 */}
        {paymentVisible ? (
          <PaymentPopup
            visible={paymentVisible}
            data={payment}
            onBack={(data) => animate("POP", () => onPaymentPopupBack(data))}
          ></PaymentPopup>
        ) : null}
        {/* 万事达卡 */}
        {cardVisible ? (
          <div style={{ paddingTop: 15 }}>
            <CardsPopup
              visible={cardVisible}
              channel={payment || reservation.payment?.channel}
              onBack={(data) => animate("POP", () => onCardsPopupBack(data))}
            ></CardsPopup>
          </div>
        ) : null}
        {/* 等待支付 */}
        {paymentLoadingVisible ? (
          <Common.PaymentModal
            visible={paymentLoadingVisible}
            onComplete={onPaymentComplete}
          />
        ) : null}
        {/** 支付弹窗 */}
        {hasStripe && paymentStore.stripePublicKey ? (
          <StripePayModal
            visible={stripePayVisible}
            onVisibleChange={setStripePayVisible}
            setSubmitLoading={setSubmitLoading}
            stripePublicKey={paymentStore.stripePublicKey}
            clientSecret={clientSecret}
            amount={amount}
            currency={currency}
            formatAmount={formatAmount}
            channel={payment || reservation.payment?.channel}
          />
        ) : null}
        <div className="step-success page-animate-main">
          <BookHeader title={reservation.status_humanize} />
          <BookInfo status={reservation.status} />
          <h3 className="list-head" style={{ marginTop: 10 }}>
            {t("PAYMENT.Complete booking")}
          </h3>
          <article>{t("PAYMENT.hold booking")}</article>

          <PaymentSelector
            projectInfo={reservation.project_config}
            payment={payment}
            paymentInfo={paymentInfo}
            mastercardNumber={mastercardNumber || bankNumber}
            disabled={hideFoot}
            setPaymentVisible={() => {
              animate("PUSH", () => setPaymentVisible(true));
            }}
            setCardVisible={() => {
              animate("PUSH", () => setCardVisible(true));
            }}
          ></PaymentSelector>
          <div className="page-footer-place" hidden={hideFoot}></div>
          <footer className="page-footer" align="right" hidden={hideFoot}>
            <Common.Button
              type="primary"
              loading={submitLoading}
              onClick={showWxLunch ? null : onPay}
              disabled={!payment}
            >
              {t("PAYMENT.Pay now")}
              <WxOpenLaunch {...weappConfig}>{t("PAYMENT.Pay now")}</WxOpenLaunch>
            </Common.Button>
          </footer>
        </div>
      </React.Fragment>
    );
  });
};

/**
 * 唤起微信小程序
 * @param {*} props 
 * @returns 
 */
const WxOpenLaunch = (props) => {
  return <wx-open-launch-weapp
      id="launch-btn"
      appid={props.appid}
      path={props.path}
      style={{
        position: 'absolute',
        width: props.appid ? '100%' : '1px',
        height: '38px',
        opacity: 0,
        top: 0,
        left: 0,
        zIndex: 10
      }}
    >
      <script type="text/wxtag-template">
        <button style={{
          position: 'absolute',
          width: props.appid ? '100%' : '1px',
          height: '38px',
          opacity: 0,
          top: 0,
          left: 0,
          zIndex: 10,
        }}>{props.children}</button>
      </script>
    </wx-open-launch-weapp>
}


/**
 * 订单基本信息
 *
 */
const BookInfo = ({ status }) => {
  const { restaurantStore, memberStore } = useStores();
  const { t } = useTranslation();
  return useObserver(() => {
    let { reservation, paymentInfo } = restaurantStore;
    let { benefits } = memberStore;
    let {
      deal,
      group_booking,
      formated_date,
      reservation_date,
      formated_time,
      reservation_time,
      reservation_seats,
      children_seats,
      reservation_code,
      discount,
      reservation_comment = "",
      loyalty_program_benefits = [],
      payment,
      genre,
    } = reservation;
    let date =
      formated_date ||
      moment(reservation_date).format(
        lang.startsWith("zh") ? "dddd, M月D日" : "dddd,MMMM DD"
      );
    let time = formated_time || moment(reservation_time).format("hh:mm A");
    let remark = reservation_comment || "";
    let { currency, price } = payment || {};
    let isDeposit = genre === "deposit";
    let isNeedPay = status === "need_pay";
    let deposit = `${currency} ${Number(price).toLocaleString()}`;
    let depositTitle =
      payment.status !== "pending" ? (
        <Common.Icon text={t("PAYMENT.Deposit paid")} icon="icon icon-info" />
      ) : (
        <span style={{ fontSize: 15, fontWeight: "bold", color: "#353535" }}>
          {t("PAYMENT.Deposit")}:
        </span>
      );
    // 筛选出可用的部分
    loyalty_program_benefits = loyalty_program_benefits.filter((item) => {
      return true;
      return benefits.find((sub) => sub.id === item.id);
    });

    return (
      <div className="book-result__body">
        <h3 className="list-head">{t("Details")}</h3>
        <Common.ListItem
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.DATE")}
              icon="icon icon-booking"
            />
          }
          value={date}
        />
        <Common.ListItem
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.TIME")}
              icon="icon icon-time"
            />
          }
          value={time}
        />
        <Common.ListItem
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.PEOPLE")}
              icon="icon icon-seat"
            />
          }
          value={reservation_seats}
        />
        <Common.ListItem
          hidden={children_seats < 1}
          title={
            <Common.Icon text={t("Kids Included")} icon="icon icon-seat" />
          }
          value={t("Children seats", {
            adult: reservation_seats - children_seats,
            children: children_seats,
          })}
        />
        <Common.ListItem
          hidden={!reservation_code || isNeedPay}
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.CODE")}
              icon="icon icon-info"
            />
          }
          value={reservation_code}
        />
        <Common.ListItem
          hidden={!isDeposit}
          title={depositTitle}
          value={deposit}
        />
        {deal && (
          <Common.ListItem
            hidden={isDeposit}
            title={
              <Common.Icon
                text={
                  <React.Fragment>
                    <span
                      className="ellipsis"
                      style={{
                        display: "inline-block",
                        maxWidth: "150px",
                        marginRight: 3,
                      }}
                    >
                      {deal.name}
                    </span>
                    {paymentInfo.item_count > 0 && (
                      <span> * {paymentInfo.item_count}</span>
                    )}
                  </React.Fragment>
                }
                icon="icon icon-deal"
              ></Common.Icon>
            }
            value={
              <span>
                {currency} {Number(price).toLocaleString()}
              </span>
            }
          ></Common.ListItem>
        )}

        <Common.ListItem
          hidden={!discount}
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.BS")}
              icon="icon icon-save"
            />
          }
          value={discount}
        />
        <Common.ListItem
          hidden={!loyalty_program_benefits.length}
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.VIP")}
              icon="icon icon-vip"
            />
          }
          value={
            <span>
              {loyalty_program_benefits.map(({ id, title }) => (
                <div key={id}>{title}</div>
              ))}
            </span>
          }
        />
        <React.Fragment>
          <Common.ListItem
            hidden={!remark.length}
            title={
              <Common.Icon
                text={
                  group_booking
                    ? t("GROUP_REMARK")
                    : t("RESERVATION_INFO.Requests")
                }
                icon="icon icon-info selected"
              />
            }
          />
          <article className="requests-text" hidden={!remark.length}>
            {remark}
          </article>
        </React.Fragment>
      </div>
    );
  });
};

export default Payment;
