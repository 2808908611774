import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import { Row, Col, Modal } from "antd";
import Pick from "@/components/pick";
import { DcCheckbox, Button } from "@/components/common";
import "./index.less";
import PlusIcon from "@/images/plus.svg";
import { getQuery } from "@/service/http";

const { show_group_booking } = getQuery();

/**
 * 选择座位数
 *
 * @param {*} props
 */
const Seats = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setChecked(restaurantStore.form.children_seats > 0);
  }, []);

  // 确认选择座位, 如果不包含儿童，需要把该字段设置为空
  function onConfirm() {
    restaurantStore.selectSeat(checked ? null : { children_seats: 0 });
    window.parent.postMessage({
      type: 'btn_click',
      data: {
        text: 'confirm',
      }
    }, '*')
  }
  // 选择座位数
  function onClick(seatData) {
    let setStep = !restaurantStore.bookingConditions.allow_kids;
    if (seatData.seats < 2) {
      setChecked(false);
    }
    restaurantStore.selectSeat(
      {
        ...seatData,
        children_seats: 0,
      },
      setStep
    );
  }
  // 选择儿童数
  function setChild(num) {
    restaurantStore.selectSeat({ children_seats: num }, false);
  }

  // 开始团体预订
  const onGroup = () => {
    restaurantStore.groupBooking();
  };
  return useObserver(() => {
    const { seats, form, bookingConditions } = restaurantStore;
    // 如果勾选了包含儿童，需要选择至少一位儿童才可以提交
    let enable = form.seats > 0 && (checked ? form.children_seats > 0 : true);
    let style = form.seats > 1 ? {} : { opacity: 0.3, pointerEvents: "none" };
    return (
      <div className="step-seats">
        <h2>{t("HOW_MANY_PEOPLE")}</h2>
        {/* 时间分组 */}
        <div className="seats-group">
          <Row gutter={24}>
            {seats.map((item, index) => {
              let discount = item.discount || "";
              return (
                <Col span={6} key={index}>
                  <Pick
                    onClick={() => onClick(item)}
                    discount={discount !== ""}
                    label={item.seats}
                    desc={discount}
                    selected={item.seats == form.seats}
                  />
                </Col>
              );
            })}
            {show_group_booking == "true" ? (
              <Col span={6}>
                <div className="pick-item" onClick={onGroup}>
                  <div className="pick-item--label">
                    <img src={PlusIcon} width="16" />
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
        {/* 包含儿童 */}
        {bookingConditions.allow_kids ? (
          <div className="step-seats-title">
            <div style={style}>
              <DcCheckbox checked={checked} onChange={setChecked}>
                {t("Children Included")}
              </DcCheckbox>
            </div>
          </div>
        ) : null}
        {checked && (
          <ChildrenInlucde
            kidsPolicy={bookingConditions.kids_policy}
            form={form}
            onClick={setChild}
          ></ChildrenInlucde>
        )}
        <div className="page-footer-place"></div>
        {bookingConditions.allow_kids && (
          <footer className="page-footer" align="right">
            <Button type="primary" onClick={onConfirm} disabled={!enable}>
              {t("CONFIRM")}
            </Button>
          </footer>
        )}
      </div>
    );
  });
};

function ChildrenInlucde(props) {
  const { t } = useTranslation();
  if (props.form.seats < 2) return null;
  const list = new Array(props.form.seats - 1).fill(1).map((item, index) => {
    return index + 1;
  });
  return (
    <section className="children-inlucde">
      <article
        dangerouslySetInnerHTML={{
          __html: props.kidsPolicy,
        }}
      ></article>
      <h2>{t("HOW MANY CHILDREN INCLUDED")}</h2>
      <div className="seats-group">
        <Row gutter={24}>
          {list.map((num, index) => {
            return (
              <Col span={6} key={index}>
                <Pick
                  onClick={() => props.onClick(num)}
                  label={num}
                  selected={num == props.form.children_seats}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

/**
 * 取消预订
 *
 * @param {*} props
 * @returns
 */
export const ChildrenInlucdeModal = (props) => {
  let { visible, setVisible } = props;
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <p style={{ padding: "40px 20px 0 20px" }}>
        {t("Children Included request")}
      </p>
      <footer style={{ justifyContent: "center" }}>
        <Button onClick={() => setVisible(false)} type="primary">
          {t("CONFIRM")}
        </Button>
      </footer>
    </Modal>
  );
};

export default Seats;
