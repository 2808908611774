// 把多个步骤的数据和操作，全都放进mobx，避免组件传值
import { action, computed, observable } from "mobx";
import memberService from "../service/member";
import http, { getQuery } from "../service/http";

const benefitsCache = {}

let query = getQuery()

window.private_token = query["access-token"] || query.private_token

class MemberStore {
  @observable member = {}; // 用户资料
  @observable benefits = []; // 会员礼遇

  /**
   * 是否是会员
   *
   * @readonly
   * @memberof MemberStore
   */
  @computed
  get isMember() {
    return this.member.id;
  }

  /**
   * 是否需要补充用户资料
   *
   * @readonly
   * @memberof MemberStore
   */
  @computed
  get noMemebrInfo() {
    let { id, first_name, last_name, email } = this.member;
    return id && (!email || !first_name || !last_name);
  }

  /**
   * 初始化用户数据
   *
   * @memberof MemberStore
   */
  @action
  async bootstrap(restaurant, fallback) {
    let query = getQuery()
    let { uid, client, private_token } = query
    let isAdminBooking = uid && client
    if (this.member.mobile || this.member.email) {
      if (fallback) {
        this.member.first_name = this.member.first_name || fallback.first_name
        this.member.last_name = this.member.last_name || fallback.last_name
        this.member.email = this.member.email || fallback.email
        this.member.mobile = this.member.mobile || fallback.mobile
        this.member.avatar_url = this.member.avatar_url || fallback.avatar_url
      }
      return
    }
    try {
      this.getLoyaltyBenefits(restaurant.id);
      let member = await memberService.show(isAdminBooking);
      if (member.avatar) {
        // 判断是否更新用户头像
        member.avatar_url = member.avatar.url
      }
      this.member = member;
    } catch (error) {
      console.error(error)
      this.member = fallback || {}
    }
    try {
      window.private_token = query["access-token"] || private_token || this.member.private_token
      this.getLoyaltyBenefits(restaurant.id, this.member.id);
    } catch (error) { }
  }

  /**
   * 修改用户信息
   *
   * @memberof MemberStore
   */
  @action
  async update(form) {
    let data = await memberService.update(form);
    this.member = Object.assign({}, this.member, data);
  }

  /**
   * 根据id获取福利数据
   *
   * @param {*} id
   * @returns
   * @memberof MemberStore
   */
  @action
  async getBenefit(id) {
    return this.benefits.find((item) => item.id == id) || {};
  }
  /**
   * 获取游客信息（用于预订，运通需求）
   */
  async getGuestInfo() {
    const { sign, timestamp, cbin, amex_code } = getQuery();
    if (!amex_code) return {};
    try {
      const res = await http.get("/amex/valid", { params: { sign, timestamp, cbin, amex_code } })
      return res.data
    } catch (error) {
      console.error(error)
      return {};
    }
  }
  /**
   * 获取用户福利
   *
   * @returns
   * @memberof Service
   */
  @action
  async getLoyaltyBenefits(restaurantId, memberId = 'no_member') {
    // 存在deal_id, 不请求福利
    if (getQuery().deal_id) return;
    if (benefitsCache[memberId]) return;
    let url = `/restaurants/${restaurantId}/loyalty_program_benefits/optional`;
    try {
      let { data } = await http.get(url, {
        params: {
          member_id: memberId,
          project: getQuery().project || "diningcity",
        },
      })
      // 是否携带memberId做为请缓存key
      benefitsCache[memberId] = true
      this.benefits = data || [];
    } catch (error) {
    }
  }
}

export default new MemberStore();
