import http from '../../service/http'

export const BookingAPI = {
    getDetail(reservation_code, reservation_id) {
        return http.get('/books/' + reservation_id, {
            params: {
                reservation_code
            }
        }).then(res => {
            if (res.status === 200) {
                return res.data
            }
            return null
        })
    },

}