import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { useStores } from "@/store";
import { ListItem } from "../common";
import "./index.less";

/**
 * 押金
 *
 * @param {*} props
 */
export const Deposit = (props) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  return useObserver(() => {
    const {
      paymentInfo: { currency, amount, amount_type },
    } = restaurantStore;
    const price = `${currency}${Number(props.amount || amount).toFixed(2)}`;
    return amount_type === "deposit" ? (
      <ListItem
        title={props.title || t("PAYMENT.Deposit required")}
        value={price}
      />
    ) : null;
  });
};

/**
 * 押金提示
 *
 * @param {*} props
 */
export const DepositTip = (props) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  return useObserver(() => {
    const {
      paymentInfo: { currency, amount, amount_type },
    } = restaurantStore;
    const avgPrice = `${currency}${(
      Number(amount) / Number(props.seats)
    ).toFixed(1)}`;
    return amount_type === "deposit" ? (
      <div>
        {t("PAYMENT.deposit_tip", {
          amount_per_seat: avgPrice,
        })}
      </div>
    ) : null;
  });
};
