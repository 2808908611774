import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ms from "./my.json";
import hi from "./hi.json";
import id from "./id.json";
import ja from "./ja.json";
import ko from "./ko.json";
import tl from "./tl.json";
import th from "./th.json";
import vi from "./vi.json";
import zhCN from "./zh-CN.json";
import zhTW from "./zh-TW.json";
import { lang } from "../service/http";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ms: { translation: ms },
    hi: { translation: hi },
    id: { translation: id },
    ja: { translation: ja },
    ko: { translation: ko },
    tl: { translation: tl },
    th: { translation: th },
    vi: { translation: vi },
    zh: { translation: zhCN },
    "zh-HK": { translation: zhTW },
  },
  lng: lang,
  fallbackLng: "en",
});

export default i18n;
