import React, { useMemo, useEffect, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { Row, Col } from "antd";
import * as Common from "@/components/common";
import { useStores } from "@/store";
import { PreAuthorization } from "@/pages/confirm/components/master-card";
import { DepositTip } from "@/components/dc-deposit";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import classNames from "classnames";
import qs from "qs";
import { getQuery, urlBuilder } from "@/service/http";
import "./index.less";

loadStripe.setLoadParameters({ advancedFraudSignals: false });
/**
 *
 * 选择支付方式
 *
 * 设置了MPGS预授权付款方式后，我们将允许用户进行预授权并将万事达卡添加到其DiningCity帐户中
 *
 */
export const PaymentSelector = (props) => {
  const { t } = useTranslation();
  const { paymentStore } = useStores();
  const { projectInfo = {}, amountType } = props;
  const { private_token } = getQuery();
  /** 是否为游客模式 */
  const isGuest = useMemo(() => {
    return (
      projectInfo?.authentication_method === "no_membership" && !private_token
    );
  }, [projectInfo]);
  return useObserver(() => {
    const { paymentMethods = [] } = paymentStore;
    const hasMpgs = useMemo(() => {
      return paymentMethods.findIndex((v) => v.provider === "mpgs") > -1;
    }, [paymentMethods]);
    // 是否存在 stripe visa 支付
    const hasStripe = useMemo(
      () => paymentMethods.findIndex((v) => v.provider === "stripe") > -1,
      [paymentMethods]
    );
    const hasAmex = useMemo(
      () => paymentMethods.findIndex((v) => v.provider === "amex") > -1,
      [paymentMethods]
    );
    const pre_authorized = amountType === "pre_authorized";
    return (
      <React.Fragment>
        <Common.ListItem
          className={props.errtip ? "has-error" : ""}
          onClick={() => {
            // 如果不需要支付
            if(props.disabled) return;
            // 如果是游客，不允许选择卡片，使用stripe 组件填写卡片信息支付
            if (hasStripe && isGuest) {
              console.warn(
                "如果是游客，不允许选择卡片，使用stripe 组件填写卡片信息支付"
              );
              return;
            }
            // 如果有运通收银台，不允许选择支付方式
            if (hasAmex) {
              console.warn("如果有运通收银台，不允许选择支付方式");
              return;
            }
            if (hasMpgs || hasStripe) {
              // Stripe支付 只支持一种支付方式，不允许选择支付方式
              console.warn("Stripe支付 只支持一种支付方式，不允许选择支付方式");
              props.setCardVisible && props.setCardVisible();
            } else {
              console.warn("打开支付方式选择");
              props.setPaymentVisible();
            }
          }}
          title={
            <Common.Icon
              text={t("PAYMENT.Payment method")}
              icon="icon icon-payment-method"
            ></Common.Icon>
          }
          value={
            <PayMethod
              isGuest={isGuest}
              paymentInfo={props.paymentInfo}
              amountType={amountType}
              payment={props.payment}
              mastercardNumber={props.mastercardNumber}
            />
          }
        ></Common.ListItem>
        {pre_authorized && <PreAuthorization />}
      </React.Fragment>
    );
  });
};

const PayMethod = ({
  payment,
  mastercardNumber,
  paymentInfo,
  amountType,
  isGuest,
}) => {
  const { paymentStore, restaurantStore } = useStores();
  const { paymentMethods } = paymentStore;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const handlePrePay = (e) => {
    e.stopPropagation();
    setVisible(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const hideSelect = paymentMethods.length === 1;
  const hasMpgs = useMemo(
    () => paymentMethods.findIndex((v) => v.provider === "mpgs") > -1,
    [paymentMethods]
  );
  // 是否存在 stripe 支付
  const hasStripe = useMemo(
    () => paymentMethods.findIndex((v) => v.provider === "stripe") > -1,
    [paymentMethods]
  );
  /** 支付方式详情,支付方式详情是根据keyword查找 */
  const paymentMethodDetail = useMemo(
    () => paymentMethods.find((v) => v.keyword === payment),
    [payment, paymentMethods]
  );
  const PaymentName = () => {
    return (
      <div
        className={classNames("list-item__tag", {
          "list-item__tag--active":
            !payment || (payment && hasStripe && !mastercardNumber && !isGuest),
          "list-item__tag--info": (payment && !hasStripe) || mastercardNumber,
        })}
      >
        {(() => {
          if (payment) {
            if (hasMpgs) {
              // mpgs支付
              return <span>{mastercardNumber}</span>;
            } else if (hasStripe && !isGuest) {
              // stripe 支付
              return mastercardNumber ? (
                <span>{mastercardNumber}</span>
              ) : (
                <span style={{ color: "#fff", fontWeight: 500 }}>
                  {t("PAYMENT.Please select")}&nbsp;
                </span>
              );
            } else if (hasStripe && isGuest) {
              // 商家端 stripe 支付
              return (
                <span>
                  <img
                    style={{ height: 24 }}
                    src={paymentMethodDetail?.icon}
                    alt={paymentMethodDetail?.name}
                  />
                </span>
              );
            } else {
              return <span>{paymentMethodDetail?.name}&nbsp;</span>;
            }
          } else {
            return (
              <span style={{ color: "#fff", fontWeight: 500 }}>
                {t("PAYMENT.Please select")}&nbsp;
              </span>
            );
          }
        })()}
        <RightOutlined
          style={{ fontSize: 12 }}
          hidden={
            (hideSelect && payment && (!hasMpgs || !hasStripe)) ||
            (hasStripe && isGuest)
          }
        />
      </div>
    );
  };
  return useObserver(() => {
    return (
      <div>
        <PaymentName />
        {amountType ? (
          <React.Fragment>
            <div
              onClick={handlePrePay}
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {amountType === "pre_pay" ? (
                <span>{t("PAYMENT.Pre-payment")}</span>
              ) : amountType === "deposit" ? (
                <span>{t("PAYMENT.Deposit required")}</span>
              ) : null}
              {(amountType === "pre_pay" || amountType === "deposit") && (
                <QuestionCircleOutlined
                  style={{ marginLeft: "8px", fontSize: 20 }}
                />
              )}
            </div>
            <Modal
              wrapClassName="pre-desc-dialog"
              title={
                amountType === "pre_pay" ? (
                  <h4 className="">{t("PAYMENT.Pre-payment")}</h4>
                ) : amountType === "deposit" ? (
                  <h4 className="">{t("PAYMENT.Deposit required")}</h4>
                ) : null
              }
              centered={true}
              visible={visible}
              cancelButtonProps={null}
              footer={
                <div
                  onClick={handleClose}
                  style={{
                    color: "#1C1C1E",
                    fontSize: 18,
                    fontWeight: "bold",
                    lineHeight: "24px",
                  }}
                >
                  {t("VOUCHERS.Close")}
                </div>
              }
              closable={false}
              okText={t("VOUCHERS.Close")}
            >
              {amountType === "pre_pay" ? (
                <p>{t("PAYMENT.pre_payment_desc")}</p>
              ) : amountType === "deposit" ? (
                <DepositTip seats={paymentInfo.item_count} />
              ) : null}
            </Modal>
          </React.Fragment>
        ) : null}
      </div>
    );
  });
};

/**
 * 选择支付方式
 *
 * @param {*} props
 * @returns
 */
export const PaymentPopup = (props) => {
  const { t } = useTranslation();
  const { paymentStore } = useStores();
  useEffect(() => {
    // 记录滚动条位置
    let dom = document.querySelector(".page-main");
    let old = dom.scrollTop;
    dom.scrollTo(0, 0);
    // 还原位置
    return () => {
      dom.scrollTo(0, old);
    };
  }, []);
  return useObserver(() => {
    let { paymentMethods } = paymentStore;
    return (
      <div className="page-popup page-animate-popup">
        <header>
          <div className="header-left" onClick={() => props.onBack(props.data)}>
            <i className="icon icon-arrow-left"></i>
          </div>
          <h2>{t("PAYMENT.Payment method")}</h2>
          <div className="header-right"></div>
        </header>
        <div className="page-popup--body">
          <div className="payments">
            <Row gutter={10} justify={"start"}>
              {paymentMethods.map((item) => {
                let icon = item.icon
                  ? { backgroundImage: `url(${item.icon})` }
                  : null;
                return (
                  <Col span={12} key={item.keyword}>
                    <div
                      onClick={() => props.onBack(item.keyword)}
                      className="payment"
                      data-method={item.keyword}
                      style={icon}
                    ></div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    );
  });
};

/**确定支付按钮 */
export const PaymentAction = ({
  left,
  disabled,
  onSubmit,
  loading,
  hidden,
  formatAmount,
  formatOriginAmount,
}) => {
  const { t } = useTranslation();
  const style = useMemo(() => {
    return String(formatAmount).length > 8
      ? { fontSize: 22, marginRight: 10 }
      : null;
  }, [formatAmount]);

  const showTwo = formatAmount !== formatOriginAmount;
  return (
    <footer className="page-footer prepay-action" hidden={hidden}>
      <div className="page-footer--left">{/* {left} */}</div>
      <div className="page-footer--right">
        {showTwo ? (
          <div style={{ textAlign: "right" }}>
            <strong
              className="price"
              style={{
                ...style,
                color: "#dc2224",
              }}
            >
              {formatAmount}
            </strong>
            <strong style={{ fontSize: 14, display: "block", marginTop: -5 }}>
              <span>{t("Original")}:</span>
              <span className="line-through">{formatOriginAmount}</span>
            </strong>
          </div>
        ) : (
          <strong className="price" style={style}>
            {formatAmount}
          </strong>
        )}
        <Common.Button
          disabled={disabled}
          loading={loading}
          type="primary"
          onClick={onSubmit}
        >
          {t("PAYMENT.Pay & book")}
        </Common.Button>
      </div>
    </footer>
  );
};
/** stripe支付Form */
export const StripeCheckoutForm = ({
  clientSecret,
  channel,
  setSubmitLoading,
  onVisibleChange,
}) => {
  const { paymentStore, restaurantStore } = useStores();
  const { restaurant } = restaurantStore;
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [isComleted, setIsComleted] = useState(false);
  /** 支持的卡片 */
  const allowedCardBrands = useMemo(() => {
    return paymentStore.bankCardInfos.map((v) => v.bank_brand);
  }, [paymentStore.bankCardInfos]);

  /** PaymentElement 配置 */
  const paymentElementOptions = {
    // layout: "tabs",
    allowedCardBrands,
    defaultValues: {
      billingDetails: {
        address: {
          country: restaurant?.region?.iso_code,
        },
      },
    },
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          console.info("Payment succeeded!");
          break;
        case "processing":
          console.info("Your payment is processing.");
          break;
        case "requires_payment_method":
          console.warn("Your payment was not successful, please try again.");
          break;
        default:
          console.error("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  /** 拼接支付成功url */
  const getSuccessfulUrl = () => {
    const reservation_code =
      restaurantStore.bookingResult?.book?.reservation_code ||
      restaurantStore.reservation?.reservation_code;
    const reservation_id =
      restaurantStore.bookingResult?.book?.id ||
      restaurantStore.reservation?.id;
    const query = qs.stringify({
      ...getQuery(),
      reservation_code,
      reservation_id,
      channel: channel,
    });
    return `${location.origin}${location.pathname}#/restaurants/${restaurantStore.restaurant.id}/reservation/complete?${query}`;
  };
  const handlePayFail = () => {
    const reservation_code =
      restaurantStore.bookingResult?.book?.reservation_code ||
      restaurantStore.reservation.reservation_code;
    const reservation_id =
      restaurantStore.bookingResult?.book?.id || restaurantStore.reservation.id;
    // 如果需要支付，重定向页面到支付页面
    const domain = document.location.href.split("#")[0];
    const base =
      domain + `#/restaurants/${restaurantStore.restaurant.id}/reservation`;
    let callback = urlBuilder(reservation_code, reservation_id);
    const query = qs.stringify({
      reservation_code: reservation_code,
      reservation_id: reservation_id,
      channel: channel,
      success_url: callback,
      project: getQuery().project,
      program: getQuery().program,
      deal_id: getQuery().deal_id,
      lang: getQuery().lang,
    });
    localStorage.setItem("AUTO_PAY", "false");
    window.top.location.href = `${base}/pay?${query}`;
  };
  /** 提交 */
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      console.warn("stripe or element has not yet load");
      return;
    }
    if (!isComleted) {
      console.warn("your input is not completed");
      return;
    }
    setIsLoading(true);
    // 获取 paymentMethods
    const {
      error: createErr,
      paymentMethod,
    } = await stripe.createPaymentMethod({
      elements,
      params: {},
    });
    if (createErr) {
      console.error(createErr);
      return;
    }
    // 验证 paymentMethod
    const { status } = await paymentStore.validStripeCard(paymentMethod);
    if (!status) {
      return;
    }
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: getSuccessfulUrl(),
        },
        redirect: "if_required",
      });

      // 确认支付结果
      if (error) {
        throw error;
      }
      if (
        paymentIntent?.status === "succeeded" ||
        paymentIntent?.status === "requires_capture"
      ) {
        console.log("pay success");
        // 更新预订信息
        const reservation_code =
          restaurantStore.bookingResult?.book?.reservation_code ||
          restaurantStore.reservation?.reservation_code;
        const reservation_id =
          restaurantStore.bookingResult?.book?.id ||
          restaurantStore.reservation?.id;

        // 支付状态更新，为异步操作，延迟跳转, 最大重试十次
        let timer = null;
        let count = 0;
        const helper = async () => {
          // 最大重试十次
          if (count > 10) {
            clearTimeout(timer);
            setSubmitLoading(false);
            return location.assign(getSuccessfulUrl());
          }
          const res = await restaurantStore.getBooking(
            reservation_code,
            reservation_id
          );
          if (res.status === "need_pay") {
            count++;
            timer = setTimeout(helper, 400);
          } else {
            setSubmitLoading(false);
            location.assign(getSuccessfulUrl());
          }
        };
        onVisibleChange(false);
        setSubmitLoading(true);
        helper();
      } else {
        console.error(paymentIntent);
      }
    } catch (err) {
      console.error("支付失败", err);
      message.error(err.message);
      handlePayFail();
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Form ayout="vertical" className="stripe-form" onFinish={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onChange={(event) => {
          setIsComleted(event.complete);
        }}
        options={paymentElementOptions}
      />
      <Button
        type="primary"
        style={{ marginTop: 20 }}
        loading={isLoading}
        htmlType="submit"
        block
      >
        Pay
      </Button>
    </Form>
  );
};

/**
 * Stripe 支付弹窗,适用于guest无卡支付
 * @returns
 */
export const StripePayModal = ({
  visible,
  channel,
  formatAmount,
  stripePublicKey,
  clientSecret,
  amount,
  currency,
  setSubmitLoading,
  onVisibleChange,
}) => {
  const [tips, setTips] = useState([]);
  const { i18n } = useTranslation();
  const stripePromise = loadStripe(stripePublicKey, {
    betas: [
      "elements_enable_deferred_intent_beta_1",
      "blocked_card_brands_beta_1",
    ],
  });
  const options = {
    mode: "payment",
    currency,
    appearance: {
      theme: "stripe",
    },
    locale: i18n.language,
    amount,
    style: {
      base: {
        colorText: "rgba(0, 0, 0, 0.65)",
      },
    },
    paymentMethodTypes: ["card"],
  };
  // if (clientSecret) {
  //   options.clientSecret = clientSecret
  // }
  return (
    <Modal
      visible={visible}
      width="100vw"
      wrapClassName="stripe-pay-modal"
      footer={null}
      closable={false}
    >
      <section className="pay-info">
        <h4 className="title">Subtotal</h4>
        <div className="amount">{formatAmount}</div>
      </section>
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckoutForm
          clientSecret={clientSecret}
          channel={channel}
          setSubmitLoading={setSubmitLoading}
          onVisibleChange={onVisibleChange}
        />
      </Elements>
    </Modal>
  );
};
