import React, { useState, useEffect, useMemo, useCallback } from "react";

const ListItem = ({ icon, label, value, children, show = true }) => {
  if (!show) return null;
  return (
    <div className="dc-list-item">
      <div className="dc-list-item--label">
        <i className={`icon icon-${icon}`}></i>
        <span>{label}</span>
      </div>
      <div className="dc-list-item--value">{children || value}</div>
    </div>
  );
};

export default ListItem;
