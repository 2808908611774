import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { Modal, Input, message, Form, Drawer } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import * as Common from "@/components/common";
import { useStores } from "@/store";
import "./index.less";
import { lang } from "@/service/http";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { getQuery } from "@/service/http";
import qs from "qs";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

/**
 *
 * Pre-authorization
 *
 * 点击查看详情
 *  */
export const PreAuthorization = ({ type }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  return (
    <section style={{ marginTop: -10 }}>
      <Common.ListItem
        value={
          <span onClick={() => setVisible(true)}>
            {t("MASTER_CARD.Pre-authorization")}
            <QuestionCircleOutlined
              style={{
                fontSize: 20,
                marginLeft: 5,
                verticalAlign: -5,
                color: "#5d5d5d",
              }}
            />
          </span>
        }
      ></Common.ListItem>
      <PreAuthorizationModal
        visible={visible}
        setVisible={setVisible}
        type={type}
      />
    </section>
  );
};

/**管理卡片 */
const CardsManage = ({ tips, cards, onCreate, onSelect, onRemove }) => {
  const { t } = useTranslation();
  const { masterCardStore, paymentStore } = useStores();
  const { paymentMethods = [] } = paymentStore;
  const [isManage, setManage] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentId, setCurrentId] = useState("");
  /** 是否存在万事达支付 */
  const hasMpgs = useMemo(() => {
    return paymentMethods.findIndex((v) => v.provider === "mpgs") > -1;
  }, [paymentMethods]);
  function handleRemove(ev, id) {
    ev.stopPropagation();
    setVisible(true);
    setCurrentId(id);
  }
  function onConfirm() {
    setVisible(false);
    masterCardStore
      .remove(currentId)
      .then((data) => {
        const card = cards.find((v) => v.id === currentId);
        /** 删除成功 */
        onRemove && onRemove(card?.uuid);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function onClick(data) {
    if (isManage) return;
    onSelect(data);
  }
  return (
    <div className="master-cards--manage">
      <CardRemoveModal
        visible={visible}
        setVisible={setVisible}
        onConfirm={onConfirm}
      />
      <div className="master-cards--manage-title">
        {isManage ? (
          <span onClick={() => setManage(false)}>{t("MASTER_CARD.Done")}</span>
        ) : (
          <React.Fragment>
            <span onClick={() => setManage(true)}>
              {t("MASTER_CARD.Manage")}
            </span>
            <i onClick={() => setManage(true)} className="icon icon-manage"></i>
          </React.Fragment>
        )}
      </div>
      {cards.map((item, index) => {
        return (
          <CardItem
            card={item}
            isManage={isManage}
            action={
              isManage ? (
                <span onClick={(ev) => handleRemove(ev, item.id)}>
                  <Common.Icon
                    icon="icon icon-remove"
                    text={t("MASTER_CARD.Remove")}
                  />
                </span>
              ) : null
            }
            key={index}
            title={item.short_number}
            icon={item.info.icon_url}
            onClick={() => onClick(item)}
          />
        );
      })}
      {isManage ? null : (
        <CardItem title={t("MASTER_CARD.Add new card")} onClick={onCreate} />
      )}
      <Common.Help>
        {hasMpgs && (
          <div>{t("MASTER_CARD.Only mastercard accepted in this program")}</div>
        )}
        <div>
          {t("MASTER_CARD.The added card can only be used in this program")}
        </div>
      </Common.Help>
      <CardCellGroup tips={tips} />
    </div>
  );
};

export const CardCellGroup = ({ tips = [] }) => {
  return (
    <ul className="assignments-cell-group">
      {tips.map((item, index) => {
        return (
          <li key={index} className="assignments-cell">
            <i
              className="assignments-cell__icon"
              style={{
                backgroundImage: `url(${item.voucher_batch_assignment.icon.url})`,
              }}
            />
            <p className="assignments-cell__value">
              {item.voucher_batch_assignment.name}
            </p>
          </li>
        );
      })}
    </ul>
  );
};

/**确认弹窗 */
export const PreAuthorizationModal = ({
  visible,
  setVisible,
  type = "normal",
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal pre-authorization-modal"
      width={280}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      {/* 展示预定条款 */}
      <div className="confirm-content">
        <header>{t("MASTER_CARD.Pre-authorization")}</header>
        {type === "refund" && (
          <div className="modal-content">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Pre_authorization_desc2"),
              }}
            ></p>
          </div>
        )}
        {type === "normal" && (
          <>
            <div className="modal-content">
              <p>{t("MASTER_CARD.Pre_authorization_desc")}</p>
            </div>
            <header style={{ paddingTop: 0, paddingBottom: 14 }}>
              {t("MASTER_CARD.No show policy")}
            </header>
            <div className="modal-content">
              <p>{t("MASTER_CARD.No show policy desc")}</p>
              <p>{t("MASTER_CARD.No show policy desc2")}</p>
            </div>
          </>
        )}

        <footer>
          <span></span>
          <Common.Button onClick={() => setVisible(false)}>
            {t("VOUCHERS.Close")}
          </Common.Button>
        </footer>
      </div>
    </Modal>
  );
};

/**确认删除 */
const CardRemoveModal = ({ visible, setVisible, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal card-remove-modal"
      width={280}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <div className="confirm-content">
        <div className="modal-content">
          <p>
            {t(
              "MASTER_CARD.This will permanently remove the card from your account"
            )}
          </p>
          <p>{t("MASTER_CARD.Would you like to continue?")}</p>
        </div>
        <footer>
          <Common.Button onClick={() => setVisible(false)}>
            {t("MASTER_CARD.Back")}
          </Common.Button>
          <Common.Button onClick={onConfirm} type="primary">
            {t("MASTER_CARD.Remove")}
          </Common.Button>
        </footer>
      </div>
    </Modal>
  );
};

/**
 * 卡片
 *
 * @param {*} props
 * @returns
 */
export const CardsPopup = (props) => {
  const { onRemove } = props;
  const { t } = useTranslation();
  const { masterCardStore, voucherStore, paymentStore } = useStores();
  const { paymentMethods = [] } = paymentStore;
  const [cardVisible, setCardVisible] = useState(false);
  const [tips, setTips] = useState([]);
  /** 是否存在万事达支付 */
  const hasMpgs = useMemo(() => {
    return paymentMethods.findIndex((v) => v.provider === "mpgs") > -1;
  }, [paymentMethods]);
  /** 是否存在stripe支付 */
  const hasStripe = useMemo(() => {
    return paymentMethods.findIndex((v) => v.provider === "stripe") > -1;
  }, [paymentMethods]);
  useEffect(() => {
    voucherStore.batchAssignment().then((data) => {
      setTips(data);
    });
    masterCardStore.getList();
  }, []);
  /**开始添加卡片 */
  function onCreate() {
    setCardVisible(true);
  }
  return useObserver(() => {
    let { cards } = masterCardStore;
    let isEmpty = cards.length === 0;
    return (
      <React.Fragment>
        {cardVisible && hasMpgs ? (
          <CardsCreatePopup onBack={() => setCardVisible(false)} />
        ) : hasStripe ? (
          <StripeCardCreatePopup
            visible={cardVisible}
            channel={props.channel}
            onBack={() => setCardVisible(false)}
          />
        ) : null}

        <div
          className="page-popup page-animate-popup"
          style={{
            display: cardVisible ? "none" : "block",
            pointerEvents: cardVisible ? "none" : "all",
          }}
        >
          <header>
            <div className="header-left" onClick={() => props.onBack(null)}>
              <i className="icon icon-arrow-left"></i>
            </div>
            <h2>{t("MASTER_CARD.SELECT CARD")}</h2>
            <div className="header-right"></div>
          </header>
          <div className="page-popup--body">
            <div className="master-cards">
              {isEmpty ? (
                <CardsEmpty onCreate={onCreate} tips={tips} />
              ) : (
                <CardsManage
                  tips={tips}
                  cards={cards}
                  onCreate={onCreate}
                  onRemove={onRemove}
                  onSelect={props.onBack}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
};

/**
 * 添加卡片
 *
 * @param {*} props
 * @returns
 */
export const CardsCreatePopup = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [timer, setTimer] = useState(0);
  const { masterCardStore } = useStores();

  useEffect(() => {
    masterCardStore.configure().then((status) => {
      setDisabled(false);
      return console.log("configure success");
    });
  }, [masterCardStore]);
  function onSubmit() {
    setLoading(true);
    window.PaymentSession.validate("card", ({ card }) => {
      if (card.isValid) {
        setErrMsg("");
        // 10s关闭弹窗
        const timerId = setTimeout(() => {
          console.log(visible, loading, "timeout");
          const msg =
            lang === "zh-HK"
              ? "綁卡失敗，超時請重新嘗試"
              : "Adding card timeout! Please try again.";
          setErrMsg(msg);
          setLoading(false);
          setVisible(false);
        }, 60000);
        setTimer(timerId);
        return setVisible(true);
      }

      setLoading(false);
      setErrMsg(masterCardStore.formatError(card));
      // message.error(masterCardStore.formatError(card));
    });
  }
  /**
   * 关闭弹窗
   */
  function closeDialog(flag) {
    clearTimeout(timer);
    setVisible(flag);
  }
  return (
    <div className="page-popup page-animate-popup">
      <RedirectHTML
        visible={visible}
        setLoading={setLoading}
        setVisible={closeDialog}
        setErrMsg={setErrMsg}
        onBack={props.onBack}
      />
      <header>
        <div className="header-left" onClick={props.onBack}>
          <i className="icon icon-arrow-left"></i>
        </div>
        <h2>{t("MASTER_CARD.ADD CARD")}</h2>
        <div className="header-right"></div>
      </header>
      <div className="page-popup--body" id="master-card-popup">
        <div className="master-cards">
          <div className="master-cards--create">
            <span
              className="ant-input-affix-wrapper"
              style={{
                padding: "0 10px",
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 32,
                overflow: "hidden",
              }}
            >
              <span
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i className="icon icon-payment-method" data-card></i>
                <span style={{ width: 200, paddingLeft: 10 }}>
                  <Input
                    id="j_master_card_number"
                    maxLength={30}
                    placeholder={t("MASTER_CARD.Card number")}
                    readOnly
                    style={{
                      border: 0,
                      marginBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginTop: 4,
                      width: 200,
                      height: 28,
                    }}
                  />
                </span>
              </span>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ width: 64, height: 32 }}>
                  <Input
                    id="j_master_card_expiryMonth"
                    readOnly
                    placeholder={t("MASTER_CARD.MM")}
                    style={{ width: 64, height: 32 }}
                  />
                </span>

                <span style={{ padding: "0 10px", color: "#BBBBBB" }}>/</span>
                <span style={{ width: 64, height: 32 }}>
                  <Input
                    id="j_master_card_expiryYear"
                    readOnly
                    placeholder={t("MASTER_CARD.YY")}
                    style={{ width: 64, height: 32 }}
                  />
                </span>
              </span>
              <span style={{ flex: 1, height: 32 }}>
                <Input
                  id="j_master_card_securityCode"
                  maxLength={4}
                  placeholder={t("MASTER_CARD.CCV")}
                  readOnly
                  style={{ width: "100%", height: 32 }}
                />
              </span>
            </div>
            <div className="master-cards__msg">{errMsg}</div>
            <Common.Help>
              <div>
                {t("MASTER_CARD.Only mastercard accepted in this program")}
              </div>
              <div>
                {t(
                  "MASTER_CARD.The added card can only be used in this program"
                )}
              </div>
            </Common.Help>
            <CardCellGroup />
            <footer>
              <Common.Button
                onClick={onSubmit}
                type="primary"
                disabled={disabled}
                loading={loading}
              >
                {t("SAVE")}
              </Common.Button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * stripe支付新增卡片
 * @param {*} props
 * @returns
 */
export const StripeCardCreatePopup = (props) => {
  const { i18n } = useTranslation();
  const { paymentStore } = useStores();
  const { channel, visible } = props;
  const stripePromise = loadStripe(paymentStore.stripePublicKey, {
    betas: [
      "elements_enable_deferred_intent_beta_1",
      "blocked_card_brands_beta_1",
    ],
  });
  /** 抄demo里面的实例 */

  const ELEMENTS_OPTIONS = {
    mode: "setup",
    fonts: [
      {
        // cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
    locale: i18n.language,
    paymentMethodTypes: ["card"],
  };

  return (
    <Drawer
      visible={visible}
      placement="bottom"
      mask={false}
      height="80vh"
      onClose={props.onBack}
    >
      <div className="payment-drawer">
        <header className="payment-drawer__header">
          <h2>Add your payment information</h2>
        </header>
        <div className="page-popup--body">
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <StripeCreateForm channel={channel} onBack={props.onBack} />
          </Elements>
        </div>
      </div>
    </Drawer>
  );
};
/**创建stripe支付方式 */
const StripeCreateForm = ({ channel, onBack }) => {
  const { t } = useTranslation();
  const {
    masterCardStore,
    restaurantStore,
    paymentStore,
    memberStore,
  } = useStores();
  const { restaurant } = restaurantStore;
  const stripe = useStripe();
  const elements = useElements();
  const [cardErr, setCardErr] = useState();
  const [helpTip, setHelpTip] = useState();
  const [validateStatus, setValidateStatus] = useState();
  const [cardComplete, setCardComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  /** 支持的卡片 */
  const allowedCardBrands = useMemo(() => {
    return paymentStore.bankCardInfos.map((v) => v.bank_brand);
  }, [paymentStore.bankCardInfos]);
  /**
   * 生成重定向url
   * @param {*} param0
   * @returns
   */
  const getRedirectUrl = ({ project, region_id, member_id }) => {
    const { date, time, seats } = restaurantStore.form;
    const [redirectPath, redirectQuery] = location.href.split("?");
    const { private_token } = getQuery();
    const redirect_url =
      redirectPath +
      "?" +
      qs.stringify(
        Object.assign({}, qs.parse(redirectQuery), { date, time, seats })
      );
    const queryStr = qs.stringify({
      project,
      region_id,
      member_id,
      redirect_url: decodeURIComponent(redirect_url),
      private_token,
      channel,
    });

    return `${location.origin}${location.pathname}?${queryStr}`;
  };
  /**
   *
   * @returns 保存卡片
   */
  const handleSubmit = async () => {
    if (loading) return;
    if (!stripe || !elements || !cardComplete) {
      return;
    }
    setLoading(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {},
      });
      if (error) {
        console.error(error);
      } else {
        const { project } = getQuery();
        await masterCardStore.bind({
          region_id: restaurant.region?.id,
          project: project,
          member_id: memberStore.member.id,
          channel: channel,
          payment_method_id: paymentMethod.id,
        });
        // 绑卡成功
        onBack && onBack();
      }
    } catch (err) {
      if (err.data.full_messages || typeof err.data === "string") {
        setHelpTip(err.data?.full_messages?.[0] || err.data || "");
        setValidateStatus("error");
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item help={helpTip} validateStatus={validateStatus}>
        <PaymentElement
          options={{
            allowedCardBrands,
            defaultValues: {
              billingDetails: {
                address: {
                  country: restaurant?.region?.iso_code,
                },
              },
            },
          }}
          onChange={(e) => {
            setCardErr(e.error);
            setHelpTip(false);
            setCardComplete(e.complete);
          }}
        />
      </Form.Item>
      {/* <div id="payment-element"></div> */}
      <footer style={{ textAlign: "center" }}>
        <Common.Button
          onClick={() => formRef.current?.submit()}
          htmlType="submit"
          type="primary"
          style={{ width: "100%" }}
          block
          disabled={!cardComplete}
          loading={loading}
        >
          {t("Confirm")}
        </Common.Button>
      </footer>
    </Form>
  );
};
const CardsEmpty = (props) => {
  const { t } = useTranslation();
  return (
    <div className="master-cards--empty">
      {t("MASTER_CARD.available_to_pay")}
      <CardItem
        title={t("MASTER_CARD.Add new card")}
        onClick={props.onCreate}
      />
      <CardCellGroup tips={props.tips} />
    </div>
  );
};

const CardItem = (props) => {
  return (
    <div
      className="master-cards--item"
      data-manage={props.isManage}
      onClick={props.onClick}
    >
      <div className="master-cards--item-left">
        {props.icon ? (
          <img
            src={props.icon}
            className="icon"
            style={{ objectFit: "scale-down" }}
          />
        ) : (
          <i className="icon icon-payment-method"></i>
        )}
        <span>{props.title}</span>
      </div>
      <div className="master-cards--item-right">{props.action}</div>
    </div>
  );
};

const RedirectHTML = (props) => {
  return (
    <Modal
      visible={props.visible}
      closable={false}
      footer={null}
      destroyOnClose={true}
      getContainer={document.getElementById("master-card-popup")}
    >
      {props.visible && <HTML {...props} />}
    </Modal>
  );
};

const HTML = ({ setVisible, setErrMsg, setLoading, onBack }) => {
  const ref = useRef();
  const { masterCardStore } = useStores();
  const [loading, showLoading] = useState(true);
  useEffect(() => {
    const style = `<style>body{margin: 0}iframe{border-width: 0;}<\/style>`;
    let redirectHtml = "";
    let target = ref.current.contentDocument;
    window.target = target;
    function writeHTML() {
      masterCardStore
        .create()
        .then((html) => {
          redirectHtml = style + html;
          target.write(redirectHtml);
          showLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
    writeHTML();
    window.onstorage = (ev) => {
      if (ev.key === "message") {
        let messageData = JSON.parse(localStorage.getItem("message"));
        localStorage.removeItem("message");
        masterCardStore
          .bind({ order_id: messageData["order.id"] })
          .then((data) => {
            console.error("bind data", data);
            setLoading(false);
            if (!data || data.result === "ERROR") {
              // message.error(data.error.explanation);
              setErrMsg(data.error.explanation);
              console.log(data.error.explanation);
              // 失败，再次写入html
              // writeHTML();
              setVisible(false);
              // onBack();
            } else {
              console.error("添加卡片成功");
              setVisible(false);
              onBack();
            }
          })
          .catch((error) => {
            if (error.number) {
              setErrMsg(masterCardStore.formatError(error));
              setVisible(false);
            }
            console.error(error);
            setLoading(false);
          });
      }
    };
  }, [masterCardStore, onBack, setErrMsg, setLoading, setVisible]);
  return (
    <div>
      <div hidden={!loading} style={{ textAlign: "center", padding: "50px 0" }}>
        <Common.Loading red loop></Common.Loading>
      </div>
      <iframe
        ref={ref}
        frameBorder="0"
        style={{
          width: "100%",
          height: loading ? "0" : "400px",
          opacity: 1,
          display: "block",
        }}
      ></iframe>
    </div>
  );
};
