import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { useStores } from "@/store";
import "./index.less";

/**
 * 儿童政策
 *
 */
export default function KidsPolicy(props) {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  if (props.hidden) return null;
  return useObserver(() => {
    return restaurantStore.bookingConditions.allow_kids ? (
      <div className="kids-policy">
        <div className="kids-policy-label">
          {props.showIcon && <i className="icon icon-info-selected"></i>}
          <strong>{t("Kids policy")}</strong>
        </div>
        <article
          className="kids-policy-desc"
          dangerouslySetInnerHTML={{
            __html: restaurantStore.bookingConditions.kids_policy,
          }}
        ></article>
      </div>
    ) : null;
  });
}
