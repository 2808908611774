import React from "react";

const PosPayHead = (props) => {
  return (
    <div className="pos-pay-head">
      <span>{props.title}</span>
      <span className={props.payMethod === "amexweb" ? "amex-logo" : ""}></span>
    </div>
  );
};

export default PosPayHead;
