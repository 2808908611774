import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useStores } from "@/store";
import * as Common from "@/components/common";
import http, { lang } from "@/service/http";
import { Deposit } from "@/components/dc-deposit";
import DcVoucher from "@/components/dc-voucher";
import KidsPolicy from "@/components/dc-kids-policy";

/**
 * 订单基本信息
 *
 * @param {*} props
 */
const BookInfo = ({ needPay }) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const { action } = useParams();
  return useObserver(() => {
    let {
      bookingInfo,
      projectInfo,
      reservation,
      paymentInfo,
    } = restaurantStore;
    let {
      deal,
      group_booking,
      formated_date,
      reservation_date,
      formated_time,
      reservation_time,
      reservation_seats,
      reservation_code,
      children_seats,
      discount,
      reservation_comment = "",
      loyalty_program_benefits = [],
      payment,
      genre,
    } =
      (["complete", "fail", "cancel"].includes(action)
        ? reservation
        : bookingInfo) || {};

    let date =
      formated_date ||
      moment(reservation_date).format(
        lang.startsWith("zh") ? "dddd, M月D日" : "dddd,MMMM DD"
      );
    payment = payment || {};
    let time = formated_time || moment(reservation_time).format("hh:mm A");
    let remark = reservation_comment || "";
    let event =
      projectInfo && projectInfo.project_type === "event"
        ? projectInfo.title
        : "";
    let dealName = deal ? deal.name : "";
    let { channel_humanize, price, pay_method } = payment || {};
    let showPayment = payment && payment.price;
    let isDeposit = genre === "deposit";
    let depositTitle =
      payment.status !== "pending" ? (
        <Common.Icon text={t("PAYMENT.Deposit")} icon="icon icon-info" />
      ) : (
        <span style={{ fontSize: 15, fontWeight: "bold", color: "#353535" }}>
          {t("PAYMENT.Deposit")}:
        </span>
      );
    let suffix = restaurantStore.currency;
    let vouchers = bookingInfo.vouchers || [];
    let showVoucher = vouchers.length;
    let voucher_codes = vouchers.map((i) => i.code);

    return (
      <div className="book-result__body">
        <h3 className="list-head">{t("Details")}</h3>
        <Common.ListItem
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.DATE")}
              icon="icon icon-booking"
            />
          }
          value={date}
        />
        <Common.ListItem
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.TIME")}
              icon="icon icon-time"
            />
          }
          value={time}
        />
        <Common.ListItem
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.PEOPLE")}
              icon="icon icon-seat"
            />
          }
          value={reservation_seats}
        />
        <Common.ListItem
          hidden={children_seats < 1}
          title={
            <Common.Icon text={t("Kids Included")} icon="icon icon-seat" />
          }
          value={t("Children seats", {
            adult: reservation_seats - children_seats,
            children: children_seats,
          })}
        />
        <Common.ListItem
          hidden={!reservation_code || needPay}
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.CODE")}
              icon="icon icon-info"
            />
          }
          value={reservation_code}
        />
        {/* 如果允许支付，要展示金额，和支付方式 */}
        {dealName && !showPayment && (
          <Common.ListItem
            title={
              <Common.Icon
                text={t("RESERVATION_INFO.DEAL")}
                icon="icon icon-deal"
              />
            }
            value={dealName}
          />
        )}

        {showPayment ? (
          <React.Fragment>
            {isDeposit ? (
              <Deposit title={depositTitle} amount={price} />
            ) : (
              deal && (
                <Common.ListItem
                  title={
                    <Common.Icon
                      text={
                        <React.Fragment>
                          <span
                            style={{
                              display: "inline-block",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                              whiteSpace: "nowrap",
                              marginRight: 3,
                            }}
                          >
                            {deal.name}
                          </span>
                          {paymentInfo.item_count > 0 && (
                            <span> * {paymentInfo.item_count}</span>
                          )}
                        </React.Fragment>
                      }
                      icon="icon icon-deal"
                    ></Common.Icon>
                  }
                  value={
                    <span>
                      {suffix}
                      {Number(price).toLocaleString()}
                    </span>
                  }
                ></Common.ListItem>
              )
            )}

            <Common.ListItem
              title={
                <Common.Icon
                  text={t("PAYMENT.Payment method")}
                  icon="icon icon-payment-method"
                ></Common.Icon>
              }
              value={channel_humanize || pay_method}
            ></Common.ListItem>
          </React.Fragment>
        ) : null}
        {event && (
          <Common.ListItem
            title={
              <Common.Icon
                text={t("RESERVATION_INFO.EVENT")}
                icon="icon icon-event"
              />
            }
            value={event}
          />
        )}

        <Common.ListItem
          hidden={!discount}
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.BS")}
              icon="icon icon-save"
            />
          }
          value={discount}
        />
        <Common.ListItem
          hidden={!loyalty_program_benefits.length}
          title={
            <Common.Icon
              text={t("RESERVATION_INFO.VIP")}
              icon="icon icon-vip"
            />
          }
          value={
            <span>
              {loyalty_program_benefits.map(({ id, title }) => (
                <div key={id}>{title}</div>
              ))}
            </span>
          }
        />
        {/* vouchers */}
        {showVoucher ? (
          <div className="dc-voucher">
            <DcVoucher
              vouchers={vouchers}
              codes={voucher_codes}
              onClick={() => {}}
            />
          </div>
        ) : null}
        <KidsPolicy showIcon={true} hidden={children_seats < 1}></KidsPolicy>
        <React.Fragment>
          <Common.ListItem
            hidden={!remark.length}
            title={
              <Common.Icon
                text={
                  group_booking
                    ? t("GROUP_REMARK")
                    : t("RESERVATION_INFO.Requests")
                }
                icon="icon icon-info selected"
              />
            }
          />
          <article className="requests-text" hidden={!remark.length}>
            {remark}
          </article>
        </React.Fragment>
      </div>
    );
  });
};

export default BookInfo;
