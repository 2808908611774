import http, { getQuery } from "./http";
import { message } from "antd";

let verifyVoucher = {};
// 转化data中的undefined为null
function setNull(data) {
  let newData = {}
  Object.keys(data).forEach((key) => {
    newData[key] = data[key] === undefined ? null : data[key];
  });
  return newData
}
class Service {
  countries = null;
  reservation = null;

  /**
   * 创建预订 -- 团体
   *
   * @returns
   * @memberof Service
   */
  createWithGroup(data, memberToken) {
    return new Promise((resolve, reject) => {
      http
        .post(`/restaurants/${data.restaurant_id}/group_booking`, data, {
          headers: http.dropEmpty({
            "access-token-member": memberToken,
            ...http.getHeaders().headers,
          }),
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("创建团体预订 失败, ", error);
          message.error(error.message);
          reject(error);
        });
    });
  }

  /**
   * 创建预订
   *
   * @returns
   * @memberof Service
   */
  create(data, memberToken) {
    console.log("创建预订", { ...data });
    return new Promise((resolve, reject) => {
      http
        .post(
          `/restaurants/${data.restaurant_id}/booking_2018`,
          setNull(data),
          {
            headers: http.dropEmpty({
              "access-token-member": memberToken,
              ...http.getHeaders().headers,
            }),
          }
        )
        .then(({ data }) => {
          if (!data.status) {
            message.error(data.message, 8);
            window.autoBackCheck(data.message);
            reject(data);
          }
          // 预订完成后，向外部页面发出预订信息
          if(data.book) {
            window.parent.postMessage({
              type: 'create_booking',
              data: {
                reservation_code: data.book.reservation_code,
                reservation_id: data.book.id,
              }
            }, '*')
          }
          resolve(data);
        })
        .catch((error) => {
          message.error(error.message, 8);
          window.autoBackCheck(error.message);
          reject(error);
        });
    });
  }

  /**
   * 获取预订
   *
   * @param {*} reservationCode 预订码
   * @param {*} reservationId 订单id
   * @returns
   * @memberof Service
   */
  show(reservationCode, reservationId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/books/${reservationId}`, {
          params: {
            reservation_code: reservationCode,
          },
        })
        .then(({ data }) => {
          this.reservation = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取预订信息 失败, ", error);
          message.error(error.message);
          reject(error);
        });
    });
  }

  /**
   * 修改预订
   *
   * @param {*} reservation
   * @returns
   * @memberof Service
   */
  update(reservation, memberToken = '') {
    const { 'access-token': access_token } = http.getHeaders().headers;
    return new Promise((resolve, reject) => {
      http
        .post(`/books/${reservation.id}/update_2018`, setNull(reservation), {
          headers: http.dropEmpty({
            "access-token-member": access_token || memberToken,
            ...http.getHeaders().headers,
          }),
        })
        .then(({ data }) => {
          if (data.status === true) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          console.error("修改预订失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 取消预订
   *
   * @param {*} reservationCode 预订码
   * @param {*} reservationId 订单id
   * @returns
   * @memberof Service
   */
  delete(reservationCode, reservationId) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/books/${reservationId}`, {
          params: {
            reservation_code: reservationCode,
          },
        })
        .then(({ data }) => {
          if (data.status) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          console.error("取消预订 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 验证优惠券
   *
   * @param {string} code
   * @returns
   * @memberof Service
   */
  verifyVoucher({ project, code, mobile, restaurant_id, deal_id, date, time, seats, discount, country_code }) {
    return new Promise((resolve, reject) => {
      http
        .get(`/project/${project}/vouchers/${code}/valid`, {
          params: http.dropEmpty({
            mobile,
            restaurant_id,
            deal_id,
            date, time, seats, discount, country_code
          }),
        })
        .then(({ data }) => {
          resolve([null, data]);
        })
        .catch((error) => {
          console.error("验证优惠券 失败, ", error);
          let msg = "";
          try {
            msg = error.data.full_messages[0];
          } catch (error) {
            msg = error.message;
          }
          verifyVoucher[code] = [msg, null];
          resolve([msg, null]);
        });
    });
  }

  /**
   * 获取优惠券
   *
   * @param {number} seats
   * @returns
   * @memberof Service
   */
  getVouchers(project, seats) {
    return new Promise((resolve, reject) => {
      http
        .get(`/project_configs/${project}/voucher_info?seats=${seats}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("获取优惠券 失败, ", error);
          resolve([]);
        });
    });
  }
  /**
   * 输入优惠劵
   * @param {*} id 
   * @returns 
   */
  entryVoucher(id) {
    let { project, program } = getQuery();
    project = project || program || 'diningcity'
    return http
      .post(`${process.env.REACT_APP_API}/member/vouchers/${id}/entry?project=${project}`, {}, {
        headers: http.dropEmpty({
          "access-token-member": window.private_token,
          ...http.getHeaders().headers,
        }),
      })
  }
  /**
   * 获取邀请码
   *
   * @param {string} project
   * @returns
   * @memberof Service
   */
  getInvite(project) {
    return new Promise((resolve, reject) => {
      http
        .get("/access_codes/invitecode", {
          params: {
            project,
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("获取邀请码 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 验证邀请码
   *
   * @param {string} code
   * @returns
   * @memberof Service
   */
  verifyInvite(code, seats) {
    return new Promise((resolve, reject) => {
      http
        .get(`/test/member_by_invite_code?invite_code=${code}`)
        .then(({ data }) => {
          const { nickname } = data
          this.getInviteNotice(code, seats).then(notice => {
            resolve({ nickname, notice });
          }).catch((error) => {
            reject(error);
          })
        })
        .catch((error) => {
          console.error("验证邀请码 失败, ", error);
          reject(error);
        });
    });
  }
  /**
   * 邀请码点数
   *
   * @param {string} invite_code
   * @param {number}} seats
   * @returns
   * @memberof Service
   */
  getInviteNotice(invite_code, seats) {
    return new Promise((resolve, reject) => {
      http
        .get(`/book_notes/award`, {
          params: {
            invite_code,
            seats
          },
          headers: {
            'access-token-member': window.private_token
          }
        })
        .then(({ data }) => {
          resolve(data.content);
        })
        .catch((error) => {
          console.error("邀请码点数 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 发送短信验证码
   *
   * @param {*} countryCode 区号
   * @param {*} mobile 手机号码
   * @returns
   * @memberof Service
   */
  sendMobileToken(countryCode, mobile) {
    return new Promise((resolve, reject) => {
      http
        .post("/mobile_tokens", {
          mobile,
          country_code: countryCode,
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("发送短信验证码 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取区号列表
   *
   * @returns
   * @memberof Service
   */
  getCountryCodes() {
    return new Promise((resolve, reject) => {
      if (this.countries) return resolve(this.countries);
      http
        .get("/countries/country_codes")
        .then(({ data }) => {
          this.countries = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取区号列表 失败, ", error);
          resolve([]);
        });
    });
  }

  /**
   * 获取套餐
   *
   * @returns
   * @memberof Service
   */
  getDeal(dealId, project = "diningcity") {
    return new Promise((resolve, reject) => {
      http
        .get(`/deals/${dealId}`, {
          params: {
            project,
          },
        })
        .then(({ data }) => {
          resolve({
            ...data,
          });
        })
        .catch((error) => {
          console.error("获取套餐 失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取project信息
   *
   * @returns
   * @memberof Service
   */
  getProject(project) {
    return new Promise((resolve, reject) => {
      http
        .get(`/project_configs/${project}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("获取project信息 失败, ", error);
          resolve(null);
        });
    });
  }
  /**
   * 获取图片配置
   * @param {*} project 
   * @returns 
   */
  getProjectImgs(project) {
    return new Promise((resolve, reject) => {
      http.get(`/project_configs/${project}/imgs`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          console.error('获取images失败', error)
          resolve([])
        })
    })
  }
}

export default new Service();
