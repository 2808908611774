import { action, computed, observable } from "mobx";
import qs from 'qs'
import http, { lang, getQuery, BASE } from "../service/http";
import masterCardStore from './master-card'
import restaurantStore from "./restaurant";
import memberStore from './member'
import { loadStripe } from '@stripe/stripe-js/pure';
import wx from 'weixin-js-sdk'
loadStripe.setLoadParameters({ advancedFraudSignals: false })

const cache = {}

class PaymentStore {
  /**订单id */
  @observable orderId = ''
  /**支付方式 */
  @observable paymentMethods = []
  /**支付数据 */
  @observable payment = {}
  /** Stripe 支付key */
  @observable stripePublicKey = ''
  /** program支持的卡 用来限制stripe页面卡的小时和填写 brand字段 */
  @observable bankCardInfos = []

  /**
   * 获取Stripe 支付key
   */
  @action
  async getStripeKey(regionId) {
    try {
      const { data } = await http.get(`/stripes/get_api_key`, {
        params: { region_id: regionId },
      })
      this.stripePublicKey = data.public_key
      return data
    } catch (err) {
      console.error(err)
      return ''
    }
  }

  /**
   * 获取Stripe卡列表
   */
  @action
  async getStripeCardList() {
    try {
      const { data } = await http.get(
        `${process.env.REACT_APP_API}/member/bank_cards`
      )
      return data
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * 验证卡 Public Stripes
   */
  @action
  async validStripeCard(paymentMethod) {
    const { project } = getQuery()
    try {
      const { data } = await http.post(`/stripes/valid_card?project=${project}`, paymentMethod)
      return data
    } catch (err) {
      // console.error(err)
      throw err
    }
  }
  /**
   * stripe支持的卡的信息
   * @returns
   */
  async getBankCardInfos() {
    const { project = 'diningcity' } = getQuery()
    if (this.bankCardInfos.length) {
      return this.bankCardInfos
    }
    try {
      const { data } = await http.get(`/projects/${project}/bank_card_infos`)
      this.bankCardInfos = data
      return data
    } catch (err) {
      throw err
    }
  }
  /**
   * 创建卡接口
   */
  async createCard(project = 'diningcity', region_id, paymentMethod) {
    try {
      const member_id = memberStore.member?.id
      const { data } = await http.post(
        `/stripes/setup_create?project=${project}&region_id=${region_id}&member_id=${member_id}`,
        paymentMethod
      )
      // 获取clientSecret
      return data
    } catch (err) {
      // console.error(err)
      throw err
    }
  }
  /**
   * 保存stripe支付方式
   */
  async saveStripePayMethod({ region_id, project, member_id, payment_method }) {
    try {
      const { data } = await http.post(
        `/stripes/save_payment_method?region_id=${region_id}&project=${project}&member_id=${member_id}`,
        { payment_method }
      )
      return data
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * 银行授权页面完成后逻辑处理
   */
  async setupIntentUrlFlow() {
    const {
      setup_intent,
      setup_intent_client_secret,
      redirect_status,
      region_id,
      project,
      member_id,
      redirect_url,
      private_token,
      channel
    } = getQuery()
    if (!setup_intent_client_secret) return
    try {
      const { public_key } = await this.getStripeKey(region_id)
      const stripe = await loadStripe(public_key)
      const { setupIntent } = await stripe.retrieveSetupIntent(
        setup_intent_client_secret
      )

      switch (setupIntent.status) {
        case 'succeeded': {
          const res = await masterCardStore.bind({
            region_id,
            project,
            member_id,
            channel,
            payment_method_id: setupIntent.payment_method,
          })
          console.log(res, 'bind card')
          location.replace(decodeURIComponent(redirect_url))
          break
        }
        case 'processing': {
          console.log(
            "Processing payment details. We'll update you when processing is complete."
          )
          location.replace(decodeURIComponent(redirect_url))
          break
        }
        case 'requires_payment_method': {
          console.warn(
            'Failed to process payment details. Please try another payment method.'
          )
          location.replace(decodeURIComponent(redirect_url))
          break
        }
      }
    } catch (error) {
      throw error
    }
  }
  /**
   * 放到bank card里面
   */
  async addBankCards() {
    try {
      const { data } = await http.post(
        `${process.env.REACT_APP_API}/member/bank_cards`
      )
      return data
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * 验证卡号
   *
   */
  @action
  async valid(cardBin) {
    const { project } = getQuery()
    try {
      http.get(`/access_codes/wide_valid`, {
        params: {
          project: project,
          code_or_password: cardBin,
        },
      })
    } catch (error) { }
  }

  /**
   * 获取支付方式
   *
   */
  @action
  async getMethods(option) {
    if (this.paymentMethods.length) return this.paymentMethods
    // 判断客户端类型
    const { project, program, deal_id, payment_id, access_code } = option || getQuery()
    let ua = navigator.userAgent.toLowerCase()
    let client = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(ua)
      ? 'mobile'
      : 'desktop'
    client = ua.match(/MicroMessenger/i) == 'micromessenger' ? 'weixin' : client
    const restaurant_id = option ? option.restaurant_id : restaurantStore.restaurant?.id
    try {
      let { data } = await http.get(`/payments/available_channels_detail`, {
        params: {
          from: client,
          project: program || project,
          restaurant_id,
          deal_id,
          payment_id,
          access_code
        },
      })
      this.paymentMethods = data
      return data
    } catch (error) { }
  }

  /**获取支付凭证 */
  @action
  async prePayment({ payment = {}, payment_method, bank_card_uuid, open_id, success_url }) {
    try {
      let { data } = await http.post(`/payments/${payment.uuid}/pay`, {
        channel: payment_method,
        from: this.deviceDetect(),
        // 银联支付页面
        success_url: success_url || (bank_card_uuid
          ? undefined
          : makeSuccessUrl(payment_method)),
        // 万事达卡支付
        bank_card_uuid,
        // 微信openid
        open_id,
      })
      if(typeof data.miniPayRequest === 'string') {
        try {
          data.miniPayRequest = JSON.parse(data.miniPayRequest)
        } catch (error) {
          data.miniPayRequest = {}
        }
      }

      this.orderId = payment.uuid
      this.payment = data
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**使用ping++支付 */
  @action
  callPayment() {
    return new Promise((resolve) => {
      import('pingpp-js').then((pingpp) => {
        pingpp.setUrlReturnCallback(
          (err, url) => {
            if (err) return console.error(err)
            window.location.href = url
          },
          [this.payment.channel]
        )
        pingpp.createPayment(this.payment, (result, err) => {
          console.log('支付完成', result, err)
          if (result === 'success') {
            resolve('success')
            // this.getPayment(this.orderId)
            // 只有微信公众账号 wx_pub 支付成功的结果会在这里返回，其他的支付结果都会跳转到 extra 中对应的 URL。
          } else if (result === 'fail') {
            // charge 不正确或者微信公众账号支付失败时会在此处返回
            console.error('fail:', err)
            resolve('fail')
          } else if (result === 'cancel') {
            // 微信公众账号支付取消支付
            console.error('cancel:', err)
            resolve('cancel')
          }
        })
      })
    })
  }

  /** 去支付 */
  @action
  getAmexPayUrl(book) {
    let { jumpUrl = "" } = getQuery();
    jumpUrl = decodeURIComponent(jumpUrl)
    const [path, queryStr] = jumpUrl.split("?")
    const query = qs.parse(queryStr)
    query.pageType = 'PAGE_PAY'
    query.orderNo = book.third_book_id
    const url = `${path}?${qs.stringify(query)}`
    return url
  }

  /** 去支付 */
  @action
  goAmexPay(book, fallback) {
    let { source = "", jumpUrl = "" } = getQuery();
    jumpUrl = decodeURIComponent(jumpUrl)
    const [path, queryStr] = jumpUrl.split("?")
    const query = qs.parse(queryStr)
    query.pageType = 'PAGE_PAY'
    query.orderNo = book.third_book_id
    const url = `${path}?${qs.stringify(query)}`
    // 当前环境是浏览器, 直接跳转到待支付页面
    if (!jumpUrl) {
      return fallback()
    }
    // 如果是iframe模式
    if (window.top !== window.window) {
      return window.top.postMessage({
        type: "navigateTo",
        url
      }, "*")
    }
    window.location.replace(url)
  }


  /**获取支付结果 */
  @action
  getPayment() {
    return http.get(`/payments/${this.orderId}`)
  }

  @action
  async getWxConfig() {
    if(this.deviceDetect() !== 'weixin') return
    return http.get(`https://api.diningcity.asia/public/test/wechat_jsapi_sign`, {
      params: {
        url: location.href
      }
    }).then(({data}) => {
      let config = {
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,
        jsApiList: ['navigateToMiniProgram'],
        openTagList: ['wx-open-launch-weapp']
      }
      return this.setWxConfig(config)
    })
  }
  @action
  async getScheme(id, code, payMethod, provider, payment) {
    if(this.deviceDetect() !== 'mobile') return
    const cacheKey = id+code+payMethod;
    if(cache[cacheKey]) return cache[cacheKey]
    const params = {
      path: '/profile/booking-detail',
      query: `resv_id=${id}&resv_code=${code}&pay_method=${payMethod}&pay_uuid=${payment.uuid}&pay_provider=${provider}&directPay=1`
    }
    return http.post(`https://api.diningcity.asia/public/wechat/get_generate_scheme?${qs.stringify(params)}`).then(({data}) => {
      cache[cacheKey] = data.openlink
      return data.openlink
    })
  }

  setWxConfig(config) {
    wx.config(config)
    return new Promise((resolve, reject) => {
      wx.ready(resolve)
      wx.error(reject)
    })
  }

  /**设备检测 */
  deviceDetect() {
    let ua = navigator.userAgent.toLowerCase()
    let device = 'unknown'
    if (ua.match(/(iphone|ipod|android|ios)/i)) {
      device = 'mobile'
    } else {
      device = 'desktop'
    }
    if (ua.indexOf('micromessenger') > -1) {
      device = 'weixin'
    }
    return device
  }
}

function makeSuccessUrl(payment_method) {
  let { success_url } = getQuery();
  return decodeURIComponent(success_url);
}

export default new PaymentStore();
