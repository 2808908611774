import axios from "axios";
import jsonpClient from "jsonp";
import qs from "qs";

/**系统语言 */
export const lang = getLang();

/**域名信息 */
export const hostname = getHostname();

export const BASE = process.env.REACT_APP_API;
const http = axios.create({
  baseURL: `${BASE}/public`,
  headers: {
    "Content-Type": "application/json",
    "api-key": "cgecegcegcc",
    lang: lang,
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => Promise.reject(httpErrorHandler(error))
);

/**
 * 处理通用错误信息
 *
 * @param {Error} error
 * @returns
 */
function httpErrorHandler(error) {
  let { code = "", data = {}, message = "", response } = error;

  // 服务器错误
  // 如果自带了错误信息，使用服务器的错误信息，否则使用自定义的错误信息
  if (response) {
    code = response.status;
    data = response.data;
    try {
      message = response.data.full_messages[0];
    } catch (error) {
      message = response.data.message || response.statusText;
    }
  }

  switch (code) {
    case 400:
      // message = '参数类型错误';
      break;
    case 401:
    case 403:
      break;
    case 404:
      // message = '错误的请求地址';
      break;
    case 405:
      // message = '错误的请求方法';
      break;
    case 415:
      // message = '不支持的类型';
      break;
    case 500:
    case 501:
    case 502:
    case 503:
      // message = '服务器错误';
      break;
    default:
      break;
  }
  return {
    code,
    message,
    data,
  };
}

/**
 * 获取域名信息
 *
 * @returns
 */
function getHostname() {
  let hostname =
    window === window.top ? "https://www.diningcity.cn/" : document.referrer;
  let last = hostname.replace(/\//g, "").split(".").pop();
  last = ["sg", "hk", "cn"].includes(last) ? last : "cn";
  return `https://www.diningcity.${last}/`;
}

/**
 * jsonp请求
 *
 * @param {*} url
 * @param {*} [options={}]
 * @returns
 */
http.jsonp = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    jsonpClient(url, options, (err, data) => {
      if (err) {
        return reject(err);
      }
      resolve(data);
    });
  });
};

http.getHeaders = () => {
  let query = getQuery();
  return {
    headers: {
      uid: query.uid,
      client: query.client,
      "access-token": window.private_token,
    },
  };
};

/**删除空字符 */
http.dropEmpty = (data) => {
  let form = {};
  Object.keys(data).forEach((key) => {
    let val = data[key];
    if (val !== "" && (val !== undefined) & (val !== null)) {
      form[key] = val;
    }
  });
  return form;
};

/**获取url参数 */
export function getQuery() {
  let query = qs.parse(location.href.split("?")[1]);
  return query;
}

/**拼接url参数 */
export function urlBuilder(reservation_code, reservation_id) {
  const { top } = getQuery();
  let topUrl = top? top.split('?')[0] : location.origin + location.pathname;
  let payment = `payment=${reservation_code}___${reservation_id}`;
  return `${topUrl}?${payment}`;
}

function getLang() {
  //如果url指定的语言合法，使用指定语言
  let { lang } = qs.parse(location.href.split("?")[1]);
  if (lang) return lang;
  // 如果指定语言不合法，使用系统语言
  lang = window.navigator.language;
  if (lang === "zh-CN") return "zh";
  return lang.substring(0, 2)
}

export default http;
