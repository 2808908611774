import { action, computed, observable } from "mobx";
import http, { getQuery } from "../service/http";
import memberStore from './member'
import restaurantStore from './restaurant'

export const mock = [1, 2, 3, 4, 5].map((item) => {
  return {
    id: 869536,
    code: item,
    start_at: "2020-11-12T00:00:00.000+08:00",
    end_at: "2020-11-22T23:59:59.000+08:00",
    project: "rwcn_winter_2020_drinkfest",
    points: 1,
    used: false,
    expired: false,
    project_config: { title: "饮迷中国鸡尾酒节", project_type: "event" },
    detail: {
      title: "饮迷中国鸡尾酒节超值酒饮饮迷中国鸡尾酒节超值酒饮券券(50元/杯)",
      desc:
        "饮迷鸡尾酒节超值酒饮券仅限活动期间(11月12日-11月22日)使用，可在活动门店兑换50元/杯的指定酒饮，随兑随用，不限门店，不限次数。\n扫描店内活动二维码或向门店工作人员直接出示“兑换成功”页面，即可以50元/杯的优惠价格购买活动酒饮。",
      id: 164,
      name: "drinkfest1",
      code_length: 6,
      points_type: "unlimited",
      price: "50000.0",
      currency: "MCP$",
      points: 1,
      deal: null,
    },
  };
});

class Store {
  /**
   * 获取优惠券
   *
   * @memberof Store
   */
  @action
  getList({restaurantId, cardNumber}) {
    return new Promise((resolve) => {
      let { private_token, deal_id, project } = getQuery();
      const { restaurant_id, date, time, seats, discount, country_code, mobile } = restaurantStore.form
      http
        .get(`${process.env.REACT_APP_API}/member/vouchers/available`, {
          params: {
            uuid: cardNumber,
            per_page: 999,
            deal_id,
            restaurant_id,
            project,
            date,
            time,
            seats,
            discount, 
            country_code, 
            mobile
          },
          headers: {
            ["access-token-member"]: private_token || memberStore.member.private_token,
          },
        })
        .then(({ data }) => {
          let list = data || [];
          let applicable = list.filter(item => item.applicable).sort((a,b) => new Date(b.end_at) - new Date(a.end_at));
          let notApplicable = list.filter(item => !item.applicable);
          resolve(applicable.concat(notApplicable));
        })
        .catch((error) => {
          console.error("获取优惠券 失败, ", error);
          resolve([]);
        });
    });
  }
  /**
   * 获取优惠券使用规则
   *
   * @memberof Store
   */
  @action
  getRule(seats, restaurant_id) {
    let { deal_id, project = "diningcity",  } = getQuery();
    return new Promise((resolve) => {
      http
        .get(`/project_configs/${project}/voucher_info`, {
          params: {
            seats,
            deal_id,
            restaurant_id
          },
        })
        .then(({ data }) => {
          resolve(data || {});
        })
        .catch((error) => {
          console.error("获取优惠券 失败, ", error);
          resolve({});
        });
    });
  }
  /** 优惠劵批次分配 */
  @action
  batchAssignment () {
    const { project = 'diningcity' } = getQuery()
    return new Promise((resolve) => {
      http
        .get(`/voucher_batch_assignments`, {
          params: {
            project
          }
        })
        .then(({data}) => {
          resolve(data)
        })
        .catch((error) => {
          console.error("分配优惠劵 失败", error)
          resolve([])
        })
    })
  }
}

export default new Store();
