import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import "./index.less";
import { useMemo } from "react";
import * as Common from "../../components/common";
import { useStores } from "@/store";

/**
 * 填写卡号信息
 *
 * @param {*} props
 */
const Card = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [tip, setTip] = useState("");
  const [errorTip, setErrorTip] = useState(false);
  const [loading, setLoading] = useState(false);
  const { restaurantStore } = useStores();
  let makers = new Array(13).fill(1);
  let marginLeft = useMemo(() => {
    return {
      marginLeft: value.length * (18 + 5) + (value.length > 7 ? 46 : 0),
    };
  }, [value]);
  function onInput(ev) {
    let val = value.slice();
    let size = val.length;
    // 删除
    if (ev.key === "Backspace") {
      val.pop();
    } else {
      setInputVal("");
      if (size < 13 && parseInt(ev.key, 10) == ev.key) {
        val.push(ev.key);
      }
    }
    setValue(val);
  }
  function onSubmit() {
    setErrorTip(false);
    setLoading(true);
    let access_password = value.join("").substr(0, 8);
    restaurantStore
      .verifyCard(access_password)
      .then((data) => {
        // 验证通过
        restaurantStore.form = {
          ...restaurantStore.form,
          ...restaurantStore.deals.access_code_data,
          access_password,
        };
        restaurantStore.step = restaurantStore.STEPS.DATE;
      })
      .catch((error) => {
        console.error(error);
        setErrorTip(true);
      })
      .then(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    restaurantStore.getAccesCodeTip().then(({ data }) => {
      setTip(data.desc);
    });
  }, [restaurantStore.deals]);
  return useObserver(() => {
    return (
      <div className="step-card">
        <h2>{t("PAYMENT.VERIFY CARD")}</h2>
        <p className="tip">{tip}</p>
        <h3 style={{ textAlign: "left" }}>{t("PAYMENT.Bank card number")}</h3>
        <label className="input-group">
          <div className="input-wrapper">
            <input
              type="tel"
              value={inputVal}
              style={marginLeft}
              onKeyDown={onInput}
            />
          </div>
          <div className="input-mark">
            {makers.slice(0, 8).map((i, index) => (
              <i key={index}>{value[index]}</i>
            ))}
            <i data-place="*">*</i>
            <i data-place="*">*</i>
            {makers.slice(7, 12).map((i, index) => (
              <i key={index}>{value[8 + index]}</i>
            ))}
          </div>
        </label>

        <Common.Help hidden={!errorTip}>
          {t("PAYMENT.Please enter a valid bank card number")}
        </Common.Help>

        <footer className="page-footer" align="right">
          <Common.Button
            type="primary"
            loading={loading}
            disabled={value.length !== 13}
            onClick={onSubmit}
          >
            {t('PAYMENT.Next')}
          </Common.Button>
        </footer>
      </div>
    );
  });
};

export default Card;
