import { loadScript } from "@/utils";
import { action, observable } from "mobx";
import http, { BASE, getQuery, lang } from "../service/http";
import memberStore from './member'
import restaurantStore from "./restaurant";

class EventBus {
  constructor() {
    this.bus = {};
  }

  $off(id) {
    delete this.bus[id];
  }

  $on(id, callback) {
    this.bus[id] = callback;
  }

  $emit(id, ...params) {
    if (this.bus[id]) this.bus[id](...params);
  }
}

export const events = new EventBus();

class Store {
  @observable cards = [];
  session_id = undefined;
  /**
   * 获取卡列表
   *
   * @memberof Store
   */
  @action
  getList() {
    const { project = 'diningcity' } = getQuery()
    const region_id = restaurantStore.restaurant?.region_id || ''
    return http
      .get(BASE + `/member/bank_cards?project=${project}&region_id=${region_id}`, {
        ...getHeaders(),
      })
      .then((data) => {
        this.cards = data.data;
        return data.data;
      });
  }
  /**添加新卡 */
  @action
  create() {
    return new Promise((resolve, reject) => {
      try {
        events.$off("fail");
        events.$off("success");
        events.$on("success", (session_id) => {
          this.session_id = session_id;
          getAuth(session_id).then(resolve).catch(reject);
        });
        events.$on("fail", reject);
        PaymentSession.updateSessionFromForm("card");
      } catch (error) {
        console.error(error);
      }
    });
  }
  /**删除卡 */
  @action
  remove(id) {
    return http
      .delete(BASE + "/member/bank_cards/" + id, {
        ...getHeaders(),
      })
      .then(() => {
        this.getList();
      });
  }

  @action
  async configure() {
    const { session_js } = await getJsUrl();
    await loadScript(session_js, false);
    return new Promise((resolve) => {
      PaymentSession.configure({
        fields: {
          card: {
            number: "#j_master_card_number",
            securityCode: "#j_master_card_securityCode",
            expiryYear: "#j_master_card_expiryYear",
            expiryMonth: "#j_master_card_expiryMonth",
          },
        },
        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: function (response) {
            resolve(response.status === "ok");
          },
          formSessionUpdate: function (response) {
            if (response.status) {
              if ("ok" == response.status) {
                events.$emit("success", response.session.id);
              } else if ("fields_in_error" == response.status) {
                if (response.errors.cardNumber) {
                  events.$emit("fail", "Card number invalid or missing.");
                }
                if (response.errors.expiryYear) {
                  events.$emit("fail", "Expiry year invalid or missing.");
                }
                if (response.errors.expiryMonth) {
                  events.$emit("fail", "Expiry month invalid or missing.");
                }
                if (response.errors.securityCode) {
                  events.$emit("fail", "Security code invalid.");
                }
              } else if ("request_timeout" == response.status) {
                events.$emit("fail", response.errors.message);
              } else if ("system_error" == response.status) {
                events.$emit("fail", response.errors.message);
              }
            } else {
              events.$emit("fail", response.errors.message);
            }
          },
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT",
          },
        },
      });
    });
  }

  formatError(card) {
    // 卡号错误
    var cardNumber = card.number;
    if (cardNumber.isIncomplete) {
      return "Card number is incomplete, please enter full card number.";
    }
    if (cardNumber.hasError) {
      if(lang === 'zh-HK') {
        return '加卡失敗！請使Mastercard World或Mastercard World Elite卡重試。'
      }
      return "Adding card unsuccessful! Please use Mastercard World or Mastercard World Elite card to try again.";
    }
    if (cardNumber.errorReason) {
      switch (cardNumber.errorReason) {
        case "EMPTY":
          return "Card number is mandatory field, please enter card number.";
          break;
        case "INVALID_CHARACTERS":
          return "There is invalid character in card number.";
          break;
        case "NOT_SUPPORTED":
          return "The card number you entered is not supported.";
          break;
        case "INVALID_VALUE":
          return "The card number you entered is incorrect, please check the number and try again.";
          break;
        case "INVALID_LENGTH":
          return "The card number you entered is too long.";
          break;
        case "AWAITING_SERVER_RESPONSE":
          return "Card type is being determined. Please try again.";
          break;
        case "TIMEOUT":
          return "We can not determine the card type. You can proceed updating card number or reload the page.";
          break;
        case "NOT_AUTHORIZED":
          return "Request is not authorized, your session is probably expired. Please reload the page.";
          break;
        case "SYSTEM_ERROR":
          return "System error occurred. Please reload the page.";
          break;
        case "SESSION_AUTHENTICATION_LIMIT_EXCEEDED":
          return "You have exceeded session authentication limit. Please reload the page in order to generate new sessionId.";
          break;
        default:
          break;
      }
    }
    // 日期错误
    var expiryMonth = card.expiryMonth;
    if (expiryMonth.isIncomplete) {
      return "Expiry month is incomplete, please enter the value between [1 - 12].";
    }
    if (expiryMonth.hasError) {
      return "Error has been detected on Expiry month field.";
    }
    if (expiryMonth.errorReason) {
      switch (expiryMonth.errorReason) {
        case "EMPTY":
          return "Expiry month is mandatory field, please enter Expiry month.";
          break;
        case "INVALID_CHARACTERS":
          return "There is invalid character in Expiry month.";
          break;
        case "INVALID_VALUE":
          return "The Expiry month you entered is incorrect.";
          break;
        default:
          break;
      }
    }
    // 日期错误
    var expiryYear = card.expiryYear;
    if (expiryYear.isIncomplete) {
      return "Expiry year is incomplete.";
    }
    if (expiryYear.hasError) {
      return "Error has been detected on Expiry year field.";
    }
    if (expiryYear.errorReason) {
      switch (expiryYear.errorReason) {
        case "EMPTY":
          return "Expiry year is mandatory field, please enter Expiry year.";
          break;
        case "INVALID_CHARACTERS":
          return "There is invalid character in Expiry year.";
          break;
        case "INVALID_VALUE":
          return "The Expiry year you entered is incorrect.";
          break;
        default:
          break;
      }
    }
    // 安全码错误
    var securityCode = card.securityCode;
    if (securityCode.isIncomplete) {
      return "Security code is incomplete.";
    }
    if (securityCode.hasError) {
      return "Error has been detected on Security code field.";
    }
    if (securityCode.errorReason) {
      switch (securityCode.errorReason) {
        case "EMPTY":
          return "Security code is mandatory field, please enter Security code.";
          break;
        case "INVALID_CHARACTERS":
          return "There is invalid character in Security code.";
          break;
        case "INVALID_VALUE":
          return "The Security code you entered is incorrect.";
          break;
        default:
          break;
      }
    }
  }

  /**绑定卡信息 */
  @action
  async bind({ order_id, region_id, payment_method_id, channel = 'mpgs' }) {
    const { project, program = 'diningcity', handle } = getQuery()
    return http
      .post(
        BASE + "/member/bank_cards",
        {
          session_id: this.session_id,
          order_id,
          source: project || program,
          handle: handle,
          region_id,
          payment_method_id,
          channel,
        },
        getHeaders()
      )
      .then((data) => {
        this.getList();
        return data.data;
      });
  }
}
/**获取鉴权js地址 */
function getJsUrl() {
  return new Promise((resolve, reject) => {
    http.get("/mpgs/js_urls").then(async (data) => {
      resolve(data.data[0]);
    });
  });
}

/**获取鉴权信息 */
function getAuth(session_id) {
  return new Promise((resolve, reject) => {
    http
      .post("/mpgs/three_ds_authentication", {
        session_id: session_id,
        auth_back_url: location.origin,
        device: {
          browser: navigator.userAgent,
          browserDetails: {
            colorDepth: window.screen.colorDepth,
            javaEnabled: navigator.javaEnabled(),
            language: http.lang,
            screenHeight: window.screen.height,
            screenWidth: window.screen.width,
            timeZone: new Date().getTimezoneOffset(),
          },
        },
      })
      .then((data) => {
        resolve(data.data.authentication.redirectHtml);
      })
      .catch(reject);
  });
}

function getHeaders() {
  const headers = http.getHeaders().headers;
  return {
    headers: {
      "access-token-member": headers["access-token"] || memberStore.member.private_token,
      ...headers,
    },
  };
}

export default new Store();
