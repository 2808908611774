import { useRef, useEffect, useCallback, useState } from 'react'
import qs from "qs";
import { useStores } from "@/store";

const SCRIPT_CACHE = {}

/**浏览器头信息 */
export const userAgent = window.navigator.userAgent.toLowerCase();

/**客户端 */
export const client = (() => {
  // 微信小程序webview
  if (has("miniprogram")) {
    return "MP";
  }
  // 钉钉webview
  if (has("dingtalk") || has("aliapp")) {
    return "DINGTALK";
  }
  // 微信浏览器
  if (has("micromessenger")) {
    return "WECHAT";
  }
  // web
  return "WEB";
})();

export const goTop = () => {
  setTimeout(() => {
    document.documentElement.scrollTop = 0;
  }, 10);
};

export const scrollIntoView = (selector) => {
  try {
    document.querySelector(selector).scrollIntoView();
  } catch (error) {}
};

/**判断优惠券选择是否正确 */
export const voucherRequire = ({ range, required, voucher_codes }) => {
  let [min, max] = range;
  let size = voucher_codes.length;
  if (!required) return true;
  if (!max) max = min;
  return size >= min && size <= max;
};

function has(key) {
  return userAgent.indexOf(key) > -1;
}

let scrollTop = 0;

function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

// 进出场，回调
export async function animate(dir, callback) {
  if (dir === "PUSH") {
    scrollTop = document.documentElement.scrollTop;
    callback();
    await sleep(30);
    document.documentElement.scrollTop = 0;
    document.body.setAttribute("animate", "push");
  } else {
    document.body.setAttribute("animate", "pop");
    await sleep();
    callback();
    document.documentElement.scrollTop = scrollTop;
    scrollTop = 0;
    document.body.setAttribute("animate", "");
  }
}

export function cardFormat(num) {
  return num.replace(/[^0-9]/gi, "").replace(/(\d{4})(?=\d)/g, "$1 ");
}

export function cardLogo(num) {
  return String(num).indexOf("62") === 0
    ? {
        className: "icon icon-card-logo",
        "data-card": "master-card",
      }
    : {
        className: "icon icon-payment-method",
        "data-card": "",
      };
}

/**获取url参数 */
export function getQuery(key) {
  let query = qs.parse(location.href.split("?")[1]);
  return key ? query[key] : query;
}

export function loadScript(src, cache = true) {
  return new Promise((resolve, reject) => {
    if(SCRIPT_CACHE[src]) return resolve();
    const script = document.createElement('script');
    script.onload = () => {
      if(cache) {
        SCRIPT_CACHE[src] = true;
      }
      resolve();
    };
    script.onerror = reject;
    script.src = src;
    document.body.appendChild(script);
  })
}

/**节流函数 */
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function() {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function() {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

/**
 * hook 节流
 * @param {*} fn 
 * @param {*} delay 
 * @param {*} dep 
 * @returns 
 */
export function useThrottle(fn, delay, dep = []) {
  const { current } = useRef({ fn, timer: null });
  useEffect(function () {
    current.fn = fn;
  }, [fn]);
  return useCallback(function f(...args) {
    if (!current.timer) {
      current.timer = setTimeout(() => {
        delete current.timer;
      }, delay);
      current.fn(...args);
    }
  }, dep);
}

export const formatDate = (date) => {
  let str = new Date(date)
  let yyyy = str.getFullYear()
  let mm = str.getMonth() + 1
  let dd = str.getDate()
  if(mm < 10) {
    mm = '0'+mm
  }
  if(dd < 10) {
    dd = '0'+dd
  }
  return [yyyy, mm, dd].join('-')
}

export const usePayResult = ({callback}) => {
  const timer = useRef();
  const [flag, setFlag] = useState(false)
  const { paymentStore } = useStores();
  const getResult = () => {
    paymentStore.getPayment().then(({ data }) => {
      if (data.status !== "pending") {
        clearInterval(timer.current);
        callback();
      }
    }).catch(() => {
      clearInterval(timer.current);
      callback();
    })
  }

  useEffect(() => {
    if(flag) {
      getResult();
      timer.current = setInterval(getResult, 3500);
    } else {
      clearInterval(timer.current)
    }
    return () => {
      clearInterval(timer.current)
    }
  }, [flag])

  return {
    setFlag
  }
}