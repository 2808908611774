import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { client, animate } from "@/utils";
import * as Common from "@/components/common";
import { useStores } from "@/store";

const PosPayDetail = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { paymentStore } = useStores();

  // 价格条目
  const items = useMemo(() => {
    return props.orderInfo?.items || [];
  }, [props.orderInfo]);

  // 支付方式
  const payMethod = useMemo(() => {
    if (!props.payMethod) {
      return t("PAYMENT.Select");
    }
    return (
      props.payMethodList.find((item) => item.keyword === props.payMethod)
        ?.name || ""
    );
  }, [props.payMethod, props.payMethodList]);

  const paymentTime = props.orderInfo.formated_paid_at;

  function openPopup() {
    // 不需要选择支付方式
    const needPay = props.orderInfo.payment.status === "pending";
    const onlyone = paymentStore.paymentMethods.length <= 1;
    if (!needPay || onlyone) return;
    // 打开支付方式选择弹窗
    animate("PUSH", () => setVisible(true));
  }

  function closePopup(data) {
    props.setPayMethod(data);
    // 关闭支付方式选择弹窗
    animate("POP", () => setVisible(false));
  }

  return (
    <div className="pos-pay-detail">
      <h3>{t("PAYMENT.Payment Details")}</h3>
      {items.map((item, index) => {
        return (
          <Common.ListItem
            title={item.title}
            value={item.format_fee}
            key={index}
            className={
              item.format_fee.indexOf("-") > -1 ? "pos-pay-detail-color" : ""
            }
          ></Common.ListItem>
        );
      })}
      <div className="hr-line"></div>

      {/* 支付方式 */}
      <Common.ListItem
        onClick={openPopup}
        title={
          <Common.Icon
            text={t("PAYMENT.Payment method")}
            icon="icon icon-payment-method"
          ></Common.Icon>
        }
        value={
          <span
            className="pay-method-value"
            data-selected={props.payMethod ? "true" : "false"}
          >
            {payMethod}
          </span>
        }
      ></Common.ListItem>

      {/* 支付时间 */}
      <Common.ListItem
        hidden={!paymentTime}
        title={
          <Common.Icon
            text={t("PAYMENT.Payment time")}
            icon="icon icon-time"
          ></Common.Icon>
        }
        value={paymentTime}
      ></Common.ListItem>

      {visible ? (
        <Common.PaymentPopup
          visible={visible}
          data={props.payMethod}
          onBack={closePopup}
        ></Common.PaymentPopup>
      ) : null}
    </div>
  );
};

export default PosPayDetail;
