import { action, computed, observable } from "mobx";
import groupBy from "lodash/groupBy";
import moment from "moment";
import restaurantService from "../service/restaurant";
import reservationService from "../service/reservation";
import http, { lang, hostname, getQuery } from "../service/http";
import member from "./member";
import wx from "weixin-js-sdk";
import { goTop } from "../utils";
import qs from 'qs'

const BASE = process.env.REACT_APP_API;

class Store {
  @observable STEPS = {
    /**开始 */
    START: 0,
    /**卡号验证 */
    CARD: -1,
    /**日期 */
    DATE: 0,
    /**时间 */
    TIME: 1,
    /**座位数 */
    SEAT: 2,
    /**用户资料 */
    INFO: 3,
    /**预定资料 */
    CONFIRM: 4,
    /**预定完成 */
    COMPLETE: 5,
    /**支付二维码 */
    PAYMENT: 999,
    /**admin模式 */
    ADMIN: 1000,
    /**标记部分出席模式 */
    PARTIAL_NO_SHOW: 1001,
  };
  @observable id = ""; // 餐厅id
  @observable reservation = {};
  @observable restaurant = {}; // 餐厅资料
  /**餐厅预订规则 */
  @observable bookingConditions = {};
  @observable availability = []; // 可用日期-时间-座位数等
  @observable alertTerms = []; // 预定条款
  @observable countries = []; // 国家区号
  @observable step = 0; // 当前步骤
  @observable fullBook = false; // 满订
  @observable steps = [
    { name: "date", icon: "icon-date", status: "", value: this.STEPS.DATE },
    { name: "time", icon: "icon-time", status: "", value: this.STEPS.TIME },
    { name: "seat", icon: "icon-seat", status: "", value: this.STEPS.SEAT },
    { name: "user", icon: "icon-info", status: "", value: this.STEPS.INFO },
    {
      name: "final",
      icon: "icon-check",
      status: "",
      value: this.STEPS.CONFIRM,
    },
  ]; // 步骤
  /**填写的表单数据 */
  @observable form = {
    /**预定id*/
    id: undefined,
    /**预定日期*/
    date: "",
    /**预定时间*/
    time: "",
    /**餐厅id*/
    restaurant_id: "",
    /**折扣*/
    discount: "",
    /**套餐id*/
    deal_id: "",
    /**团队预订*/
    group_booking: false,
    /**折扣id*/
    off_peak_id: "",
    /**伙伴id*/
    partner_user_id: null,
    /**姓*/
    first_name: "",
    /**名*/
    last_name: "",
    /**手机号*/
    mobile: "",
    /**验证码*/
    mobile_code: "",
    /**电子邮箱*/
    email: "",
    /**区号*/
    country_code: "",
    /**用户协议*/
    checked_terms_conditions: "",
    /**用户福利*/
    loyalty_program_benefit_ids: "",
    /**优惠券码*/
    voucher_codes: [],
    /**邀请码*/
    invite_code: "",
    /**合作商家*/
    project: "",
  };

  @observable deals = null; // 用户可用的套餐信息

  @observable time = {}; // 当前选中的时间对象

  @observable projectInfo = null; // 项目信息
  @observable images = {} // 图片配置
  @observable inviteInfo = {}; // 邀请码信息

  @observable bookingInfo = {}; // 预定详情
  @observable bookingResult = {}; // 預訂結果
  @observable accessCode = {}; // 卡片信息
  @observable pageHeight = ""; //页面高度
  /**支付要素 */
  @observable paymentInfo = {};
  @observable bankNumber = ''; // 当前选择的银行卡

  /**是否需要支付 */
  @computed
  get enablePrePay() {
    return this.deals && this.deals.enable_pre_pay;
  }
  /**
   * 可用日期
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get dates() {
    try {
      return this.availability.map((item) => {
        return {
          date: item.date,
          discount: restaurantService.getDiscount(item),
        };
      });
    } catch (error) {
      return [];
    }
  }
  /**
   * 可用时间段
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get times() {
    try {
      let { times } = this.availability.find(
        (item) => item.date == this.form.date
      );
      return groupBy(times, "meal_type_text");
    } catch (error) {
      return {};
    }
  }
  /**
   * 可选座位
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get seats() {
    try {
      let time = {};
      this.availability.forEach((i) => {
        if (i.date == this.form.date) {
          i.times.forEach((j) => {
            if (j.time == this.form.time) {
              time = j;
            }
          });
        }
      });
      const { seats = {}, off_peak = {} } = time;
      const { id, discount } = off_peak;
      return (seats.available || []).map((item) => {
        return {
          off_peak_id: id,
          seats: item,
          discount: discount && item <= off_peak.seats ? discount : 0,
        };
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  /**
   *隐私条款
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get privacyUrl() {
    try {
      let restaurant = this.restaurant;
      return restaurant.privacy_policy_url;
    } catch (error) {
      return "javascript:void(0);";
    }
  }

  /**
   * 折扣条款
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get offpeakTerm() {
    try {
      let restaurant = this.restaurant;
      return http.lang === "en"
        ? restaurant.discount_explanation
        : restaurant.localized_discount_explanation;
    } catch (error) {
      return "";
    }
  }

  /**
   * 货币符号
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get currency() {
    try {
      return this.paymentInfo.currency || "";
    } catch (error) {
      return "￥";
    }
  }

  @action
  setCardSteps() {
    const steps = {
      /**开始 */
      START: 0,
      /**卡号验证 */
      CARD: 0,
      /**日期 */
      DATE: 1,
      /**时间 */
      TIME: 2,
      /**座位数 */
      SEAT: 3,
      /**用户资料 */
      INFO: 4,
      /**预定资料 */
      CONFIRM: 5,
      /**预定完成 */
      COMPLETE: 6,
      /**待支付 */
      PAYMENT: 999,
      /**admin模式 */
      ADMIN: 1000,
    };
    this.STEPS = steps;
    this.steps.unshift({
      name: "program",
      icon: "icon-program",
      status: "selected",
      value: steps.CARD,
    });
  }

  /**
   * 初始化
   *
   * @param {*} restaurantId
   * @returns
   * @memberof Store
   */
  @action
  async bootstrap(restaurantId, action) {
    const query = getQuery();
    const benefitCode = query.benefitCode || query.voucher_code
    try {
      // 如果是编辑模式，需要获取之前的预订信息并合并
      if (query.reservation_code && query.reservation_id) {
        await this.assignEdit(query.reservation_code, query.reservation_id, restaurantId);
      } else {
        this.form = Object.assign({}, this.form, {
          deal_id: query.deal_id || undefined,
          restaurant_id: restaurantId,
          reservation_code: query.reservation_code || undefined,
          reservation_id: query.reservation_id || undefined,
          program_key: query.program_key || undefined,
          channel_name: query.channel || "DiningCity",
          channel_mark: query.channel_mark || undefined,
          platform: query.platform || "web",
          project: query.project || query.program || 'diningcity',
          access_code: query.access_code || undefined,
          access_password:
            query.access_pwd || query.access_password || undefined,
          partner_user_id: query.partner_user_id || undefined,
          date: query.date || undefined,
          time: query.time || undefined,
          seats: query.seats || undefined,
          source: query.source || undefined,
          amex_code: query.amex_code || undefined,
          handle: query.handle || undefined,
          deal_enable: query.deal_enable || undefined,
          book_id: query.book_id || undefined,
          off_peak_only: Boolean(query.off_peak_only) || undefined,
          voucher_codes: benefitCode ? [benefitCode] : []
        });
      }

      let { project, deal_id, date, time, seats } = this.form;
      if (project) {
        let projectInfo = await reservationService.getProject(project);
        const imgs = await reservationService.getProjectImgs(project)

        const images = {}
        imgs.forEach((i) => {
          images[i.name] = i.img_url
        })
        this.images = images
        this.projectInfo = projectInfo;
      }
      if (deal_id) {
        this.getDeals(deal_id, project);
        // this.getAccessCode(deal_id);
      }
      this.restaurant = await restaurantService.show(restaurantId, this.form);
      this.bookingConditions = await restaurantService.getBookingConditions(restaurantId);
      this.availability = await restaurantService.getAvailability(this.form);
      if (action !== 'admin') {
        // 无可预订时间，或所有预订时间均没有可选座位
        this.fullBook = !this.availability.length;
      }
      setTitle(this.restaurant.name);
      reservationService.getCountryCodes().then((data) => {
        this.countries = data.map((item) => {
          item.country_code_format = `+ ${item.country_code}`;
          return item;
        });
      });
      if (project) {
        reservationService.getInvite(project).then((data) => {
          let { open_at, close_at, opened, required } = data;
          let now = moment();
          this.inviteInfo = {
            isOpen: opened && now > moment(open_at) && now < moment(close_at),
            required,
          };
        });
      }
      if (date && this.availability.find((i) => i.date === date)) {
        this.selectDate(date);
        if (time) {
          this.selectTime(time);
          const seatItem = this.seats.find(v => v.seats === +seats)
          /** select seats */
          if (seats && seatItem) {
            this.selectSeat(seatItem)
          }
        }

      }


      return this.restaurant;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  /**判断是否需要输入密码 */
  @action
  async getAccessCode(dealId) {
    const { project } = getQuery();
    try {
      await http.get(`/access_codes/deal_${dealId}?project=${project}`);
      this.setCardSteps();
    } catch (error) { }
  }

  /**
   * 选择日期
   *
   * @param {*} date
   * @memberof Store
   */
  @action
  selectDate(date) {
    this.step = this.STEPS.TIME;
    this.form = Object.assign({}, this.form, {
      date,
      time: "",
      seats: "",
      discount: "",
      off_peak_id: "",
      children_seats: "",
      group_booking: false,
    });
    goTop();
  }

  /**
   * 选择时间
   *
   * @param {object} time 时段内的座位和折扣，套餐信息
   */
  @action
  async selectTime(time) {
    this.step = this.STEPS.SEAT;
    // this.time = Object.assign({}, this.form, time);
    this.form = Object.assign({}, this.form, {
      time: time,
      seats: "",
      discount: "",
      off_peak_id: "",
      children_seats: "",
      group_booking: false,
    });
    let useTime = `${this.form.date}_${this.form.time}`;
    let project = this.form.project || 'diningcity';
    this.alertTerms = await restaurantService.getAlertTerms(useTime, project);
    goTop();
  }

  /**
   * 选择座位
   *
   * @params {boolean} setStep 是否跳转
   *
   */
  @action
  selectSeat(data, setStep = true) {
    if (setStep) {
      this.step =
        member.isMember && member.noMemebrInfo
          ? this.STEPS.INFO
          : this.STEPS.CONFIRM;
      goTop();
    }
    this.form = Object.assign({}, this.form, data, {
      group_booking: false,
    });
  }

  /**
   * 团队预定
   *
   * @memberof Store
   */
  @action
  groupBooking() {
    this.step = this.STEPS.CONFIRM;
    this.form = Object.assign({}, this.form, {
      seats: "",
      discount: "",
      off_peak_id: "",
      children_seats: "",
      group_booking: true,
    });
    goTop();
  }

  /**
   * 重置选项卡
   *
   * @param {number} step 步骤
   * @param {string} field form字段
   * @memberof ReservationStore
   */
  @action
  reset(step, field) {
    this.step = step;
    // this.form = Object.assign({}, this.form, {[field]: ''})
    goTop();
  }

  /**
   * 取消预订
   *
   * @param {*} form
   * @memberof Store
   */
  @action
  async cancel(reservationCode, reservationId) {
    return reservationService.delete(reservationCode, reservationId);
  }

  /**
   * 创建预订
   *
   * @param {*} form
   * @memberof Store
   */
  @action
  async create(form) {
    try {
      let formdata = this.dropEmpty(Object.assign({}, this.form, form));
      if (this.form.id) {
        return reservationService.update(formdata);
      }
      if (this.form.group_booking) {
        return this.createWithGroup(formdata);
      }
      return await reservationService.create(
        formdata,
        member.member.private_token
      );
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  /**删除空字符 */
  dropEmpty(data) {
    let form = {};
    Object.keys(data).forEach((key) => {
      let val = data[key];
      if (val !== "" && (val !== undefined) & (val !== null)) {
        form[key] = val;
      }
    });
    return form;
  }

  /**
   * 创建团队预订
   *
   * @param {*} formdata
   * @memberof Store
   */
  @action
  async createWithGroup(formdata) {
    try {
      return await reservationService.createWithGroup(
        formdata,
        member.member.private_token
      );
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  /**
   * 获取套餐信息
   *
   * @memberof Store
   */
  @action
  async getDeals(id, project) {
    this.deals = await reservationService.getDeal(id, project);
    // 需要验证卡号
    if (this.deals.enable_verify_card) {
      this.setCardSteps();
    }
  }

  /**
   * 获取优惠券
   *
   * @memberof Store
   */
  @action
  getVouchers() {
    let restaurant = this.restaurant;
    return new Promise((resolve) => {
      let { project = "diningcity", seats } = this.form;
      http
        .get(`/project_configs/${project}/voucher_info`, {
          params: {
            seats: seats,
            deal_id: this.deals ? this.deals.id : undefined,
            restaurant_id: restaurant ? restaurant.id : undefined
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("获取优惠券 失败, ", error);
          resolve({});
        });
    });
  }

  /**获取银行卡提示信息 */
  @action
  getAccesCodeTip() {
    let { access_code_data } = this.deals;
    if (!access_code_data) return Promise.reject();
    const { code, project } = access_code_data;
    return http.get(`${BASE}/public/access_codes/${code}`, {
      params: {
        project,
      },
    });
  }

  /**获取押金信息 */
  @action
  getDeposit({ restaurant_id, seats, project = "diningcity" }) {
    if (!project) return Promise.reject();
    return http.get(`/restaurants/${restaurant_id}/commission`, {
      params: {
        project,
        seats,
        deal_id: getQuery().deal_id,
      },
    });
  }

  /**验证银行卡号 */
  @action
  verifyCard(password) {
    let { access_code_data } = this.deals;
    if (!access_code_data) return Promise.reject();
    const { code } = access_code_data;
    const { project } = getQuery();
    return http.get(`${BASE}/public/access_codes/${code}/valid`, {
      params: {
        project,
        password,
        code,
      },
    });
  }

  /**获取支付要素 */
  @action
  getPaymentInfo(booking) {
    const { deal_id, access_code, project = "diningcity" } = getQuery();
    const { restaurant_id, seats, vouchers, uuid, date, discount, mobile, time, country_code } = booking || {}
    return http
      .get(`/restaurants/${restaurant_id}/payment_info`, {
        params: {
          project,
          access_code,
          deal_id,
          seats,
          vouchers,
          uuid,
          restaurant_id,
          date, discount, mobile, time, country_code
        },
      })
      .then((data) => {
        this.paymentInfo = data.data;
        return data.data;
      });
  }

  /**获取预订信息 */
  @action
  async getBooking(code, id) {
    let data = await reservationService.show(code, id);
    data = {
      ...data,
      date: moment(data.reservation_date).format("YYYY-MM-DD"),
      time: moment(data.reservation_time).format("HH:mm"),
      seats: data.reservation_seats,
    };
    this.reservation = data
    return data;
  }

  /** 合并编辑信息 */
  @action
  async assignEdit(code, id, restaurantId) {
    let data = await reservationService.show(code, id);
    let { first_name, last_name, email, mobile } = data
    let memberInfo = { first_name, last_name, email, mobile };
    let newData = {
      ...memberInfo,
      id: id,
      restaurant_id: restaurantId,
      reservation_code: code,
      date: moment(data.reservation_date).format("YYYY-MM-DD"),
      time: moment(data.reservation_time).format("HH:mm"),
      seats: data.reservation_seats,
      children_seats: data.children_seats,
      comment: data.reservation_comment,
      channel_name: data.channel_name,
      loyalty_program_benefit_ids: data.loyalty_program_benefits
        .filter((i) => i.is_optional)
        .map((i) => i.id)
        .join(","),
    };

    if (data.project) {
      newData.project = data.project;
    }
    if (data.program) {
      newData.program = data.program.name;
    }
    if (data.deal) {
      newData.deal_id = data.deal.id;
    }
    if (data.member) {
      if (data.member.mobile == data.mobile) {
        newData.avatar_url = data.member.avatar_url
        member.member = data.member
      } else { // 代订订单
        member.member = {
          ...memberInfo,
          email: ''
        }
      }
    } else {
      member.member = memberInfo
    }
    this.reservation = data;
    this.form = Object.assign({}, this.form, newData);
  }

  /**
   * 预订接口转换器
   *
   * @param {*} form
   * @memberof Service
   */
  @action
  bookResultAdapter(form) {
    const {
      date,
      time,
      seats,
      remark,
      discount,
      mobile,
      country_code,
      project,
      first_name,
      last_name,
      email,
      program,
    } = form;
    console.log(form, 'formform');
    return {
      group_booking: true,
      reservation_date: date,
      reservation_time: `2000-01-01T${time}:00.000+08:00`,
      reservation_seats: seats,
      reservation_comment: remark,
      discount: discount,
      project: project || program,
      country_code: country_code,
      mobile: mobile,
      first_name: first_name,
      last_name: last_name,
      email: email,
      invites: [],
      can_review: false,
      can_cancel: false,
      can_update: false,
      can_noshow: false,
      deal: this.deals,
      loyalty_program_benefits: [],
      vouchers: [],
      payment: null,
    };
  }

  /**跳转到小程序页面 */
  @action
  navigateToMP(book) {
    let { source = "", jumpUrl = "" } = getQuery();
    jumpUrl = decodeURIComponent(jumpUrl)
    source = source.toUpperCase();
    const paramsMp = {
      pageType: 'PAGE_BOOK_RESULT',
      orderNo: ''
    }
    const [path, queryStr] = jumpUrl.split("?")
    const query = qs.parse(queryStr)
    query.pageType = paramsMp.pageType
    query.orderNo = paramsMp.orderNo
    const url = jumpUrl ? `${path}?${qs.stringify(query)}` : "/pages/benefits/bookResult/index?orderNo="
    // 如果是iframe模式
    if (window.top !== window.window) {
      return window.top.postMessage({
        type: "navigateTo",
        url
      }, "*")
    }
    try {
      if (jumpUrl) {
        window.location.replace(url)
      }
      else if (source === "EC_WECHAT_MP") {
        wx.miniProgram.navigateTo({
          url: "/pages/benefits/bookResult/index?orderNo=",
        });
      } else if (source === "EC_ALIPAY_MP") {
        my.navigateTo({
          url: "/pages/benefits/bookResult/index?orderNo=",
        });
      } else if (source.endsWith("_H5")) {
        window.location.href =
          process.env.REACT_APP_PAY_URL + "/pages/benefits/bookResult/index?orderNo=";
      }

    } catch (error) {
      console.error(error);
    }
  }

  /**标记出席人数 */
  @action
  setPartialNoShow({ restaurant_id, book_id, show_number }) {
    const BASE = process.env.REACT_APP_API;
    const { uid, lang, client, acctoken } = getQuery();
    return http.post(
      `${BASE}/restaurant/restaurants/${restaurant_id}/books/${book_id}/partial_no_show`,
      {
        show_number,
      },
      {
        headers: {
          uid: uid,
          lang: lang,
          client: client,
          "access-token": acctoken,
          "api-key": "cgecegcegcc",
        },
      }
    );
  }

}

function setTitle(name) {
  document.title = name;
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.onload = function () {
    document.body.removeChild(iframe);
  };
  document.body.appendChild(iframe);
}

export default new Store();
