import http from '../../service/http'

export const PosPayAPI = {
    getOrder(orderId) {
        return http.get('/orders/' + orderId).then(res => {
            if (res.status === 200) {
                return res.data
            }
        })
    },
    getBooking(reservation_code, reservation_id) {
        return http.get('/books/' + reservation_id, {
            params: {
                reservation_code
            }
        }).then(res => {
            if (res.status === 200) {
                return res.data
            }
        })
    },

}