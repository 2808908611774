import React, {
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { Modal, Empty, Input, Form } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useStores } from "@/store";
import moment from "moment";
import * as Common from "@/components/common";
import { voucherRequire } from "@/utils";
import "./index.less";
import reservationService from "@/service/reservation";
import classNames from "classnames";

/** Voucher信息 */
export const Voucher = (props) => {
  const { t } = useTranslation();
  const selecterRef = useRef();
  const onSelect = () => {
    selecterRef.current.onSelect();
  };
  return (
    <Common.ListItem
      title={
        <Common.Icon
          icon="icon icon-voucher"
          text={
            <span
              style={
                props.big
                  ? {
                      display: "block",
                      fontSize: 15,
                      lineHeight: "20px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "auto",
                      whiteSpace: "nowrap",
                    }
                  : {}
              }
            >
              {t("VOUCHERS.VOUCHERS")}
            </span>
          }
        />
      }
      value={
        props.showCardVoucher ? (
          <CardVoucherSelected selecterRef={selecterRef} {...props} />
        ) : (
          <VoucherSelected {...props} />
        )
      }
      onClick={() => (props.disabled ? () => {} : onSelect())}
    ></Common.ListItem>
  );
};

export const VoucherPopover = (props) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(props.codes || []);
  const [visible, setVisible] = useState(false);
  const [terms, setTerms] = useState("");
  const [status, setStatus] = useState("SelectVoucher");
  // 是否输入正确券码
  const [enterVoucher, setEnterVoucher] = useState("");
  const [voucherErrMsg, setVoucherErrMsg] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  // 是否选择了足够的券码
  const voucherEnable = useMemo(() => {
    let { required, points_range = [] } = props.rule;
    points_range = points_range || [];
    let [min] = points_range;
    if (!required) return true;
    if (values.length >= min) return true;
    return false;
  }, [props.rule, values]);

  const maxSize = useMemo(() => {
    let { points_range } = props.rule;
    points_range = points_range || [];
    let [min, max] = points_range;
    return max || min || 0;
  }, [props.rule]);

  useEffect(() => {
    props.getVocherList &&
      props.getVocherList({
        cardNumber: props.uuid,
      });
    () => {
      setStatus("SelectVoucher");
      setEnterVoucher("");
      setVoucherErrMsg("");
    };
  }, []);

  function onChange(data, val) {
    let list = [...values];
    if (val) {
      list.push(data.code);
    } else {
      list = list.filter((item) => item !== data.code);
    }
    // 限制最大可选数量
    if (list.length > maxSize) {
      list.pop();
    }
    setValues(list);
  }
  function showTerm(data) {
    setTerms(data.detail.desc);
    setVisible(true);
  }
  const onBack = () => {
    setVoucherErrMsg("");
    setEnterVoucher("");
    if (status === "SelectVoucher") {
      props.onBack(props.codes);
    } else if (status === "AddVoucher") {
      setStatus("SelectVoucher");
    }
  };

  const onAddConfirm = async () => {
    // 添加
    if (!enterVoucher) return;
    setConfirmLoading(true);
    try {
      const { data } = await reservationService.entryVoucher(enterVoucher);
      if (data !== "ok") {
        setVoucherErrMsg(data.message);
        return console.error("添加券码失败");
      } else {
        setVoucherErrMsg("");
      }
      setStatus("SelectVoucher");
      setConfirmLoading(false);
      // 获取券码
      props.getVocherList();
    } catch (error) {
      setVoucherErrMsg(error.message);
      setConfirmLoading(false);
    }
  };
  return (
    <div className="page-popup page-animate-popup">
      <Common.PageHeader onBack={onBack}>
        {status === "SelectVoucher" ? t("VOUCHERS.VOUCHERS") : t("Add voucher")}
      </Common.PageHeader>
      {status === "SelectVoucher" ? (
        <React.Fragment>
          <div className="voucher-tip" hidden={!props.showCardVoucher}>
            <i className="icon icon-info selected"></i>
            <span>
              {t("You can use up to x voucher(s)", { size: maxSize })}
            </span>
          </div>
          <Terms text={terms} visible={visible} setVisible={setVisible} />
        </React.Fragment>
      ) : (
        <div
          style={{
            fontSize: 13,
            color: "#333333",
            lineHeight: "18px",
            marginBottom: 10,
          }}
        >
          <div>{t("Enter a valid voucher code to add to your accounts")}</div>
          <span>
            {t("You can later find your code under Me > My vouchers")}
          </span>
        </div>
      )}

      <div className="page-popup--body">
        {status === "SelectVoucher" ? (
          <React.Fragment>
            {props.vouchers.length ? (
              props.vouchers.map((item, index) => {
                return (
                  <VoucherItem
                    {...item}
                    key={index}
                    checks={values}
                    showTerm={() => showTerm(item)}
                    onChange={(val) => onChange(item, val)}
                  />
                );
              })
            ) : (
              <Empty
                style={{ marginTop: 88 }}
                image={require("./../../images/No_vouchers_drawing.svg")}
                description={
                  <span style={{ fontSize: 13, color: "#333333" }}>
                    {t("There are no vouchers currently tied to your account")}
                  </span>
                }
              ></Empty>
            )}
          </React.Fragment>
        ) : (
          <CodeInput errMsg={voucherErrMsg} onChange={setEnterVoucher} />
        )}

        {props.showFooter ? (
          <React.Fragment>
            <div
              style={{
                height: status === "SelectVoucher" && props.isMember ? 160 : 80,
              }}
            ></div>
            <footer className="page-footer" align="right">
              {status === "SelectVoucher" && props.isMember && (
                <div style={{ marginBottom: 16 }}>
                  <button
                    className="dc-button"
                    onClick={() => setStatus("AddVoucher")}
                  >
                    {t("Enter code")}
                  </button>
                </div>
              )}
              <div>
                {status === "SelectVoucher" ? (
                  <Common.Button
                    type="primary"
                    disabled={!voucherEnable}
                    onClick={() => props.onBack(values)}
                  >
                    {t("CONFIRM")}
                  </Common.Button>
                ) : (
                  <Common.Button
                    type="primary"
                    disabled={!enterVoucher}
                    onClick={onAddConfirm}
                    loading={confirmLoading}
                  >
                    {t("CONFIRM")}
                  </Common.Button>
                )}
              </div>
              {/* 输入券 */}
            </footer>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

/**
 * code 输入
 * @returns
 */
const CodeInput = (props) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      className="voucher-form-item"
      validateStatus={props.errMsg ? "error" : "success"}
      help={props.errMsg}
    >
      <Input
        className="voucher-input"
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={t("Enter voucher code here")}
      />
    </Form.Item>
  );
};

export const VoucherItem = (props) => {
  const { t } = useTranslation();
  const { detail, end_at, points } = props;
  const priceStyle = useMemo(() => {
    let size = String(detail.currency + detail.price).length;
    if (size < 4) {
      return { fontSize: "34px" };
    } else if (size >= 4 && size <= 6) {
      return { fontSize: "26px" };
    } else {
      return { fontSize: "18px" };
    }
  }, [detail.currency, detail.price]);
  const isSelected = useMemo(() => {
    return (props.checks || []).includes(props.code);
  }, [props.checks, props.code]);
  const Price = () => {
    switch (detail.price_type) {
      case "percentage":
        return <span>{detail.price_humanize}</span>;
        break;
      case "fixed":
        return (
          <React.Fragment>
            <i>{detail.currency}</i>
            <span style={priceStyle}>{detail.price_humanize}</span>
          </React.Fragment>
        );
        break;
      case "none":
        return (
          <React.Fragment>
            <i>{detail.currency}</i>
            <span style={priceStyle}>{detail.price_humanize}</span>
          </React.Fragment>
        );
        break;
      default:
        return (
          <span
            style={{
              fontSize: 18,
              width: 100,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {detail.title}
          </span>
        );
        break;
    }
  };
  return (
    <div className="voucher-item">
      <div
        className={`voucher-item--body ${
          !(
            props.applicable ||
            props.applicable === undefined ||
            props.applicable === null
          )
            ? "voucher-item--body--disabled"
            : ""
        }`}
      >
        <div style={{ display: "flex" }}>
          <div className="voucher-item--left" hidden={props.hidden}>
            <Common.DcCheckbox
              checked={isSelected}
              onChange={props.onChange}
            ></Common.DcCheckbox>
          </div>
          <div className="voucher-item--price" hidden={!+props.detail.price}>
            <Price />
          </div>
        </div>
        <div
          className="voucher-item--right"
          style={{ maxWidth: !+props.detail.price ? "none" : "135px" }}
        >
          <div className="voucher-item--title ellipsis-2">{detail.title}</div>
          {detail.visible_consumer && (
            <div className="voucher-item--item">
              <label
                className={classNames("voucher-item--item-date", {
                  "no-price": !+props.detail.price,
                })}
              >
                {t("VOUCHERS.Expires on")}:{" "}
              </label>
              {moment(end_at).format("YYYY-MM-DD")}
            </div>
          )}
          <div className="voucher-item--item ellipsis-1">
            <label>{t("VOUCHERS.Redeems left")}: </label>
            <span>
              {detail.points_type === "unlimited"
                ? t("VOUCHERS.Unlimited")
                : points}
            </span>
          </div>
        </div>
      </div>
      <div className="voucher-item--footer">
        <span className="terms-button" onClick={props.showTerm}>
          {t("VOUCHERS.Terms of use")}
          <i className="icon icon-info terms-icon"></i>
        </span>
        <span
          className="dc-tag"
          hidden={
            props.applicable ||
            props.applicable === undefined ||
            props.applicable === null
          }
        >
          {t("VOUCHERS.Not applicable")}
        </span>
      </div>
    </div>
  );
};

export const VoucherSelected = (props) => {
  const { t } = useTranslation();
  const size = useMemo(() => {
    try {
      return props.codes.length;
    } catch (error) {
      return 0;
    }
  }, [props.codes]);

  const title = useMemo(() => {
    try {
      return props.vouchers[0].detail.title;
    } catch (error) {}
  }, [props.vouchers]);
  return size ? (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        right: 6,
        position: "relative",
        whiteSpace: "nowrap",
      }}
    >
      <span
        style={{
          display: "inline-block",
          fontSize: 13,
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 80,
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </span>{" "}
      * {size}
      <i
        className="icon icon-arrow-right"
        style={{ width: 12, height: 12, backgroundSize: 12 }}
      ></i>
    </span>
  ) : (
    <span style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          fontSize: 13,
          lineHeight: "18px",
          color: "#DC2224",
          fontWeight: "bold",
          width: 80,
          whiteSpace: "nowrap",
        }}
      >
        {t("PAYMENT.Please select")}
      </span>
      <i
        className="icon icon-arrow-right"
        style={{ width: 12, height: 12, backgroundSize: 12 }}
      ></i>
    </span>
  );
};

/** 选择优惠劵 */
export const CardVoucherSelected = (props) => {
  const { selecterRef, onClick } = props;
  const { t } = useTranslation();
  const enable = useMemo(() => {
    try {
      // 展示可用的券码
      return (
        !!props.vouchers.filter((item) => item.applicable).length &&
        !props.disabled
      );
    } catch (error) {
      return false;
    }
  }, [props.vouchers]);
  const size = useMemo(() => {
    return !!props.codes.length;
  }, [props.codes]);

  const price = useMemo(() => {
    let vouchcer = props.vouchers.find((item) => item.code === props.codes[0]);
    if (!vouchcer) return "";
    let { detail } = vouchcer;
    if (props.codes.length > 1) {
      return t("x vouchers", { size: props.codes.length });
    }
    switch (detail.price_type) {
      case "percentage":
        return `-${detail.price}%`;
        break;
      case "fixed":
        return `${detail.currency}${detail.price_humanize}`;
        break;
      case "none":
        return detail.title;
        break;
      default:
        return detail.title;
        break;
    }
  }, [props.codes, props.vouchers]);

  useImperativeHandle(selecterRef, () => ({
    onSelect: () => {
      // 新需求不判断可选优惠劵数量
      onClick(true);
    },
  }));

  return enable ? (
    <span className="list-item__tag  list-item__tag--active" onClick={onClick}>
      {size ? price : t("Vouchers available")}
      <RightOutlined />
    </span>
  ) : (
    <span className={`list-item__tag`}>
      {t("No vouchers available")}
      <RightOutlined />
    </span>
  );
};

/**使用条款 */
export const Terms = ({ visible, setVisible, text }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal voucher-modal"
      width={300}
      footer={null}
      closable={false}
      centered
      destroyOnClose
    >
      <header>{t("VOUCHERS.Terms of use")}</header>
      <div className="voucher-modal--content">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        <footer>
          <span></span>
          <strong onClick={() => setVisible(false)}>
            {t("VOUCHERS.Close")}
          </strong>
        </footer>
      </div>
    </Modal>
  );
};

export default Voucher;

function useThrottle(value, interval = 300) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastExecuted = useRef(Date.now());

  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
  }, [value, interval]);

  return throttledValue;
}
